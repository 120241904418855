/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0.0 Beta 6
----------------------------------------
GENERAL SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://v2.designsystem.digital.gov/style-tokens
----------------------------------------
*/
/*
----------------------------------------
Image Path
----------------------------------------
Relative image file path
----------------------------------------
*/
/*
----------------------------------------
Show compile warnings
----------------------------------------
Show Sass warnings when functions and
mixins use non-standard tokens.
----------------------------------------
*/
/*
----------------------------------------
Namespace
----------------------------------------
*/
/*
----------------------------------------
Layout grid
----------------------------------------
Should the layout grid classes output
with !important
----------------------------------------
*/
/*
----------------------------------------
Border box sizing
----------------------------------------
When set to true, sets the box-sizing
property of all site elements to
`border-box`.
----------------------------------------
*/
/*
----------------------------------------
Focus styles
----------------------------------------
*/
/*
----------------------------------------
Icons
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0.0 Beta 6
----------------------------------------
TYPOGRAPHY SETTINGS
----------------------------------------
Read more about settings and
USWDS typography tokens in the documentation:
https://v2.designsystem.digital.gov/style-tokens/typography
----------------------------------------
*/
/*
----------------------------------------
Root font size
----------------------------------------
Setting $theme-respect-user-font-size to
true sets the root font size to 100% and
uses ems for media queries
----------------------------------------
$theme-root-font-size only applies when
$theme-respect-user-font-size is set to
false.

This will set the root font size
as a specific px value and use px values
for media queries.

Accepts true or false
----------------------------------------
*/
/*
----------------------------------------
Global styles
----------------------------------------
Adds basic styling for the following
unclassed elements:

- paragraph: paragraph text
- link: links
- global: paragraph text, links,
  headings, lists, and tables
----------------------------------------
*/
/*
----------------------------------------
Font path
----------------------------------------
Relative font file path
----------------------------------------
*/
/*
----------------------------------------
Custom typeface tokens
----------------------------------------
Add a new custom typeface token if
your project uses a typeface not already
defined by USWDS.
----------------------------------------
USWDS defines the following tokens
by default:
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
Add as many new tokens as you have
custom typefaces. Reference your new
token(s) in the type-based font settings
using the quoted name of the token.

For example:

$theme-font-type-cond: 'example-font-token';

display-name:
The display name of your font

cap-height:
The height of a 500px `N` in Sketch
----------------------------------------
You should change `example-[style]-token`
names to something more descriptive.
----------------------------------------
*/
/*
----------------------------------------
Type-based font settings
----------------------------------------
Set the type-based tokens for your
project from the following tokens,
or from any new font tokens you added in
$theme-typeface-tokens.
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
*/
/*
----------------------------------------
Custom font stacks
----------------------------------------
Add custom font stacks to any of the
type-based fonts. Any USWDS typeface
token already has a default stack.

Custom stacks don't need to include the
font's display name. It will
automatically appear at the start of
the stack.
----------------------------------------
Example:
$theme-font-type-sans: 'source-sans-pro';
$theme-font-sans-custom-stack: "Helvetica Neue", Helvetica, Arial, sans;

Output:
font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans;
----------------------------------------
*/
/*
----------------------------------------
Add any custom font source files
----------------------------------------
If you want USWDS to generate additional
@font-face declarations, add your font
data below, following the example that
follows.
----------------------------------------
USWDS automatically generates @font-face
decalarations for the following

'merriweather'
'public-sans'
'roboto-mono'
'source-sans-pro'

These typefaces not require custom
source files.
----------------------------------------
EXAMPLE

- dir:
  Directory relative to $theme-font-path
- This directory should include fonts saved as
  .eot, .ttf, .woff, and .woff2
  ExampleSerif-Normal.eot
  ExampleSerif-Normal.ttf
  ExampleSerif-Normal.woff
  ExampleSerif-Normal.woff2

$theme-font-serif-custom-src: (
  dir: 'custom/example-serif',
  roman: (
    100: false,
    200: false,
    300: 'ExampleSerif-Light',
    400: 'ExampleSerif-Normal',
    500: false,
    600: false,
    700: 'ExampleSerif-Bold',
    800: false,
    900: false,
  ),
  italic: (
    100: false,
    200: false,
    300: 'ExampleSerif-LightItalic',
    400: 'ExampleSerif-Italic',
    500: false,
    600: false,
    700: 'ExampleSerif-BoldItalic',
    800: false,
    900: false,
  ),
);
----------------------------------------
*/
/*
----------------------------------------
Role-based font settings
----------------------------------------
Set the role-based tokens for your
project from the following font-type
tokens.
----------------------------------------
'cond'
'icon'
'lang'
'mono'
'sans'
'serif'
----------------------------------------
*/
/*
----------------------------------------
Type scale
----------------------------------------
Define your project's type scale using
values from the USWDS system type scale

1-20
----------------------------------------
*/
/*
----------------------------------------
Font weights
----------------------------------------
Assign weights 100-900
Or use `false` for unneeded weights.
----------------------------------------
*/
/*
----------------------------------------
General typography settings
----------------------------------------
Type scale tokens
----------------------------------------
micro:      10px
1:          12px
2:          13px
3:          14px
4:          15px
5:          16px
6:          17px
7:          18px
8:          20px
9:          22px
10:         24px
11:         28px
12:         32px
13:         36px
14:         40px
15:         48px
16:         56px
17:         64px
18:         80px
19:         120px
20:         140px
----------------------------------------
Line height tokens
----------------------------------------
1:    1
2:    1.15
3:    1.35
4:    1.5
5:    1.62
6:    1.75
----------------------------------------
Font role tokens
----------------------------------------
'ui'
'heading'
'body'
'code'
'alt'
----------------------------------------
Measure (max-width) tokens
----------------------------------------
1:       40ch
2:       60ch
3:       66ch
4:       72ch
5:       77ch
none:    none
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0.0 Beta 6
----------------------------------------
SPACING SETTINGS
----------------------------------------
Read more about settings and
USWDS spacing units tokens in the
documentation:
https://v2.designsystem.digital.gov/style-tokens/spacing-units
----------------------------------------
*/
/*
----------------------------------------
Border radius
----------------------------------------
2px           2px
0.5           4px
1             8px
1.5           12px
2             16px
2.5           20px
3             24px
4             32px
5             40px
6             48px
7             56px
8             64px
9             72px
----------------------------------------
*/
/*
----------------------------------------
Column gap
----------------------------------------
2px         2px
0.5         4px
1           8px
2           16px
3           24px
4           32px
5           40px
6           48px
----------------------------------------
*/
/*
----------------------------------------
Grid container max-width
----------------------------------------
mobile
mobile-lg
tablet
tablet-lg
desktop
desktop-lg
widescreen
----------------------------------------
*/
/*
----------------------------------------
Site
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0.0 Beta 6
----------------------------------------
COLOR SETTINGS
----------------------------------------
Read more about settings and
USWDS color tokens in the documentation:
https://v2.designsystem.digital.gov/style-tokens/color
----------------------------------------
*/
/*
----------------------------------------
Theme palette colors
----------------------------------------
*/
/*
----------------------------------------
State palette colors
----------------------------------------
*/
/*
----------------------------------------
General colors
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0.0 Beta 6
----------------------------------------
UTILITIES SETTINGS
----------------------------------------
Read more about settings and
USWDS utilities in the documentation:
https://v2.designsystem.digital.gov/utilities
----------------------------------------
*/
/*
----------------------------------------
Utility breakpoints
----------------------------------------
Which breakpoints does your project
need? Select as `true` any breakpoint
used by utilities or layout grid
----------------------------------------
*/
/*
----------------------------------------
Global colors
----------------------------------------
The following palettes will be added to
- background-color
- border-color
- color
- text-decoration-color
----------------------------------------
*/
/*
----------------------------------------
Settings
----------------------------------------
*/
/*
----------------------------------------
Values
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.12.1
----------------------------------------
GENERAL SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
----------------------------------------
Image path
----------------------------------------
Relative image file path
----------------------------------------
*/
/*
----------------------------------------
Show compile warnings
----------------------------------------
Show Sass warnings when functions and
mixins use non-standard tokens.
AND
Show updates and notifications.
----------------------------------------
*/
/*
----------------------------------------
Namespace
----------------------------------------
*/
/*
----------------------------------------
Prefix separator
----------------------------------------
Set the character the separates
responsive and state prefixes from the
main class name.

The default (":") needs to be preceded
by two backslashes to be properly
escaped.
----------------------------------------
*/
/*
----------------------------------------
Layout grid
----------------------------------------
Should the layout grid classes output
with !important
----------------------------------------
*/
/*
----------------------------------------
Border box sizing
----------------------------------------
When set to true, sets the box-sizing
property of all site elements to
`border-box`.
----------------------------------------
*/
/*
----------------------------------------
Focus styles
----------------------------------------
*/
/*
----------------------------------------
Icons
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.12.1
----------------------------------------
TYPOGRAPHY SETTINGS
----------------------------------------
Read more about settings and
USWDS typography tokens in the documentation:
https://designsystem.digital.gov/design-tokens/typesetting/overview/
----------------------------------------
*/
/*
----------------------------------------
Root font size
----------------------------------------
Setting $theme-respect-user-font-size to
true sets the root font size to 100% and
uses ems for media queries
----------------------------------------
$theme-root-font-size only applies when
$theme-respect-user-font-size is set to
false.

This will set the root font size
as a specific px value and use px values
for media queries.

Accepts true or false
----------------------------------------
*/
/*
----------------------------------------
Global styles
----------------------------------------
Adds basic styling for the following
unclassed elements:

- paragraph: paragraph text
- link: links
- content: paragraph text, links,
  headings, lists, and tables
----------------------------------------
*/
/*
----------------------------------------
Font path
----------------------------------------
Relative font file path
----------------------------------------
*/
/*
----------------------------------------
Custom typeface tokens
----------------------------------------
Add a new custom typeface token if
your project uses a typeface not already
defined by USWDS.
----------------------------------------
USWDS defines the following tokens
by default:
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
Add as many new tokens as you have
custom typefaces. Reference your new
token(s) in the type-based font settings
using the quoted name of the token.

For example:

$theme-font-type-cond: 'example-font-token';

display-name:
The display name of your font

cap-height:
The height of a 500px `N` in Sketch
----------------------------------------
You should change `example-[style]-token`
names to something more descriptive.
----------------------------------------
*/
/*
----------------------------------------
Type-based font settings
----------------------------------------
Set the type-based tokens for your
project from the following tokens,
or from any new font tokens you added in
$theme-typeface-tokens.
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
*/
/*
----------------------------------------
Custom font stacks
----------------------------------------
Add custom font stacks to any of the
type-based fonts. Any USWDS typeface
token already has a default stack.

Custom stacks don't need to include the
font's display name. It will
automatically appear at the start of
the stack.
----------------------------------------
Example:
$theme-font-type-sans: 'source-sans-pro';
$theme-font-sans-custom-stack: "Helvetica Neue", Helvetica, Arial, sans;

Output:
font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans;
----------------------------------------
*/
/*
----------------------------------------
Add any custom font source files
----------------------------------------
If you want USWDS to generate additional
@font-face declarations, add your font
data below, following the example that
follows.
----------------------------------------
USWDS automatically generates @font-face
decalarations for the following

'merriweather'
'public-sans'
'roboto-mono'
'source-sans-pro'

These typefaces not require custom
source files.
----------------------------------------
EXAMPLE

- dir:
  Directory relative to $theme-font-path
- This directory should include fonts saved as
  .ttf, .woff, and .woff2
  ExampleSerif-Normal.ttf
  ExampleSerif-Normal.woff
  ExampleSerif-Normal.woff2

$theme-font-serif-custom-src: (
  dir: 'custom/example-serif',
  roman: (
    100: false,
    200: false,
    300: 'ExampleSerif-Light',
    400: 'ExampleSerif-Normal',
    500: false,
    600: false,
    700: 'ExampleSerif-Bold',
    800: false,
    900: false,
  ),
  italic: (
    100: false,
    200: false,
    300: 'ExampleSerif-LightItalic',
    400: 'ExampleSerif-Italic',
    500: false,
    600: false,
    700: 'ExampleSerif-BoldItalic',
    800: false,
    900: false,
  ),
);
----------------------------------------
*/
/*
----------------------------------------
Role-based font settings
----------------------------------------
Set the role-based tokens for your
project from the following font-type
tokens.
----------------------------------------
'cond'
'icon'
'lang'
'mono'
'sans'
'serif'
----------------------------------------
*/
/*
----------------------------------------
Type scale
----------------------------------------
Define your project's type scale using
values from the USWDS system type scale

1-20
----------------------------------------
*/
/*
----------------------------------------
Font weights
----------------------------------------
Assign weights 100-900
Or use `false` for unneeded weights.
----------------------------------------
*/
/*
----------------------------------------
General typography settings
----------------------------------------
Type scale tokens
----------------------------------------
micro:      10px
1:          12px
2:          13px
3:          14px
4:          15px
5:          16px
6:          17px
7:          18px
8:          20px
9:          22px
10:         24px
11:         28px
12:         32px
13:         36px
14:         40px
15:         48px
16:         56px
17:         64px
18:         80px
19:         120px
20:         140px
----------------------------------------
Line height tokens
----------------------------------------
1:    1
2:    1.15
3:    1.35
4:    1.5
5:    1.62
6:    1.75
----------------------------------------
Font role tokens
----------------------------------------
'ui'
'heading'
'body'
'code'
'alt'
----------------------------------------
Measure (max-width) tokens
----------------------------------------
1:       44ex
2:       60ex
3:       64ex
4:       68ex
5:       74ex
6:       88ex
none:    none
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.12.1
----------------------------------------
COLOR SETTINGS
----------------------------------------
Read more about settings and
USWDS color tokens in the documentation:
https://designsystem.digital.gov/design-tokens/color
----------------------------------------
*/
/*
----------------------------------------
Theme palette colors
----------------------------------------
*/
/*
----------------------------------------
State palette colors
----------------------------------------
*/
/*
----------------------------------------
General colors
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.12.1
----------------------------------------
SPACING SETTINGS
----------------------------------------
Read more about settings and
USWDS spacing units tokens in the
documentation:
https://designsystem.digital.gov/design-tokens/spacing-units
----------------------------------------
*/
/*
----------------------------------------
Border radius
----------------------------------------
2px           2px
0.5           4px
1             8px
1.5           12px
2             16px
2.5           20px
3             24px
4             32px
5             40px
6             48px
7             56px
8             64px
9             72px
----------------------------------------
*/
/*
----------------------------------------
Column gap
----------------------------------------
2px         2px
0.5         4px
1           8px
2           16px
3           24px
4           32px
5           40px
6           48px
----------------------------------------
*/
/*
----------------------------------------
Grid container max-width
----------------------------------------
mobile
mobile-lg
tablet
tablet-lg
desktop
desktop-lg
widescreen
----------------------------------------
*/
/*
----------------------------------------
Site
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.12.1
----------------------------------------
UTILITIES SETTINGS
----------------------------------------
Read more about settings and
USWDS utilities in the documentation:
https://designsystem.digital.gov/utilities
----------------------------------------
*/
/*
----------------------------------------
Utility breakpoints
----------------------------------------
Which breakpoints does your project
need? Select as `true` any breakpoint
used by utilities or layout grid
----------------------------------------
*/
/*
----------------------------------------
Global colors
----------------------------------------
The following palettes will be added to
- background-color
- border-color
- color
- text-decoration-color
----------------------------------------
*/
/*
----------------------------------------
Settings
----------------------------------------
*/
/*
----------------------------------------
Values
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.12.1
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
========================================
Functions
----------------------------------------
*/
/*
========================================
General-purpose functions
----------------------------------------
*/
/*
----------------------------------------
uswds-error()
----------------------------------------
Allow the system to pass an error as text
to test error states in unit testing
----------------------------------------
*/
/*
----------------------------------------
error-not-token()
----------------------------------------
Returns a common not-a-token error.
----------------------------------------
*/
/*
----------------------------------------
map-deep-get()
----------------------------------------
@author Hugo Giraudel
@access public
@param {Map} $map - Map
@param {Arglist} $keys - Key chain
@return {*} - Desired value
----------------------------------------
*/
/*
----------------------------------------
strip-unit()
----------------------------------------
Remove the unit of a length
@author Hugo Giraudel
@param {Number} $number - Number to remove unit from
@return {Number} - Unitless number
----------------------------------------
*/
/*
----------------------------------------
multi-cat()
----------------------------------------
Concatenate two lists
----------------------------------------
*/
/*
----------------------------------------
map-collect()
----------------------------------------
Collect multiple maps into a single
large map
source: https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------
*/
/*
----------------------------------------
smart-quote()
----------------------------------------
Quotes strings
Inspects `px`, `xs`, and `xl` numbers
Leaves bools as is
----------------------------------------
*/
/*
----------------------------------------
remove()
----------------------------------------
Remove a value from a list
----------------------------------------
*/
/*
----------------------------------------
strunquote()
----------------------------------------
Unquote a string
----------------------------------------
*/
/*
----------------------------------------
to-map()
----------------------------------------
Convert a single value to a USWDS
value map.

Candidate for deprecation if we remove
isReadable
----------------------------------------
*/
/*
----------------------------------------
base-to-map()
----------------------------------------
Convert a single base to a USWDS
value map.

Candidate for deprecation if we remove
isReadable
----------------------------------------
*/
/*
----------------------------------------
ns()
----------------------------------------
Add a namesspace of $type if that
namespace is set to output
----------------------------------------
*/
/*
----------------------------------------
de-list()
----------------------------------------
Transform a one-element list or arglist
into that single element.
----------------------------------------
(1) => 1
((1)) => (1)
----------------------------------------
*/
/*
----------------------------------------
unpack()
----------------------------------------
Create lists of single items from lists
of lists.
----------------------------------------
(1, (2.1, 2.2), 3) -->
(1, 2.1, 2.2, 3)
----------------------------------------
*/
/*
----------------------------------------
get-last()
----------------------------------------
Return the last item of a list,
Return null if the value is null
----------------------------------------
*/
/*
----------------------------------------
has-important()
----------------------------------------
Check to see if `!important` is
being passed in a mixin's props
----------------------------------------
*/
/*
----------------------------------------
append-important()
----------------------------------------
Append `!important` to a list
----------------------------------------
*/
/*
----------------------------------------
spacing-multiple()
----------------------------------------
Converts a spacing unit multiple into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
rem-to-px()
----------------------------------------
Converts a value in rem to a value in px
----------------------------------------
*/
/*
----------------------------------------
rem-to-user-em()
----------------------------------------
Converts a value in rem to a value in
[user-settings] em for use in media
queries
----------------------------------------
*/
/*
----------------------------------------
validate-typeface-token()
----------------------------------------
Check to see if a typeface-token exists.
Throw an error if a passed token does
not exist in the typeface-token map.
----------------------------------------
*/
/*
----------------------------------------
cap-height()
----------------------------------------
Get the cap height of a valid typeface
----------------------------------------
*/
/*
----------------------------------------
px-to-rem()
----------------------------------------
Converts a value in px to a value in rem
----------------------------------------
*/
/*
----------------------------------------
normalize-type-scale()
----------------------------------------
Normalizes a specific face's optical size
to a set target
----------------------------------------
*/
/*
----------------------------------------
utility-font()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
Not the public-facing function.
Used for building the utilities and
withholds certain errors.
----------------------------------------
*/
/*
----------------------------------------
line-height()
lh()
----------------------------------------
Get a normalized line-height from
a family and a line-height scale unit
----------------------------------------
*/
/*
----------------------------------------
convert-to-font-type()
----------------------------------------
Converts a font-role token into a
font-type token. Leaves font-type tokens
unchanged.
----------------------------------------
*/
/*
----------------------------------------
get-font-stack()
----------------------------------------
Get a font stack from a style- or
role-based font token.
----------------------------------------
*/
/*
----------------------------------------
get-typeface-token()
----------------------------------------
Get a typeface token from a font-type or
font-role token.
----------------------------------------
*/
/*
----------------------------------------
get-system-color()
----------------------------------------
Derive a system color from its
family, value, and vivid or a passed
variable that is, itself, a list
----------------------------------------
*/
/*
----------------------------------------
system-type-scale()
----------------------------------------
Get a value from the system type scale
----------------------------------------
*/
/*
----------------------------------------
calc-gap-offset()
----------------------------------------
Calculate a valid uswds unit that is
half the width of a given unit, for
calculating gap offset in the layout
grid.
----------------------------------------
*/
/*
----------------------------------------
get-standard-values()
----------------------------------------
Gets a map of USWDS standard values
for a property
----------------------------------------
*/
/*
----------------------------------------
number-to-token()
----------------------------------------
Converts an integer or numeric value
into a system value

Ex: 0.5   --> '05'
    -1px  --> 'neg-1px'
----------------------------------------
*/
/*
----------------------------------------
columns()
----------------------------------------
outputs a grid-col number based on
the number of desired columns in the
12-column grid

Ex: columns(2) --> 6
    grid-col(columns(2))
----------------------------------------
*/
/*
----------------------------------------
get-uswds-value()
----------------------------------------
Finds and outputs a value from the
USWDS standard values.

Used to build other standard utility
functions and mixins.
----------------------------------------
*/
/*
----------------------------------------
pow()
----------------------------------------
Raises a unitless number to the power
of another unitless number

Includes helper functions
----------------------------------------
*/
/*
----------------------------------------
decompose-color-token()
----------------------------------------
Convert a color token into into a list
of form [family], [grade], [variant]

Vivid variants return "vivid" as the
variant.

If neither grade nor variant exists,
returns 'false'
----------------------------------------
*/
/*
----------------------------------------
test-colors()
----------------------------------------
Check to see if all system colors
fall between the proper relative
luminance range for their grade.

Has a couple quirks, as the luminance()
function returns slightly different
results than expected.
----------------------------------------
*/
/*
----------------------------------------
str-split()
----------------------------------------
Split a string at a given separator
and convert into a lisrt of substrings
----------------------------------------
*/
/*
----------------------------------------
str-replace()
----------------------------------------
Replace any substring with another
string
----------------------------------------
*/
/*
----------------------------------------
is-system-color-token()
----------------------------------------
Return whether a token is a system
color token
----------------------------------------
*/
/*
----------------------------------------
is-theme-color-token()
----------------------------------------
Return whether a token is a theme
color token
----------------------------------------
*/
/*
----------------------------------------
color-token-assignment()
----------------------------------------
Get the system token equivalent of any
theme color token
----------------------------------------
*/
/*
----------------------------------------
is-color-token()
----------------------------------------
Returns whether a given string is a
USWDS color token.
----------------------------------------
*/
/*
----------------------------------------
calculate-grade()
----------------------------------------
Derive the grade equivalent any color,
even non-token colors
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
advanced-color()
----------------------------------------
Derive a color from a color triplet:
[family], [grade], [variant]
----------------------------------------
*/
/*
----------------------------------------
units()
----------------------------------------
Converts a spacing unit into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
get-palettes()
----------------------------------------
Build a single map of plugin values
from a list of plugin keys.
----------------------------------------
*/
/*
----------------------------------------
border-radius()
----------------------------------------
Get a border-radius from the system
border-radii
----------------------------------------
*/
/*
----------------------------------------
font-weight()
fw()
----------------------------------------
Get a font-weight value from the
system font-weight
----------------------------------------
*/
/*
----------------------------------------
feature()
----------------------------------------
Gets a valid USWDS font feature setting
----------------------------------------
*/
/*
----------------------------------------
flex()
----------------------------------------
Gets a valid USWDS flex value
----------------------------------------
*/
/*
----------------------------------------
font-family()
family()
----------------------------------------
Get a font-family stack from a
role-based or type-based font family
----------------------------------------
*/
/*
----------------------------------------
letter-spacing()
ls()
----------------------------------------
Get a letter-spacing value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
measure()
----------------------------------------
Gets a valid USWDS reading line length
----------------------------------------
*/
/*
----------------------------------------
opacity()
----------------------------------------
Get an opacity from the system
opacities
----------------------------------------
*/
/*
----------------------------------------
order()
----------------------------------------
Get an order value from the
system orders
----------------------------------------
*/
/*
----------------------------------------
radius()
----------------------------------------
Get a border-radius value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
font-size()
----------------------------------------
Get type scale value from a [family] and
[scale]
----------------------------------------
*/
/*
----------------------------------------
z-index()
z()
----------------------------------------
Get a z-index value from the
system z-index
----------------------------------------
*/
/*
----------------------------------------
magic-number()
----------------------------------------
Returns the magic number of two color
grades. Takes numbers or color tokens.

magic-number(50, 10)
return: 40

magic-number("red-50", "red-10")
return: 40
----------------------------------------
*/
/*
----------------------------------------
get-default()
----------------------------------------
Returns the default value from a map
of project defaults

get-default("bg-color")
> $theme-body-background-color
----------------------------------------
*/
/*
----------------------------------------
get-color-token-from-bg()
----------------------------------------
Returns an accessible foreground color
token, given a background, preferred
color, fallback color, and WCAG target

returns: color-token

get-color-token-from-bg(
  "black",
  "red-60",
  "red-10",
  "AA")
> "red-10"
----------------------------------------
*/
/*
----------------------------------------
get-link-tokens-from-bg()
----------------------------------------
Get accessible link colors for a given
background color

returns: link-token, hover-token

get-link-tokens-from-bg(
  "black",
  "red-60",
  "red-10",
  "AA")
> "red-10", "red-5"

get-link-tokens-from-bg(
  "black",
  "red-60v",
  "red-10v",
  "AA-large")
> "red-60v", "red-50v"

get-link-tokens-from-bg(
  "black",
  "red-5v",
  "red-60v",
  "AA")
> "red-5v", "white"

get-link-tokens-from-bg(
  "black",
  "white",
  "red-60v",
  "AA")
> "white", "white"
----------------------------------------
*/
/*
----------------------------------------
color-token-type()
----------------------------------------
Returns the type of a color token.

Returns: "system" | "theme"
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.

Returns: color-family

color-token-family("accent-warm-vivid")
> "accent-warm"

color-token-family("red-50v")
> "red"

color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
color-token-grade()
----------------------------------------
Returns the grade of a USWDS color token.

Returns: color-grade

color-token-grade("accent-warm")
> "root"

color-token-grade("accent-warm-vivid")
> "root"

color-token-grade("accent-warm-darker")
> "darker"

color-token-grade("red-50v")
> 50

color-token-variant(("red", 50, "vivid"))
> 50
----------------------------------------
*/
/*
----------------------------------------
color-token-variant()
----------------------------------------
Returns the variant of color token.

Returns: "vivid" | false

color-token-variant("accent-warm")
> false

color-token-variant("accent-warm-vivid")
> "vivid"

color-token-variant("red-50v")
> "vivid"

color-token-variant(("red", 50, "vivid"))
> "vivid"
----------------------------------------
*/
/*
----------------------------------------
next-token()
----------------------------------------
Returns next "darker" or "lighter" color
token of the same token type and variant.

Returns: color-token | false

next-token("accent-warm", "lighter")
> "accent-warm-light"

next-token("gray-10", "lighter")
> "gray-5"

next-token("gray-5", "lighter")
> "white"

next-token("white", "lighter")
> false

next-token("red-50v", "darker")
> "red-60v"

next-token("red-50", "darker")
> "red-60"

next-token("red-80v", "darker")
> "red-90"

next-token("red-90", "darker")
> "black"

next-token("white", "darker")
> "gray-5"

next-token("black", "lighter")
> "gray-90"
----------------------------------------
*/
/*
----------------------------------------
wcag-magic-number()
----------------------------------------
Returns the magic number of a specific
wcag grade:

"AA"
"AA-Large"
"AAA"

wcag-magic-number("AA")
> 50
----------------------------------------
*/
/*
----------------------------------------
is-accessible-magic-number()
----------------------------------------
Returns whether two grades achieve
specified target color contrast

Returns: true | false

is-accessible-magic-number(10, 50, "AA")
> false

is-accessible-magic-number(10, 60, "AA")
> true
----------------------------------------
*/
/*
========================================
core/units
----------------------------------------
The master palettes of
- colors
- whitespace
- typescale
----------------------------------------
This file is not meant to be modified
by USWDS project teams. Change your
project values in
project/uswds-project-settings
----------------------------------------
*/
/*
----------------------------------------
Spacing grid multiplier
----------------------------------------
*/
/*
----------------------------------------
Spacing grid
----------------------------------------
*/
/*
----------------------------------------
Breakpoints
----------------------------------------
*/
/*
----------------------------------------
Units
----------------------------------------
*/
/*
----------------------------------------
Layout grid widths
----------------------------------------
*/
/*
----------------------------------------
Font stacks
----------------------------------------
*/
/*
----------------------------------------
Typeface
----------------------------------------
*/
/*
----------------------------------------
Typescale
----------------------------------------
*/
/*
----------------------------------------
Measure
----------------------------------------
*/
/*
----------------------------------------
Line height
----------------------------------------
*/
/*
----------------------------------------
Base cap height
----------------------------------------
500px 'N' height measured in Sketch.
Used for normalizing font sizes.
Current normalized to Apple system
fonts.
----------------------------------------
*/
/*
----------------------------------------
Luminance ranges
----------------------------------------
*/
/*
----------------------------------------
Colors
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0 Variables
Use for computed variables or any
not meant to be set by system users
directly.
----------------------------------------
*/
/*
----------------------------------------
Touch target size
----------------------------------------
*/
/*
----------------------------------------
Namespace
----------------------------------------
*/
/*
----------------------------------------
Separator
----------------------------------------
*/
/*
----------------------------------------
Spacing
----------------------------------------
All spacing values that can be called
by units()
----------------------------------------
*/
/*
----------------------------------------
Project fonts
----------------------------------------
Collects font settings in a map for
looping.
----------------------------------------
*/
/*
----------------------------------------
Font stack
----------------------------------------
Compute the project font stack based on
the project fonts and the font
definition values set in
core/_font-definitions
----------------------------------------
*/
/*
----------------------------------------
Cap heights
----------------------------------------
Collect project cap heights
----------------------------------------
*/
/*
----------------------------------------
Theme color families and grades
----------------------------------------
*/
/*
----------------------------------------
Theme color map
----------------------------------------
*/
/*
----------------------------------------
Theme color shortcodes
----------------------------------------
*/
/*
----------------------------------------
System magic numbers for color contrast
----------------------------------------
*/
/*
----------------------------------------
Build the project type scale map
----------------------------------------
*/
/*
----------------------------------------
Border-radius
----------------------------------------
*/
/*
----------------------------------------
Column gaps
----------------------------------------
*/
/*
----------------------------------------
Grid
----------------------------------------
*/
/*
----------------------------------------
Aspect Ratios
----------------------------------------
*/
/*
----------------------------------------
Easing
----------------------------------------
*/
/*
----------------------------------------
Project defaults
----------------------------------------
*/
/*
----------------------------------------
USWDS Properties
----------------------------------------
*/
/*
----------------------------------------
family()
----------------------------------------
Get a font-family stack
----------------------------------------
*/
/*
----------------------------------------
size()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
font()
----------------------------------------
Get a font-family stack
AND
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
typeset()
----------------------------------------
Sets:
- family
- size
- line-height
----------------------------------------
*/
/* stylelint-disable max-nesting-depth */
/*
----------------------------------------
@render-pseudoclass
----------------------------------------
Build a pseucoclass utiliy from values
calculated in the @render-utilities-in
loop
----------------------------------------
*/
/*
----------------------------------------
@render-utility
----------------------------------------
Build a utility from values calculated
in the @render-utilities-in loop
----------------------------------------
TODO: Determine the proper use of
unquote() in the following. Changed to
account for a 'interpolation near
operators will be simplified in a
future version of Sass' warning.
----------------------------------------
*/
/*
----------------------------------------
@render-utilities-in
----------------------------------------
The master loop that sets the building
blocks of utilities from the values
in individual rule settings and loops
through all possible variants
----------------------------------------
*/
/* stylelint-enable */
/*  deprecated.scss
    ---
    Occasionally the design system will deprecate
    old variables or functionality. If we replace
    the old functionality with something new, this is a
    place to connect the old functionality to the
    new functionality, in the service of better
    continuity and backwards compatibility within a
    major release cycle.

    Note the USWDS version where we deprecated the
    old functionality in a comment.

    Be sure to update notifications.scss.

    This file should started fresh at each
    major version.
*/
/*  notifications.scss
    ---
    Adds a notification at the top of each USWDS
    compile. Use this file for important notifications
    and updates to the design system.

    This file should started fresh at each
    major version.

*/
/* prettier-ignore */
/* prettier-ignore */
/*
----------------------------------------
Set basic font rules for the font
utilities to reference.
----------------------------------------
*/
[class*=font-lang-] {
  font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}

[class*=font-mono-] {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

[class*=font-sans-] {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

[class*=font-serif-] {
  font-family: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;
}

[class*=font-ui-] {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

[class*=font-heading-] {
  font-family: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;
}

[class*=font-body-] {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

[class*=font-code-] {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

[class*=font-alt-] {
  font-family: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;
}

/*
========================================
FONT PLUGINS
----------------------------------------
These plugins collect packaged
font-related values for inclusion in
utilities
----------------------------------------
*/
/*
----------------------------------------
role-based theme sizes
----------------------------------------
*/
/*
----------------------------------------
system font sizes
----------------------------------------
*/
/*
========================================
MISC PLUGINS
----------------------------------------
These plugins collect packaged
spacing-related values for inclusion
in utilities
----------------------------------------
*/
/*
========================================
STANDARD PLUGINS
----------------------------------------
These plugins collect standard property
values as plugin
----------------------------------------
*/
/*
========================================
PALETTE REGISTRY
----------------------------------------
This map collects the
available utility palettes
----------------------------------------
*/
/*
========================================
ASPECT
----------------------------------------
usage:
  .aspect-[value]
----------------------------------------
output:
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 100%;
----------------------------------------
example:
  .aspect-16x9
----------------------------------------
*/
/*
========================================
LIST RESET
----------------------------------------
usage:
  .list-reset
----------------------------------------
output:
  list-style: none;
  padding-left: 0;
----------------------------------------
example:
  .list-reset {
    list-style: none;
    padding-left: 0; }
----------------------------------------
*/
/*
========================================
align-items
----------------------------------------
usage:
  .flex-align-[key]
----------------------------------------
output:
  align-items: [value];
----------------------------------------
example:
  .flex-align-start {
    align-items: flex-start; }
----------------------------------------
*/
/*
========================================
align-self
----------------------------------------
usage:
  .flex-align-self-[key]
----------------------------------------
output:
  align-self: [value];
----------------------------------------
example:
  .flex-align-self-start {
    align-self: flex-start; }
----------------------------------------
*/
/*
========================================
background-color
----------------------------------------
usage:
  .bg-[key]
----------------------------------------
output:
  background-color: [value];
----------------------------------------
example:
  .bg-red-50v {
    background-color: #be4900; }
----------------------------------------
*/
/*
========================================
border
----------------------------------------
Also applies `solid`
Needs to come before other border utils
----------------------------------------
usage:
  .border-[modifier]*-[key]
----------------------------------------
output:
  border-[modifier]: [value];
----------------------------------------
example:
  .border-t-1px {
    border-top: 1px solid; }

  .border-0 {
    border: none; }
----------------------------------------
*/
/*
========================================
background-color
----------------------------------------
usage:
  .border-[color]
----------------------------------------
output:
  border[-color]: [color];
----------------------------------------
example:
  .border-red {
    border-color: #be4900; }
----------------------------------------
*/
/*
========================================
border-radius
----------------------------------------
usage:
  .border-radius-[key]
----------------------------------------
output:
  border-radius: [value];
----------------------------------------
example:
  .border-radius-0 {
    border-radius: 0; }

  .border-radius-pill {
    border-radius: 10em; }
----------------------------------------
*/
/*
========================================
border-style
----------------------------------------
usage:
  .border[-modifier]*-[key]
----------------------------------------
output:
  border-style: [value];
----------------------------------------
example:
  .border-dotted {
    border-style: dotted; }
----------------------------------------
*/
/*
========================================
border-width
----------------------------------------
usage:
  .border-[modifier]*-width-[key]
----------------------------------------
output:
  border-[modifier]-width: [value];
----------------------------------------
example:
  .border-top-width-1px {
    border-top-width: 1px; }
----------------------------------------
*/
/*
========================================
.bottom
----------------------------------------
property: bottom
----------------------------------------
usage:
  .bottom-[key]
----------------------------------------
output:
  bottom: [value];
----------------------------------------
example:
  .bottom-n2px {
    bottom: -2px; }
----------------------------------------
*/
/*
========================================
box-shadow
----------------------------------------
usage:
  .box-shadow-[value]
----------------------------------------
output:
  box-shadow: [value];
----------------------------------------
example:
  .box-shadow-1 {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10); }
----------------------------------------
*/
/*
========================================
circle
----------------------------------------
usage:
  .circle-[key]
----------------------------------------
output:
  height: [value];
  width: [value];
  border-radius: 50%;
----------------------------------------
example:
  .circle-6 {
    height: 3rem;
    width: 3rem; }
----------------------------------------
*/
/*
========================================
.clearfix
----------------------------------------
usage:
  .clearfix
----------------------------------------
example:
  .clearfix::after {
    clear: both;
    content: "";
    display: block; }
----------------------------------------
*/
/*
========================================
color
----------------------------------------
usage:
  .color-[key]
----------------------------------------
output:
  color: [value];
----------------------------------------
example:
  .color-black-100 {
    color: #000; }
----------------------------------------
*/
/*
========================================
color
----------------------------------------
usage:
  .cursor-[key]
----------------------------------------
output:
  cursor: [value];
----------------------------------------
example:
  .cursor-pointer {
    cursor: pointer; }
----------------------------------------
*/
/*
========================================
display
----------------------------------------
usage:
  .display-[key]
----------------------------------------
output:
  display: [value]
----------------------------------------
example:
  .display-none {
    display: none; }
----------------------------------------
*/
/*
========================================
float
----------------------------------------
usage:
  .float-[value]
----------------------------------------
output:
  float: [value];
----------------------------------------
example:
  .float-left {
    float: left; }
----------------------------------------
*/
/*
========================================
flex
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex: [value];
----------------------------------------
example:
  .flex-1 {
    flex: 1 0 0; }

  .flex-fill {
    flex: 1 0 0; }

  .flex-auto {
    flex: none; }
----------------------------------------
*/
/*
========================================
flex-direction
----------------------------------------
usage:
  .flex-direction-[value]
----------------------------------------
output:
  flex-direction: [value]
----------------------------------------
example:
  .flex-direction-row {
    flex-direction: row; }
----------------------------------------
*/
/*
========================================
flex-wrap
----------------------------------------
usage:
  .flex-wrap-[key]
----------------------------------------
output:
  flex-wrap: [value];
----------------------------------------
example:
  .flex-wrap {
    flex-wrap: wrap; }
----------------------------------------
*/
/*
========================================
font
----------------------------------------
sets font-size and font-family
----------------------------------------
usage:
  .size-[key]-[key]
----------------------------------------
output:
  font-family: [value];
  font-size: [value];
----------------------------------------
example:
  .size-sans-s3 {
    font-face: '18Franklin-webfont',
                system,
                -apple-system,
                BlinkMacSystemFont,
                'Roboto',
                'Helvetica Neue',
                'Helvetica',
                'Arial',
                sans-serif;
    font-size: 1rem; }
----------------------------------------
*/
/*
========================================
font-family
----------------------------------------
usage:
  .family-[key]
----------------------------------------
output:
  font-family: [value]
----------------------------------------
example:
  .family-sans {
    font-family: 'United Sans webfont',
                system,
                -apple-system,
                BlinkMacSystemFont,
                'Roboto',
                'Helvetica Neue',
                'Helvetica',
                'Arial',
                sans-serif; }
----------------------------------------
*/
/*
========================================
font-feature-settings
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-feature-settings: [value];
----------------------------------------
example:
  .text-tabular {
    font-feature-settings:
      'tnum' 1, 'kern' 1; }
----------------------------------------
*/
/*
========================================
font-style
----------------------------------------
usage:
  .text-italic
----------------------------------------
output:
  font-style: italic;
----------------------------------------
example:
  .text-italic {
    font-style: italic; }
  .text-no-italic {
    font-style: normal; }
----------------------------------------
*/
/*
========================================
font-weight
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-weight: [value];
----------------------------------------
example:
  .text-300 {
    font-weight: 300; }
----------------------------------------
*/
/*
========================================
height
----------------------------------------
usage:
  .height-[value]
----------------------------------------
output:
  height: [value];
----------------------------------------
example:
  .height-33ct {
    height: 33.33333333%; }
----------------------------------------
*/
/*
========================================
.justify-content
----------------------------------------
property: justify-content
----------------------------------------
usage:
  .flex-justify-[key]
----------------------------------------
output:
  justify-content: [value];
----------------------------------------
example:
  .flex-justify-start {
    justify-content: flex-start; }
----------------------------------------
*/
/*
========================================
.left
----------------------------------------
property: left
----------------------------------------
usage:
  .left-[key]
----------------------------------------
output:
  left: [value];
----------------------------------------
example:
  .left-n2px {
    left: -2px; }
----------------------------------------
*/
/*
========================================
letter-spacing
----------------------------------------
usage:
  .text-ls-[key]
----------------------------------------
output:
  letter-spacing: [value];
----------------------------------------
example:
  .text-ls-2 {
    letter-spacing: .1em; }
----------------------------------------
*/
/*
========================================
line-height
----------------------------------------
usage:
  .line-height-[value]
----------------------------------------
output:
  line-height: [value];
----------------------------------------
example:
  .line-height-sans-2 {
    line-height: 1.15; }
----------------------------------------
*/
/*
========================================
margin
----------------------------------------
usage:
  .margin-[modifier]*-[key]
----------------------------------------
output:
  margin: [value]
----------------------------------------
example:
  .margin-x-auto {
    margin-left: auto;
    margin-right: auto; }

  .margin-g105 {
    margin: .75rem; }
----------------------------------------
*/
/*
========================================
max-height
----------------------------------------
usage:
  .maxh[key]
----------------------------------------
output:
  max-height: [value];
----------------------------------------
example:
  .maxh-viewport {
    max-height: 100vh; }
----------------------------------------
*/
/*
========================================
max-width
----------------------------------------
usage:
  .maxw-[key]
----------------------------------------
output:
  max-width: [value];
----------------------------------------
example:
  .maxw-desktop {
    max-width: 960px; }
----------------------------------------
*/
/*
========================================
.measure
----------------------------------------
property: max-width
----------------------------------------
usage:
  .measure-[key]
----------------------------------------
output:
  max-width: [value];
----------------------------------------
example:
  .measure-4 {
    max-width: 68ex; }
----------------------------------------
*/
/*
========================================
min-height
----------------------------------------
usage:
  .minh-[key]
----------------------------------------
output:
  min-height: [value];
----------------------------------------
example:
  .minh-card {
    min-height: 200px; }
----------------------------------------
*/
/*
========================================
min-width
----------------------------------------
usage:
  .minw-[key]
----------------------------------------
output:
  min-width: [value];
----------------------------------------
example:
  .minw-desktop {
    min-width: 960px; }
----------------------------------------
*/
/*
========================================
opacity
----------------------------------------
usage:
  .opacity-[key]
----------------------------------------
output:
  opacity: [value];
----------------------------------------
example:
  .opacity-0 {
    opacity: 0; }
----------------------------------------
*/
/*
========================================
outline
----------------------------------------
sets width and style:solid
----------------------------------------
usage:
  .outline-[modifier]
----------------------------------------
output:
  outline: [modifier] solid;
----------------------------------------
example:
  .outline-g05 {
    outline: 4px solid }
----------------------------------------
*/
/*
========================================
outline-color
----------------------------------------
usage:
  .outline-color-[key]
----------------------------------------
output:
  outline-color: [value];
----------------------------------------
example:
  .outline-color-yellow-50 {
    outline-color: #91721f; }
----------------------------------------
*/
/*
========================================
overflow
----------------------------------------
usage:
  .overflow-[modifier]
----------------------------------------
output:
  overflow[-modifier]: [value] solid;
----------------------------------------
example:
  .overflow-y-hidden {
    overflow-y: hidden; }
----------------------------------------
*/
/*
========================================
order
----------------------------------------
usage:
  .order-[key]
----------------------------------------
output:
  order: [value]
----------------------------------------
example:
  .order-first {
    order: -1; }

  .order-2 {
    order: 2; }
----------------------------------------
*/
/*
========================================
padding
----------------------------------------
usage:
  .padding-[modifier]*-[value]
----------------------------------------
output:
  padding: [value]
----------------------------------------
example:
  .padding-0 {
    padding: none; }

  .padding-bottom-1px {
    padding-bottom: 1px; }
----------------------------------------
*/
/*
========================================
PIN
----------------------------------------
usage:
  .pin-all
----------------------------------------
output:
  bottom: 0
  left:0
  right: 0
  top: 0
----------------------------------------
example:
  .pin-all
----------------------------------------
*/
/*
========================================
position
----------------------------------------
usage:
  .position-[key]
----------------------------------------
output:
  position: [value];
----------------------------------------
example:
  .position-absolute {
    position: absolute; }
----------------------------------------
*/
/*
========================================
.right
----------------------------------------
property: right
----------------------------------------
usage:
  .right-[key]
----------------------------------------
output:
  right: [value];
----------------------------------------
example:
  .right-n2px {
    right: -2px; }
----------------------------------------
*/
/*
========================================
SQUARE
----------------------------------------
usage:
  .square-[key]
----------------------------------------
output:
  height: [value];
  width: [value];
----------------------------------------
example:
  .square-g6 {
    height: 3rem;
    width: 3rem; }
----------------------------------------
*/
/*
========================================
text-align
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  align: [value];
----------------------------------------
example:
  .text-left {
    align: left; }
----------------------------------------
*/
/*
========================================
text-decoration
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  text-decoration: [value]
----------------------------------------
example:
  .text-no-underline {
    text-decoration: none; }
----------------------------------------
*/
/*
========================================
text-decoration-color
----------------------------------------
usage:
  .text-decoration-color-[key]
----------------------------------------
output:
  text-decoration-color: [value]
----------------------------------------
example:
  .text-decoration-color-black {
    text-decoration-color: #000; }
----------------------------------------
*/
/*
========================================
text-indent
----------------------------------------
usage:
  .text-indent-[key]
----------------------------------------
output:
  align: [value];
----------------------------------------
example:
  .text-indent-0 {
    text-ident: 0; }
----------------------------------------
*/
/*
========================================
text-transform
----------------------------------------
usage:
  .text-[value]
----------------------------------------
output:
  text-transform: value;
----------------------------------------
example:
  .text-uppercase {
    text-transform: uppercase; }
----------------------------------------
*/
/*
========================================
.top
----------------------------------------
property: top
----------------------------------------
usage:
  .top-[key]
----------------------------------------
output:
  top: [value];
----------------------------------------
example:
  .top-n2px {
    top: -2px; }
----------------------------------------
*/
/*
========================================
vertical-align
----------------------------------------
usage:
  .vertical-align-[value]
----------------------------------------
output:
  vertical-align: [value]
----------------------------------------
example:
  .vertical-align-top {
    vertical-align: top; }
----------------------------------------
*/
/*
========================================
white-space
----------------------------------------
usage:
  text-[key]
----------------------------------------
output:
  white-space: [value];
----------------------------------------
example:
  .text-pre-wrap {
    white-space: pre-wrap; }
----------------------------------------
*/
/*
========================================
width
----------------------------------------
usage:
  .width-[key]
----------------------------------------
output:
  width: [value];
----------------------------------------
example:
  .width-75ct {
    width: 75%; }
----------------------------------------
*/
/*
========================================
z-index
----------------------------------------
usage:
  z-[key]
----------------------------------------
output:
  z-index: [value];
----------------------------------------
example:
  .z-100 {
    z-index: 100; }
  .z-top {
    z-index: 9999; }
----------------------------------------
*/
.add-aspect-9x16 {
  box-sizing: border-box !important;
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 0 177.77778% !important;
  position: relative !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.add-aspect-1x1 {
  box-sizing: border-box !important;
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 0 100% !important;
  position: relative !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.add-aspect-4x3 {
  box-sizing: border-box !important;
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 0 75% !important;
  position: relative !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.add-aspect-16x9 {
  box-sizing: border-box !important;
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 0 56.25% !important;
  position: relative !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.add-aspect-2x1 {
  box-sizing: border-box !important;
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 0 50% !important;
  position: relative !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.add-list-reset {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-left: 0 !important;
  list-style: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-start {
  align-items: flex-start !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-end {
  align-items: flex-end !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-center {
  align-items: center !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-stretch {
  align-items: stretch !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-baseline {
  align-items: baseline !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-self-start {
  align-self: flex-start !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-self-end {
  align-self: flex-end !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-self-center {
  align-self: center !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-self-stretch {
  align-self: stretch !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-align-self-baseline {
  align-self: baseline !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-5 {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.hover\:bg-black-transparent-5:hover {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-10 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.hover\:bg-black-transparent-10:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-20 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.hover\:bg-black-transparent-20:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-30 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.hover\:bg-black-transparent-30:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-40 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.hover\:bg-black-transparent-40:hover {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-50 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.hover\:bg-black-transparent-50:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-60 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.hover\:bg-black-transparent-60:hover {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-70 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.hover\:bg-black-transparent-70:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-80 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.hover\:bg-black-transparent-80:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black-transparent-90 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.hover\:bg-black-transparent-90:hover {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-1 {
  background-color: #fbfcfd !important;
}

.hover\:bg-gray-cool-1:hover {
  background-color: #fbfcfd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-2 {
  background-color: #f7f9fa !important;
}

.hover\:bg-gray-cool-2:hover {
  background-color: #f7f9fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-3 {
  background-color: #f5f6f7 !important;
}

.hover\:bg-gray-cool-3:hover {
  background-color: #f5f6f7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-4 {
  background-color: #f1f3f6 !important;
}

.hover\:bg-gray-cool-4:hover {
  background-color: #f1f3f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-5 {
  background-color: #edeff0 !important;
}

.hover\:bg-gray-cool-5:hover {
  background-color: #edeff0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-10 {
  background-color: #dfe1e2 !important;
}

.hover\:bg-gray-cool-10:hover {
  background-color: #dfe1e2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-20 {
  background-color: #c6cace !important;
}

.hover\:bg-gray-cool-20:hover {
  background-color: #c6cace !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-30 {
  background-color: #a9aeb1 !important;
}

.hover\:bg-gray-cool-30:hover {
  background-color: #a9aeb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-40 {
  background-color: #8d9297 !important;
}

.hover\:bg-gray-cool-40:hover {
  background-color: #8d9297 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-50 {
  background-color: #71767a !important;
}

.hover\:bg-gray-cool-50:hover {
  background-color: #71767a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-60 {
  background-color: #565c65 !important;
}

.hover\:bg-gray-cool-60:hover {
  background-color: #565c65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-70 {
  background-color: #3d4551 !important;
}

.hover\:bg-gray-cool-70:hover {
  background-color: #3d4551 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-80 {
  background-color: #2d2e2f !important;
}

.hover\:bg-gray-cool-80:hover {
  background-color: #2d2e2f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-cool-90 {
  background-color: #1c1d1f !important;
}

.hover\:bg-gray-cool-90:hover {
  background-color: #1c1d1f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-1 {
  background-color: #fcfcfc !important;
}

.hover\:bg-gray-1:hover {
  background-color: #fcfcfc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-2 {
  background-color: #f9f9f9 !important;
}

.hover\:bg-gray-2:hover {
  background-color: #f9f9f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-3 {
  background-color: #f6f6f6 !important;
}

.hover\:bg-gray-3:hover {
  background-color: #f6f6f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-4 {
  background-color: #f3f3f3 !important;
}

.hover\:bg-gray-4:hover {
  background-color: #f3f3f3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-5 {
  background-color: #f0f0f0 !important;
}

.hover\:bg-gray-5:hover {
  background-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-10 {
  background-color: #e6e6e6 !important;
}

.hover\:bg-gray-10:hover {
  background-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-20 {
  background-color: #c9c9c9 !important;
}

.hover\:bg-gray-20:hover {
  background-color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-30 {
  background-color: #adadad !important;
}

.hover\:bg-gray-30:hover {
  background-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-40 {
  background-color: #919191 !important;
}

.hover\:bg-gray-40:hover {
  background-color: #919191 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-50 {
  background-color: #757575 !important;
}

.hover\:bg-gray-50:hover {
  background-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-60 {
  background-color: #5c5c5c !important;
}

.hover\:bg-gray-60:hover {
  background-color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-70 {
  background-color: #454545 !important;
}

.hover\:bg-gray-70:hover {
  background-color: #454545 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-80 {
  background-color: #2e2e2e !important;
}

.hover\:bg-gray-80:hover {
  background-color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-90 {
  background-color: #1b1b1b !important;
}

.hover\:bg-gray-90:hover {
  background-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-1 {
  background-color: #fcfcfb !important;
}

.hover\:bg-gray-warm-1:hover {
  background-color: #fcfcfb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-2 {
  background-color: #f9f9f7 !important;
}

.hover\:bg-gray-warm-2:hover {
  background-color: #f9f9f7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-3 {
  background-color: #f6f6f2 !important;
}

.hover\:bg-gray-warm-3:hover {
  background-color: #f6f6f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-4 {
  background-color: #f5f5f0 !important;
}

.hover\:bg-gray-warm-4:hover {
  background-color: #f5f5f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-5 {
  background-color: #f0f0ec !important;
}

.hover\:bg-gray-warm-5:hover {
  background-color: #f0f0ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-10 {
  background-color: #e6e6e2 !important;
}

.hover\:bg-gray-warm-10:hover {
  background-color: #e6e6e2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-20 {
  background-color: #cac9c0 !important;
}

.hover\:bg-gray-warm-20:hover {
  background-color: #cac9c0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-30 {
  background-color: #afaea2 !important;
}

.hover\:bg-gray-warm-30:hover {
  background-color: #afaea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-40 {
  background-color: #929285 !important;
}

.hover\:bg-gray-warm-40:hover {
  background-color: #929285 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-50 {
  background-color: #76766a !important;
}

.hover\:bg-gray-warm-50:hover {
  background-color: #76766a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-60 {
  background-color: #5d5d52 !important;
}

.hover\:bg-gray-warm-60:hover {
  background-color: #5d5d52 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-70 {
  background-color: #454540 !important;
}

.hover\:bg-gray-warm-70:hover {
  background-color: #454540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-80 {
  background-color: #2e2e2a !important;
}

.hover\:bg-gray-warm-80:hover {
  background-color: #2e2e2a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gray-warm-90 {
  background-color: #171716 !important;
}

.hover\:bg-gray-warm-90:hover {
  background-color: #171716 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-5 {
  background-color: #e7f2f5 !important;
}

.hover\:bg-blue-cool-5:hover {
  background-color: #e7f2f5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-10 {
  background-color: #dae9ee !important;
}

.hover\:bg-blue-cool-10:hover {
  background-color: #dae9ee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-20 {
  background-color: #adcfdc !important;
}

.hover\:bg-blue-cool-20:hover {
  background-color: #adcfdc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-30 {
  background-color: #82b4c9 !important;
}

.hover\:bg-blue-cool-30:hover {
  background-color: #82b4c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-40 {
  background-color: #6499af !important;
}

.hover\:bg-blue-cool-40:hover {
  background-color: #6499af !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-50 {
  background-color: #3a7d95 !important;
}

.hover\:bg-blue-cool-50:hover {
  background-color: #3a7d95 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-60 {
  background-color: #2e6276 !important;
}

.hover\:bg-blue-cool-60:hover {
  background-color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-70 {
  background-color: #224a58 !important;
}

.hover\:bg-blue-cool-70:hover {
  background-color: #224a58 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-80 {
  background-color: #14333d !important;
}

.hover\:bg-blue-cool-80:hover {
  background-color: #14333d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-90 {
  background-color: #0f191c !important;
}

.hover\:bg-blue-cool-90:hover {
  background-color: #0f191c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-5v {
  background-color: #e1f3f8 !important;
}

.hover\:bg-blue-cool-5v:hover {
  background-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-10v {
  background-color: #c3ebfa !important;
}

.hover\:bg-blue-cool-10v:hover {
  background-color: #c3ebfa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-20v {
  background-color: #97d4ea !important;
}

.hover\:bg-blue-cool-20v:hover {
  background-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-30v {
  background-color: #59b9de !important;
}

.hover\:bg-blue-cool-30v:hover {
  background-color: #59b9de !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-40v {
  background-color: #28a0cb !important;
}

.hover\:bg-blue-cool-40v:hover {
  background-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-50v {
  background-color: #0d7ea2 !important;
}

.hover\:bg-blue-cool-50v:hover {
  background-color: #0d7ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-60v {
  background-color: #07648d !important;
}

.hover\:bg-blue-cool-60v:hover {
  background-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-70v {
  background-color: #074b69 !important;
}

.hover\:bg-blue-cool-70v:hover {
  background-color: #074b69 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-cool-80v {
  background-color: #002d3f !important;
}

.hover\:bg-blue-cool-80v:hover {
  background-color: #002d3f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-5 {
  background-color: #eff6fb !important;
}

.hover\:bg-blue-5:hover {
  background-color: #eff6fb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-10 {
  background-color: #d9e8f6 !important;
}

.hover\:bg-blue-10:hover {
  background-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-20 {
  background-color: #aacdec !important;
}

.hover\:bg-blue-20:hover {
  background-color: #aacdec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-30 {
  background-color: #73b3e7 !important;
}

.hover\:bg-blue-30:hover {
  background-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-40 {
  background-color: #4f97d1 !important;
}

.hover\:bg-blue-40:hover {
  background-color: #4f97d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-50 {
  background-color: #2378c3 !important;
}

.hover\:bg-blue-50:hover {
  background-color: #2378c3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-60 {
  background-color: #2c608a !important;
}

.hover\:bg-blue-60:hover {
  background-color: #2c608a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-70 {
  background-color: #274863 !important;
}

.hover\:bg-blue-70:hover {
  background-color: #274863 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-80 {
  background-color: #1f303e !important;
}

.hover\:bg-blue-80:hover {
  background-color: #1f303e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-90 {
  background-color: #11181d !important;
}

.hover\:bg-blue-90:hover {
  background-color: #11181d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-5v {
  background-color: #e8f5ff !important;
}

.hover\:bg-blue-5v:hover {
  background-color: #e8f5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-10v {
  background-color: #cfe8ff !important;
}

.hover\:bg-blue-10v:hover {
  background-color: #cfe8ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-20v {
  background-color: #a1d3ff !important;
}

.hover\:bg-blue-20v:hover {
  background-color: #a1d3ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-30v {
  background-color: #58b4ff !important;
}

.hover\:bg-blue-30v:hover {
  background-color: #58b4ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-40v {
  background-color: #2491ff !important;
}

.hover\:bg-blue-40v:hover {
  background-color: #2491ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-50v {
  background-color: #0076d6 !important;
}

.hover\:bg-blue-50v:hover {
  background-color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-60v {
  background-color: #005ea2 !important;
}

.hover\:bg-blue-60v:hover {
  background-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-70v {
  background-color: #0b4778 !important;
}

.hover\:bg-blue-70v:hover {
  background-color: #0b4778 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-80v {
  background-color: #112f4e !important;
}

.hover\:bg-blue-80v:hover {
  background-color: #112f4e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-5 {
  background-color: #ecf1f7 !important;
}

.hover\:bg-blue-warm-5:hover {
  background-color: #ecf1f7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-10 {
  background-color: #e1e7f1 !important;
}

.hover\:bg-blue-warm-10:hover {
  background-color: #e1e7f1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-20 {
  background-color: #bbcae4 !important;
}

.hover\:bg-blue-warm-20:hover {
  background-color: #bbcae4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-30 {
  background-color: #98afd2 !important;
}

.hover\:bg-blue-warm-30:hover {
  background-color: #98afd2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-40 {
  background-color: #7292c7 !important;
}

.hover\:bg-blue-warm-40:hover {
  background-color: #7292c7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-50 {
  background-color: #4a77b4 !important;
}

.hover\:bg-blue-warm-50:hover {
  background-color: #4a77b4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-60 {
  background-color: #345d96 !important;
}

.hover\:bg-blue-warm-60:hover {
  background-color: #345d96 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-70 {
  background-color: #2f4668 !important;
}

.hover\:bg-blue-warm-70:hover {
  background-color: #2f4668 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-80 {
  background-color: #252f3e !important;
}

.hover\:bg-blue-warm-80:hover {
  background-color: #252f3e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-90 {
  background-color: #13171f !important;
}

.hover\:bg-blue-warm-90:hover {
  background-color: #13171f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-5v {
  background-color: #edf5ff !important;
}

.hover\:bg-blue-warm-5v:hover {
  background-color: #edf5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-10v {
  background-color: #d4e5ff !important;
}

.hover\:bg-blue-warm-10v:hover {
  background-color: #d4e5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-20v {
  background-color: #adcdff !important;
}

.hover\:bg-blue-warm-20v:hover {
  background-color: #adcdff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-30v {
  background-color: #81aefc !important;
}

.hover\:bg-blue-warm-30v:hover {
  background-color: #81aefc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-40v {
  background-color: #5994f6 !important;
}

.hover\:bg-blue-warm-40v:hover {
  background-color: #5994f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-50v {
  background-color: #2672de !important;
}

.hover\:bg-blue-warm-50v:hover {
  background-color: #2672de !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-60v {
  background-color: #0050d8 !important;
}

.hover\:bg-blue-warm-60v:hover {
  background-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-70v {
  background-color: #1a4480 !important;
}

.hover\:bg-blue-warm-70v:hover {
  background-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue-warm-80v {
  background-color: #162e51 !important;
}

.hover\:bg-blue-warm-80v:hover {
  background-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-5 {
  background-color: #e7f6f8 !important;
}

.hover\:bg-cyan-5:hover {
  background-color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-10 {
  background-color: #ccecf2 !important;
}

.hover\:bg-cyan-10:hover {
  background-color: #ccecf2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-20 {
  background-color: #99deea !important;
}

.hover\:bg-cyan-20:hover {
  background-color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-30 {
  background-color: #5dc0d1 !important;
}

.hover\:bg-cyan-30:hover {
  background-color: #5dc0d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-40 {
  background-color: #449dac !important;
}

.hover\:bg-cyan-40:hover {
  background-color: #449dac !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-50 {
  background-color: #168092 !important;
}

.hover\:bg-cyan-50:hover {
  background-color: #168092 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-60 {
  background-color: #2a646d !important;
}

.hover\:bg-cyan-60:hover {
  background-color: #2a646d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-70 {
  background-color: #2c4a4e !important;
}

.hover\:bg-cyan-70:hover {
  background-color: #2c4a4e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-80 {
  background-color: #203133 !important;
}

.hover\:bg-cyan-80:hover {
  background-color: #203133 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-90 {
  background-color: #111819 !important;
}

.hover\:bg-cyan-90:hover {
  background-color: #111819 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-5v {
  background-color: #e5faff !important;
}

.hover\:bg-cyan-5v:hover {
  background-color: #e5faff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-10v {
  background-color: #a8f2ff !important;
}

.hover\:bg-cyan-10v:hover {
  background-color: #a8f2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-20v {
  background-color: #52daf2 !important;
}

.hover\:bg-cyan-20v:hover {
  background-color: #52daf2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-30v {
  background-color: #00bde3 !important;
}

.hover\:bg-cyan-30v:hover {
  background-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-40v {
  background-color: #009ec1 !important;
}

.hover\:bg-cyan-40v:hover {
  background-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-50v {
  background-color: #0081a1 !important;
}

.hover\:bg-cyan-50v:hover {
  background-color: #0081a1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-60v {
  background-color: #00687d !important;
}

.hover\:bg-cyan-60v:hover {
  background-color: #00687d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-70v {
  background-color: #0e4f5c !important;
}

.hover\:bg-cyan-70v:hover {
  background-color: #0e4f5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan-80v {
  background-color: #093b44 !important;
}

.hover\:bg-cyan-80v:hover {
  background-color: #093b44 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-5 {
  background-color: #f5f0e6 !important;
}

.hover\:bg-gold-5:hover {
  background-color: #f5f0e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-10 {
  background-color: #f1e5cd !important;
}

.hover\:bg-gold-10:hover {
  background-color: #f1e5cd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-20 {
  background-color: #dec69a !important;
}

.hover\:bg-gold-20:hover {
  background-color: #dec69a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-30 {
  background-color: #c7a97b !important;
}

.hover\:bg-gold-30:hover {
  background-color: #c7a97b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-40 {
  background-color: #ad8b65 !important;
}

.hover\:bg-gold-40:hover {
  background-color: #ad8b65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-50 {
  background-color: #8e704f !important;
}

.hover\:bg-gold-50:hover {
  background-color: #8e704f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-60 {
  background-color: #6b5947 !important;
}

.hover\:bg-gold-60:hover {
  background-color: #6b5947 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-70 {
  background-color: #4d4438 !important;
}

.hover\:bg-gold-70:hover {
  background-color: #4d4438 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-80 {
  background-color: #322d26 !important;
}

.hover\:bg-gold-80:hover {
  background-color: #322d26 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-90 {
  background-color: #191714 !important;
}

.hover\:bg-gold-90:hover {
  background-color: #191714 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-5v {
  background-color: #fef0c8 !important;
}

.hover\:bg-gold-5v:hover {
  background-color: #fef0c8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-10v {
  background-color: #ffe396 !important;
}

.hover\:bg-gold-10v:hover {
  background-color: #ffe396 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-20v {
  background-color: #ffbe2e !important;
}

.hover\:bg-gold-20v:hover {
  background-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-30v {
  background-color: #e5a000 !important;
}

.hover\:bg-gold-30v:hover {
  background-color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-40v {
  background-color: #c2850c !important;
}

.hover\:bg-gold-40v:hover {
  background-color: #c2850c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-50v {
  background-color: #936f38 !important;
}

.hover\:bg-gold-50v:hover {
  background-color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-60v {
  background-color: #7a591a !important;
}

.hover\:bg-gold-60v:hover {
  background-color: #7a591a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-70v {
  background-color: #5c410a !important;
}

.hover\:bg-gold-70v:hover {
  background-color: #5c410a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold-80v {
  background-color: #3b2b15 !important;
}

.hover\:bg-gold-80v:hover {
  background-color: #3b2b15 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-5 {
  background-color: #ecf3ec !important;
}

.hover\:bg-green-cool-5:hover {
  background-color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-10 {
  background-color: #dbebde !important;
}

.hover\:bg-green-cool-10:hover {
  background-color: #dbebde !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-20 {
  background-color: #b4d0b9 !important;
}

.hover\:bg-green-cool-20:hover {
  background-color: #b4d0b9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-30 {
  background-color: #86b98e !important;
}

.hover\:bg-green-cool-30:hover {
  background-color: #86b98e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-40 {
  background-color: #5e9f69 !important;
}

.hover\:bg-green-cool-40:hover {
  background-color: #5e9f69 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-50 {
  background-color: #4d8055 !important;
}

.hover\:bg-green-cool-50:hover {
  background-color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-60 {
  background-color: #446443 !important;
}

.hover\:bg-green-cool-60:hover {
  background-color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-70 {
  background-color: #37493b !important;
}

.hover\:bg-green-cool-70:hover {
  background-color: #37493b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-80 {
  background-color: #28312a !important;
}

.hover\:bg-green-cool-80:hover {
  background-color: #28312a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-90 {
  background-color: #1a1f1a !important;
}

.hover\:bg-green-cool-90:hover {
  background-color: #1a1f1a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-5v {
  background-color: #e3f5e1 !important;
}

.hover\:bg-green-cool-5v:hover {
  background-color: #e3f5e1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-10v {
  background-color: #b7f5bd !important;
}

.hover\:bg-green-cool-10v:hover {
  background-color: #b7f5bd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-20v {
  background-color: #70e17b !important;
}

.hover\:bg-green-cool-20v:hover {
  background-color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-30v {
  background-color: #21c834 !important;
}

.hover\:bg-green-cool-30v:hover {
  background-color: #21c834 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-40v {
  background-color: #00a91c !important;
}

.hover\:bg-green-cool-40v:hover {
  background-color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-50v {
  background-color: #008817 !important;
}

.hover\:bg-green-cool-50v:hover {
  background-color: #008817 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-60v {
  background-color: #216e1f !important;
}

.hover\:bg-green-cool-60v:hover {
  background-color: #216e1f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-70v {
  background-color: #154c21 !important;
}

.hover\:bg-green-cool-70v:hover {
  background-color: #154c21 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-cool-80v {
  background-color: #19311e !important;
}

.hover\:bg-green-cool-80v:hover {
  background-color: #19311e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-5 {
  background-color: #eaf4dd !important;
}

.hover\:bg-green-5:hover {
  background-color: #eaf4dd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-10 {
  background-color: #dfeacd !important;
}

.hover\:bg-green-10:hover {
  background-color: #dfeacd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-20 {
  background-color: #b8d293 !important;
}

.hover\:bg-green-20:hover {
  background-color: #b8d293 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-30 {
  background-color: #9bb672 !important;
}

.hover\:bg-green-30:hover {
  background-color: #9bb672 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-40 {
  background-color: #7d9b4e !important;
}

.hover\:bg-green-40:hover {
  background-color: #7d9b4e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-50 {
  background-color: #607f35 !important;
}

.hover\:bg-green-50:hover {
  background-color: #607f35 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-60 {
  background-color: #4c6424 !important;
}

.hover\:bg-green-60:hover {
  background-color: #4c6424 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-70 {
  background-color: #3c4a29 !important;
}

.hover\:bg-green-70:hover {
  background-color: #3c4a29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-80 {
  background-color: #293021 !important;
}

.hover\:bg-green-80:hover {
  background-color: #293021 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-90 {
  background-color: #161814 !important;
}

.hover\:bg-green-90:hover {
  background-color: #161814 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-5v {
  background-color: #ddf9c7 !important;
}

.hover\:bg-green-5v:hover {
  background-color: #ddf9c7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-10v {
  background-color: #c5ee93 !important;
}

.hover\:bg-green-10v:hover {
  background-color: #c5ee93 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-20v {
  background-color: #98d035 !important;
}

.hover\:bg-green-20v:hover {
  background-color: #98d035 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-30v {
  background-color: #7fb135 !important;
}

.hover\:bg-green-30v:hover {
  background-color: #7fb135 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-40v {
  background-color: #719f2a !important;
}

.hover\:bg-green-40v:hover {
  background-color: #719f2a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-50v {
  background-color: #538200 !important;
}

.hover\:bg-green-50v:hover {
  background-color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-60v {
  background-color: #466c04 !important;
}

.hover\:bg-green-60v:hover {
  background-color: #466c04 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-70v {
  background-color: #2f4a0b !important;
}

.hover\:bg-green-70v:hover {
  background-color: #2f4a0b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-80v {
  background-color: #243413 !important;
}

.hover\:bg-green-80v:hover {
  background-color: #243413 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-5 {
  background-color: #f1f4d7 !important;
}

.hover\:bg-green-warm-5:hover {
  background-color: #f1f4d7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-10 {
  background-color: #e7eab7 !important;
}

.hover\:bg-green-warm-10:hover {
  background-color: #e7eab7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-20 {
  background-color: #cbd17a !important;
}

.hover\:bg-green-warm-20:hover {
  background-color: #cbd17a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-30 {
  background-color: #a6b557 !important;
}

.hover\:bg-green-warm-30:hover {
  background-color: #a6b557 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-40 {
  background-color: #8a984b !important;
}

.hover\:bg-green-warm-40:hover {
  background-color: #8a984b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-50 {
  background-color: #6f7a41 !important;
}

.hover\:bg-green-warm-50:hover {
  background-color: #6f7a41 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-60 {
  background-color: #5a5f38 !important;
}

.hover\:bg-green-warm-60:hover {
  background-color: #5a5f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-70 {
  background-color: #45472f !important;
}

.hover\:bg-green-warm-70:hover {
  background-color: #45472f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-80 {
  background-color: #2d2f21 !important;
}

.hover\:bg-green-warm-80:hover {
  background-color: #2d2f21 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-90 {
  background-color: #171712 !important;
}

.hover\:bg-green-warm-90:hover {
  background-color: #171712 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-5v {
  background-color: #f5fbc1 !important;
}

.hover\:bg-green-warm-5v:hover {
  background-color: #f5fbc1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-10v {
  background-color: #e7f434 !important;
}

.hover\:bg-green-warm-10v:hover {
  background-color: #e7f434 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-20v {
  background-color: #c5d30a !important;
}

.hover\:bg-green-warm-20v:hover {
  background-color: #c5d30a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-30v {
  background-color: #a3b72c !important;
}

.hover\:bg-green-warm-30v:hover {
  background-color: #a3b72c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-40v {
  background-color: #7e9c1d !important;
}

.hover\:bg-green-warm-40v:hover {
  background-color: #7e9c1d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-50v {
  background-color: #6a7d00 !important;
}

.hover\:bg-green-warm-50v:hover {
  background-color: #6a7d00 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-60v {
  background-color: #5a6613 !important;
}

.hover\:bg-green-warm-60v:hover {
  background-color: #5a6613 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-70v {
  background-color: #4b4e10 !important;
}

.hover\:bg-green-warm-70v:hover {
  background-color: #4b4e10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green-warm-80v {
  background-color: #38380b !important;
}

.hover\:bg-green-warm-80v:hover {
  background-color: #38380b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-5 {
  background-color: #eef0f9 !important;
}

.hover\:bg-indigo-cool-5:hover {
  background-color: #eef0f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-10 {
  background-color: #e1e6f9 !important;
}

.hover\:bg-indigo-cool-10:hover {
  background-color: #e1e6f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-20 {
  background-color: #bbc8f5 !important;
}

.hover\:bg-indigo-cool-20:hover {
  background-color: #bbc8f5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-30 {
  background-color: #96abee !important;
}

.hover\:bg-indigo-cool-30:hover {
  background-color: #96abee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-40 {
  background-color: #6b8ee8 !important;
}

.hover\:bg-indigo-cool-40:hover {
  background-color: #6b8ee8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-50 {
  background-color: #496fd8 !important;
}

.hover\:bg-indigo-cool-50:hover {
  background-color: #496fd8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-60 {
  background-color: #3f57a6 !important;
}

.hover\:bg-indigo-cool-60:hover {
  background-color: #3f57a6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-70 {
  background-color: #374274 !important;
}

.hover\:bg-indigo-cool-70:hover {
  background-color: #374274 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-80 {
  background-color: #292d42 !important;
}

.hover\:bg-indigo-cool-80:hover {
  background-color: #292d42 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-90 {
  background-color: #151622 !important;
}

.hover\:bg-indigo-cool-90:hover {
  background-color: #151622 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-5v {
  background-color: #edf0ff !important;
}

.hover\:bg-indigo-cool-5v:hover {
  background-color: #edf0ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-10v {
  background-color: #dee5ff !important;
}

.hover\:bg-indigo-cool-10v:hover {
  background-color: #dee5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-20v {
  background-color: #b8c8ff !important;
}

.hover\:bg-indigo-cool-20v:hover {
  background-color: #b8c8ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-30v {
  background-color: #94adff !important;
}

.hover\:bg-indigo-cool-30v:hover {
  background-color: #94adff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-40v {
  background-color: #628ef4 !important;
}

.hover\:bg-indigo-cool-40v:hover {
  background-color: #628ef4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-50v {
  background-color: #4866ff !important;
}

.hover\:bg-indigo-cool-50v:hover {
  background-color: #4866ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-60v {
  background-color: #3e4ded !important;
}

.hover\:bg-indigo-cool-60v:hover {
  background-color: #3e4ded !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-70v {
  background-color: #222fbf !important;
}

.hover\:bg-indigo-cool-70v:hover {
  background-color: #222fbf !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-cool-80v {
  background-color: #1b2b85 !important;
}

.hover\:bg-indigo-cool-80v:hover {
  background-color: #1b2b85 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-5 {
  background-color: #efeff8 !important;
}

.hover\:bg-indigo-5:hover {
  background-color: #efeff8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-10 {
  background-color: #e5e4fa !important;
}

.hover\:bg-indigo-10:hover {
  background-color: #e5e4fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-20 {
  background-color: #c5c5f3 !important;
}

.hover\:bg-indigo-20:hover {
  background-color: #c5c5f3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-30 {
  background-color: #a5a8eb !important;
}

.hover\:bg-indigo-30:hover {
  background-color: #a5a8eb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-40 {
  background-color: #8889db !important;
}

.hover\:bg-indigo-40:hover {
  background-color: #8889db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-50 {
  background-color: #676cc8 !important;
}

.hover\:bg-indigo-50:hover {
  background-color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-60 {
  background-color: #4d52af !important;
}

.hover\:bg-indigo-60:hover {
  background-color: #4d52af !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-70 {
  background-color: #3d4076 !important;
}

.hover\:bg-indigo-70:hover {
  background-color: #3d4076 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-80 {
  background-color: #2b2c40 !important;
}

.hover\:bg-indigo-80:hover {
  background-color: #2b2c40 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-90 {
  background-color: #16171f !important;
}

.hover\:bg-indigo-90:hover {
  background-color: #16171f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-5v {
  background-color: #f0f0ff !important;
}

.hover\:bg-indigo-5v:hover {
  background-color: #f0f0ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-10v {
  background-color: #e0e0ff !important;
}

.hover\:bg-indigo-10v:hover {
  background-color: #e0e0ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-20v {
  background-color: #ccceff !important;
}

.hover\:bg-indigo-20v:hover {
  background-color: #ccceff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-30v {
  background-color: #a3a7fa !important;
}

.hover\:bg-indigo-30v:hover {
  background-color: #a3a7fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-40v {
  background-color: #8289ff !important;
}

.hover\:bg-indigo-40v:hover {
  background-color: #8289ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-50v {
  background-color: #656bd7 !important;
}

.hover\:bg-indigo-50v:hover {
  background-color: #656bd7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-60v {
  background-color: #4a50c4 !important;
}

.hover\:bg-indigo-60v:hover {
  background-color: #4a50c4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-70v {
  background-color: #3333a3 !important;
}

.hover\:bg-indigo-70v:hover {
  background-color: #3333a3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-80v {
  background-color: #212463 !important;
}

.hover\:bg-indigo-80v:hover {
  background-color: #212463 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-5 {
  background-color: #f1eff7 !important;
}

.hover\:bg-indigo-warm-5:hover {
  background-color: #f1eff7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-10 {
  background-color: #e7e3fa !important;
}

.hover\:bg-indigo-warm-10:hover {
  background-color: #e7e3fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-20 {
  background-color: #cbc4f2 !important;
}

.hover\:bg-indigo-warm-20:hover {
  background-color: #cbc4f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-30 {
  background-color: #afa5e8 !important;
}

.hover\:bg-indigo-warm-30:hover {
  background-color: #afa5e8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-40 {
  background-color: #9287d8 !important;
}

.hover\:bg-indigo-warm-40:hover {
  background-color: #9287d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-50 {
  background-color: #7665d1 !important;
}

.hover\:bg-indigo-warm-50:hover {
  background-color: #7665d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-60 {
  background-color: #5e519e !important;
}

.hover\:bg-indigo-warm-60:hover {
  background-color: #5e519e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-70 {
  background-color: #453c7b !important;
}

.hover\:bg-indigo-warm-70:hover {
  background-color: #453c7b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-80 {
  background-color: #2e2c40 !important;
}

.hover\:bg-indigo-warm-80:hover {
  background-color: #2e2c40 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-90 {
  background-color: #18161d !important;
}

.hover\:bg-indigo-warm-90:hover {
  background-color: #18161d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-5v {
  background-color: #f5f2ff !important;
}

.hover\:bg-indigo-warm-5v:hover {
  background-color: #f5f2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-10v {
  background-color: #e4deff !important;
}

.hover\:bg-indigo-warm-10v:hover {
  background-color: #e4deff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-20v {
  background-color: #cfc4fd !important;
}

.hover\:bg-indigo-warm-20v:hover {
  background-color: #cfc4fd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-30v {
  background-color: #b69fff !important;
}

.hover\:bg-indigo-warm-30v:hover {
  background-color: #b69fff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-40v {
  background-color: #967efb !important;
}

.hover\:bg-indigo-warm-40v:hover {
  background-color: #967efb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-50v {
  background-color: #745fe9 !important;
}

.hover\:bg-indigo-warm-50v:hover {
  background-color: #745fe9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-60v {
  background-color: #5942d2 !important;
}

.hover\:bg-indigo-warm-60v:hover {
  background-color: #5942d2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-70v {
  background-color: #3d2c9d !important;
}

.hover\:bg-indigo-warm-70v:hover {
  background-color: #3d2c9d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo-warm-80v {
  background-color: #261f5b !important;
}

.hover\:bg-indigo-warm-80v:hover {
  background-color: #261f5b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-5 {
  background-color: #f9f0f2 !important;
}

.hover\:bg-magenta-5:hover {
  background-color: #f9f0f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-10 {
  background-color: #f6e1e8 !important;
}

.hover\:bg-magenta-10:hover {
  background-color: #f6e1e8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-20 {
  background-color: #f0bbcc !important;
}

.hover\:bg-magenta-20:hover {
  background-color: #f0bbcc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-30 {
  background-color: #e895b3 !important;
}

.hover\:bg-magenta-30:hover {
  background-color: #e895b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-40 {
  background-color: #e0699f !important;
}

.hover\:bg-magenta-40:hover {
  background-color: #e0699f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-50 {
  background-color: #c84281 !important;
}

.hover\:bg-magenta-50:hover {
  background-color: #c84281 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-60 {
  background-color: #8b4566 !important;
}

.hover\:bg-magenta-60:hover {
  background-color: #8b4566 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-70 {
  background-color: #66364b !important;
}

.hover\:bg-magenta-70:hover {
  background-color: #66364b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-80 {
  background-color: #402731 !important;
}

.hover\:bg-magenta-80:hover {
  background-color: #402731 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-90 {
  background-color: #1b1617 !important;
}

.hover\:bg-magenta-90:hover {
  background-color: #1b1617 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-5v {
  background-color: #f9f0f2 !important;
}

.hover\:bg-magenta-5v:hover {
  background-color: #f9f0f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-10v {
  background-color: #ffddea !important;
}

.hover\:bg-magenta-10v:hover {
  background-color: #ffddea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-20v {
  background-color: #ffb4cf !important;
}

.hover\:bg-magenta-20v:hover {
  background-color: #ffb4cf !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-30v {
  background-color: #ff87b2 !important;
}

.hover\:bg-magenta-30v:hover {
  background-color: #ff87b2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-40v {
  background-color: #fd4496 !important;
}

.hover\:bg-magenta-40v:hover {
  background-color: #fd4496 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-50v {
  background-color: #d72d79 !important;
}

.hover\:bg-magenta-50v:hover {
  background-color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-60v {
  background-color: #ab2165 !important;
}

.hover\:bg-magenta-60v:hover {
  background-color: #ab2165 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-70v {
  background-color: #731f44 !important;
}

.hover\:bg-magenta-70v:hover {
  background-color: #731f44 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta-80v {
  background-color: #4f172e !important;
}

.hover\:bg-magenta-80v:hover {
  background-color: #4f172e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-5 {
  background-color: #e0f7f6 !important;
}

.hover\:bg-mint-cool-5:hover {
  background-color: #e0f7f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-10 {
  background-color: #c4eeeb !important;
}

.hover\:bg-mint-cool-10:hover {
  background-color: #c4eeeb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-20 {
  background-color: #9bd4cf !important;
}

.hover\:bg-mint-cool-20:hover {
  background-color: #9bd4cf !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-30 {
  background-color: #6fbab3 !important;
}

.hover\:bg-mint-cool-30:hover {
  background-color: #6fbab3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-40 {
  background-color: #4f9e99 !important;
}

.hover\:bg-mint-cool-40:hover {
  background-color: #4f9e99 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-50 {
  background-color: #40807e !important;
}

.hover\:bg-mint-cool-50:hover {
  background-color: #40807e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-60 {
  background-color: #376462 !important;
}

.hover\:bg-mint-cool-60:hover {
  background-color: #376462 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-70 {
  background-color: #2a4b45 !important;
}

.hover\:bg-mint-cool-70:hover {
  background-color: #2a4b45 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-80 {
  background-color: #203131 !important;
}

.hover\:bg-mint-cool-80:hover {
  background-color: #203131 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-90 {
  background-color: #111818 !important;
}

.hover\:bg-mint-cool-90:hover {
  background-color: #111818 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-5v {
  background-color: #d5fbf3 !important;
}

.hover\:bg-mint-cool-5v:hover {
  background-color: #d5fbf3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-10v {
  background-color: #7efbe1 !important;
}

.hover\:bg-mint-cool-10v:hover {
  background-color: #7efbe1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-20v {
  background-color: #29e1cb !important;
}

.hover\:bg-mint-cool-20v:hover {
  background-color: #29e1cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-30v {
  background-color: #1dc2ae !important;
}

.hover\:bg-mint-cool-30v:hover {
  background-color: #1dc2ae !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-40v {
  background-color: #00a398 !important;
}

.hover\:bg-mint-cool-40v:hover {
  background-color: #00a398 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-50v {
  background-color: #008480 !important;
}

.hover\:bg-mint-cool-50v:hover {
  background-color: #008480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-60v {
  background-color: #0f6460 !important;
}

.hover\:bg-mint-cool-60v:hover {
  background-color: #0f6460 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-70v {
  background-color: #0b4b3f !important;
}

.hover\:bg-mint-cool-70v:hover {
  background-color: #0b4b3f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-cool-80v {
  background-color: #123131 !important;
}

.hover\:bg-mint-cool-80v:hover {
  background-color: #123131 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-5 {
  background-color: #dbf6ed !important;
}

.hover\:bg-mint-5:hover {
  background-color: #dbf6ed !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-10 {
  background-color: #c7efe2 !important;
}

.hover\:bg-mint-10:hover {
  background-color: #c7efe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-20 {
  background-color: #92d9bb !important;
}

.hover\:bg-mint-20:hover {
  background-color: #92d9bb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-30 {
  background-color: #5abf95 !important;
}

.hover\:bg-mint-30:hover {
  background-color: #5abf95 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-40 {
  background-color: #34a37e !important;
}

.hover\:bg-mint-40:hover {
  background-color: #34a37e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-50 {
  background-color: #2e8367 !important;
}

.hover\:bg-mint-50:hover {
  background-color: #2e8367 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-60 {
  background-color: #286846 !important;
}

.hover\:bg-mint-60:hover {
  background-color: #286846 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-70 {
  background-color: #204e34 !important;
}

.hover\:bg-mint-70:hover {
  background-color: #204e34 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-80 {
  background-color: #193324 !important;
}

.hover\:bg-mint-80:hover {
  background-color: #193324 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-90 {
  background-color: #0d1a12 !important;
}

.hover\:bg-mint-90:hover {
  background-color: #0d1a12 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-5v {
  background-color: #c9fbeb !important;
}

.hover\:bg-mint-5v:hover {
  background-color: #c9fbeb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-10v {
  background-color: #83fcd4 !important;
}

.hover\:bg-mint-10v:hover {
  background-color: #83fcd4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-20v {
  background-color: #0ceda6 !important;
}

.hover\:bg-mint-20v:hover {
  background-color: #0ceda6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-30v {
  background-color: #04c585 !important;
}

.hover\:bg-mint-30v:hover {
  background-color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-40v {
  background-color: #00a871 !important;
}

.hover\:bg-mint-40v:hover {
  background-color: #00a871 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-50v {
  background-color: #008659 !important;
}

.hover\:bg-mint-50v:hover {
  background-color: #008659 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-60v {
  background-color: #146947 !important;
}

.hover\:bg-mint-60v:hover {
  background-color: #146947 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-70v {
  background-color: #0c4e29 !important;
}

.hover\:bg-mint-70v:hover {
  background-color: #0c4e29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint-80v {
  background-color: #0d351e !important;
}

.hover\:bg-mint-80v:hover {
  background-color: #0d351e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-5 {
  background-color: #f6efe9 !important;
}

.hover\:bg-orange-5:hover {
  background-color: #f6efe9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-10 {
  background-color: #f2e4d4 !important;
}

.hover\:bg-orange-10:hover {
  background-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-20 {
  background-color: #f3bf90 !important;
}

.hover\:bg-orange-20:hover {
  background-color: #f3bf90 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-30 {
  background-color: #f09860 !important;
}

.hover\:bg-orange-30:hover {
  background-color: #f09860 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-40 {
  background-color: #dd7533 !important;
}

.hover\:bg-orange-40:hover {
  background-color: #dd7533 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-50 {
  background-color: #a86437 !important;
}

.hover\:bg-orange-50:hover {
  background-color: #a86437 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-60 {
  background-color: #775540 !important;
}

.hover\:bg-orange-60:hover {
  background-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-70 {
  background-color: #524236 !important;
}

.hover\:bg-orange-70:hover {
  background-color: #524236 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-80 {
  background-color: #332d27 !important;
}

.hover\:bg-orange-80:hover {
  background-color: #332d27 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-90 {
  background-color: #1b1614 !important;
}

.hover\:bg-orange-90:hover {
  background-color: #1b1614 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-5v {
  background-color: #fef2e4 !important;
}

.hover\:bg-orange-5v:hover {
  background-color: #fef2e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-10v {
  background-color: #fce2c5 !important;
}

.hover\:bg-orange-10v:hover {
  background-color: #fce2c5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-20v {
  background-color: #ffbc78 !important;
}

.hover\:bg-orange-20v:hover {
  background-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-30v {
  background-color: #fa9441 !important;
}

.hover\:bg-orange-30v:hover {
  background-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-40v {
  background-color: #e66f0e !important;
}

.hover\:bg-orange-40v:hover {
  background-color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-50v {
  background-color: #c05600 !important;
}

.hover\:bg-orange-50v:hover {
  background-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-60v {
  background-color: #8c471c !important;
}

.hover\:bg-orange-60v:hover {
  background-color: #8c471c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-70v {
  background-color: #5f3617 !important;
}

.hover\:bg-orange-70v:hover {
  background-color: #5f3617 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-80v {
  background-color: #352313 !important;
}

.hover\:bg-orange-80v:hover {
  background-color: #352313 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-5 {
  background-color: #faeee5 !important;
}

.hover\:bg-orange-warm-5:hover {
  background-color: #faeee5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-10 {
  background-color: #fbe0d0 !important;
}

.hover\:bg-orange-warm-10:hover {
  background-color: #fbe0d0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-20 {
  background-color: #f7bca2 !important;
}

.hover\:bg-orange-warm-20:hover {
  background-color: #f7bca2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-30 {
  background-color: #f3966d !important;
}

.hover\:bg-orange-warm-30:hover {
  background-color: #f3966d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-40 {
  background-color: #e17141 !important;
}

.hover\:bg-orange-warm-40:hover {
  background-color: #e17141 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-50 {
  background-color: #bd5727 !important;
}

.hover\:bg-orange-warm-50:hover {
  background-color: #bd5727 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-60 {
  background-color: #914734 !important;
}

.hover\:bg-orange-warm-60:hover {
  background-color: #914734 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-70 {
  background-color: #633a32 !important;
}

.hover\:bg-orange-warm-70:hover {
  background-color: #633a32 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-80 {
  background-color: #3d2925 !important;
}

.hover\:bg-orange-warm-80:hover {
  background-color: #3d2925 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-90 {
  background-color: #1c1615 !important;
}

.hover\:bg-orange-warm-90:hover {
  background-color: #1c1615 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-5v {
  background-color: #fff3ea !important;
}

.hover\:bg-orange-warm-5v:hover {
  background-color: #fff3ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-10v {
  background-color: #ffe2d1 !important;
}

.hover\:bg-orange-warm-10v:hover {
  background-color: #ffe2d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-20v {
  background-color: #fbbaa7 !important;
}

.hover\:bg-orange-warm-20v:hover {
  background-color: #fbbaa7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-30v {
  background-color: #fc906d !important;
}

.hover\:bg-orange-warm-30v:hover {
  background-color: #fc906d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-40v {
  background-color: #ff580a !important;
}

.hover\:bg-orange-warm-40v:hover {
  background-color: #ff580a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-50v {
  background-color: #cf4900 !important;
}

.hover\:bg-orange-warm-50v:hover {
  background-color: #cf4900 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-60v {
  background-color: #a72f10 !important;
}

.hover\:bg-orange-warm-60v:hover {
  background-color: #a72f10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-70v {
  background-color: #782312 !important;
}

.hover\:bg-orange-warm-70v:hover {
  background-color: #782312 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange-warm-80v {
  background-color: #3d231d !important;
}

.hover\:bg-orange-warm-80v:hover {
  background-color: #3d231d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-5 {
  background-color: #f8eff1 !important;
}

.hover\:bg-red-cool-5:hover {
  background-color: #f8eff1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-10 {
  background-color: #f3e1e4 !important;
}

.hover\:bg-red-cool-10:hover {
  background-color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-20 {
  background-color: #ecbec6 !important;
}

.hover\:bg-red-cool-20:hover {
  background-color: #ecbec6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-30 {
  background-color: #e09aa6 !important;
}

.hover\:bg-red-cool-30:hover {
  background-color: #e09aa6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-40 {
  background-color: #e16b80 !important;
}

.hover\:bg-red-cool-40:hover {
  background-color: #e16b80 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-50 {
  background-color: #cd425b !important;
}

.hover\:bg-red-cool-50:hover {
  background-color: #cd425b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-60 {
  background-color: #9e394b !important;
}

.hover\:bg-red-cool-60:hover {
  background-color: #9e394b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-70 {
  background-color: #68363f !important;
}

.hover\:bg-red-cool-70:hover {
  background-color: #68363f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-80 {
  background-color: #40282c !important;
}

.hover\:bg-red-cool-80:hover {
  background-color: #40282c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-90 {
  background-color: #1e1517 !important;
}

.hover\:bg-red-cool-90:hover {
  background-color: #1e1517 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-5v {
  background-color: #fff2f5 !important;
}

.hover\:bg-red-cool-5v:hover {
  background-color: #fff2f5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-10v {
  background-color: #f8dfe2 !important;
}

.hover\:bg-red-cool-10v:hover {
  background-color: #f8dfe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-20v {
  background-color: #f8b9c5 !important;
}

.hover\:bg-red-cool-20v:hover {
  background-color: #f8b9c5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-30v {
  background-color: #fd8ba0 !important;
}

.hover\:bg-red-cool-30v:hover {
  background-color: #fd8ba0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-40v {
  background-color: #f45d79 !important;
}

.hover\:bg-red-cool-40v:hover {
  background-color: #f45d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-50v {
  background-color: #e41d3d !important;
}

.hover\:bg-red-cool-50v:hover {
  background-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-60v {
  background-color: #b21d38 !important;
}

.hover\:bg-red-cool-60v:hover {
  background-color: #b21d38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-70v {
  background-color: #822133 !important;
}

.hover\:bg-red-cool-70v:hover {
  background-color: #822133 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-cool-80v {
  background-color: #4f1c24 !important;
}

.hover\:bg-red-cool-80v:hover {
  background-color: #4f1c24 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-5 {
  background-color: #f9eeee !important;
}

.hover\:bg-red-5:hover {
  background-color: #f9eeee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-10 {
  background-color: #f8e1de !important;
}

.hover\:bg-red-10:hover {
  background-color: #f8e1de !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-20 {
  background-color: #f7bbb1 !important;
}

.hover\:bg-red-20:hover {
  background-color: #f7bbb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-30 {
  background-color: #f2938c !important;
}

.hover\:bg-red-30:hover {
  background-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-40 {
  background-color: #e9695f !important;
}

.hover\:bg-red-40:hover {
  background-color: #e9695f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-50 {
  background-color: #d83933 !important;
}

.hover\:bg-red-50:hover {
  background-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-60 {
  background-color: #a23737 !important;
}

.hover\:bg-red-60:hover {
  background-color: #a23737 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-70 {
  background-color: #6f3331 !important;
}

.hover\:bg-red-70:hover {
  background-color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-80 {
  background-color: #3e2927 !important;
}

.hover\:bg-red-80:hover {
  background-color: #3e2927 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-90 {
  background-color: #1b1616 !important;
}

.hover\:bg-red-90:hover {
  background-color: #1b1616 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-5v {
  background-color: #fff3f2 !important;
}

.hover\:bg-red-5v:hover {
  background-color: #fff3f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-10v {
  background-color: #fde0db !important;
}

.hover\:bg-red-10v:hover {
  background-color: #fde0db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-20v {
  background-color: #fdb8ae !important;
}

.hover\:bg-red-20v:hover {
  background-color: #fdb8ae !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-30v {
  background-color: #ff8d7b !important;
}

.hover\:bg-red-30v:hover {
  background-color: #ff8d7b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-40v {
  background-color: #fb5a47 !important;
}

.hover\:bg-red-40v:hover {
  background-color: #fb5a47 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-50v {
  background-color: #e52207 !important;
}

.hover\:bg-red-50v:hover {
  background-color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-60v {
  background-color: #b50909 !important;
}

.hover\:bg-red-60v:hover {
  background-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-70v {
  background-color: #8b0a03 !important;
}

.hover\:bg-red-70v:hover {
  background-color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-80v {
  background-color: #5c1111 !important;
}

.hover\:bg-red-80v:hover {
  background-color: #5c1111 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-5 {
  background-color: #f6efea !important;
}

.hover\:bg-red-warm-5:hover {
  background-color: #f6efea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-10 {
  background-color: #f4e3db !important;
}

.hover\:bg-red-warm-10:hover {
  background-color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-20 {
  background-color: #ecc0a7 !important;
}

.hover\:bg-red-warm-20:hover {
  background-color: #ecc0a7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-30 {
  background-color: #dca081 !important;
}

.hover\:bg-red-warm-30:hover {
  background-color: #dca081 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-40 {
  background-color: #d27a56 !important;
}

.hover\:bg-red-warm-40:hover {
  background-color: #d27a56 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-50 {
  background-color: #c3512c !important;
}

.hover\:bg-red-warm-50:hover {
  background-color: #c3512c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-60 {
  background-color: #805039 !important;
}

.hover\:bg-red-warm-60:hover {
  background-color: #805039 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-70 {
  background-color: #524236 !important;
}

.hover\:bg-red-warm-70:hover {
  background-color: #524236 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-80 {
  background-color: #332d29 !important;
}

.hover\:bg-red-warm-80:hover {
  background-color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-90 {
  background-color: #1f1c18 !important;
}

.hover\:bg-red-warm-90:hover {
  background-color: #1f1c18 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-5v {
  background-color: #fff5ee !important;
}

.hover\:bg-red-warm-5v:hover {
  background-color: #fff5ee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-10v {
  background-color: #fce1d4 !important;
}

.hover\:bg-red-warm-10v:hover {
  background-color: #fce1d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-20v {
  background-color: #f6bd9c !important;
}

.hover\:bg-red-warm-20v:hover {
  background-color: #f6bd9c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-30v {
  background-color: #f39268 !important;
}

.hover\:bg-red-warm-30v:hover {
  background-color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-40v {
  background-color: #ef5e25 !important;
}

.hover\:bg-red-warm-40v:hover {
  background-color: #ef5e25 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-50v {
  background-color: #d54309 !important;
}

.hover\:bg-red-warm-50v:hover {
  background-color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-60v {
  background-color: #9c3d10 !important;
}

.hover\:bg-red-warm-60v:hover {
  background-color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-70v {
  background-color: #63340f !important;
}

.hover\:bg-red-warm-70v:hover {
  background-color: #63340f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red-warm-80v {
  background-color: #3e2a1e !important;
}

.hover\:bg-red-warm-80v:hover {
  background-color: #3e2a1e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-5 {
  background-color: #f4f1f9 !important;
}

.hover\:bg-violet-5:hover {
  background-color: #f4f1f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-10 {
  background-color: #ebe3f9 !important;
}

.hover\:bg-violet-10:hover {
  background-color: #ebe3f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-20 {
  background-color: #d0c3e9 !important;
}

.hover\:bg-violet-20:hover {
  background-color: #d0c3e9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-30 {
  background-color: #b8a2e3 !important;
}

.hover\:bg-violet-30:hover {
  background-color: #b8a2e3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-40 {
  background-color: #9d84d2 !important;
}

.hover\:bg-violet-40:hover {
  background-color: #9d84d2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-50 {
  background-color: #8168b3 !important;
}

.hover\:bg-violet-50:hover {
  background-color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-60 {
  background-color: #665190 !important;
}

.hover\:bg-violet-60:hover {
  background-color: #665190 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-70 {
  background-color: #4c3d69 !important;
}

.hover\:bg-violet-70:hover {
  background-color: #4c3d69 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-80 {
  background-color: #312b3f !important;
}

.hover\:bg-violet-80:hover {
  background-color: #312b3f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-90 {
  background-color: #18161d !important;
}

.hover\:bg-violet-90:hover {
  background-color: #18161d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-5v {
  background-color: #f7f2ff !important;
}

.hover\:bg-violet-5v:hover {
  background-color: #f7f2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-10v {
  background-color: #ede3ff !important;
}

.hover\:bg-violet-10v:hover {
  background-color: #ede3ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-20v {
  background-color: #d5bfff !important;
}

.hover\:bg-violet-20v:hover {
  background-color: #d5bfff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-30v {
  background-color: #c39deb !important;
}

.hover\:bg-violet-30v:hover {
  background-color: #c39deb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-40v {
  background-color: #ad79e9 !important;
}

.hover\:bg-violet-40v:hover {
  background-color: #ad79e9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-50v {
  background-color: #9355dc !important;
}

.hover\:bg-violet-50v:hover {
  background-color: #9355dc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-60v {
  background-color: #783cb9 !important;
}

.hover\:bg-violet-60v:hover {
  background-color: #783cb9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-70v {
  background-color: #54278f !important;
}

.hover\:bg-violet-70v:hover {
  background-color: #54278f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-80v {
  background-color: #39215e !important;
}

.hover\:bg-violet-80v:hover {
  background-color: #39215e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-5 {
  background-color: #f8f0f9 !important;
}

.hover\:bg-violet-warm-5:hover {
  background-color: #f8f0f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-10 {
  background-color: #f6dff8 !important;
}

.hover\:bg-violet-warm-10:hover {
  background-color: #f6dff8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-20 {
  background-color: #e2bee4 !important;
}

.hover\:bg-violet-warm-20:hover {
  background-color: #e2bee4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-30 {
  background-color: #d29ad8 !important;
}

.hover\:bg-violet-warm-30:hover {
  background-color: #d29ad8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-40 {
  background-color: #bf77c8 !important;
}

.hover\:bg-violet-warm-40:hover {
  background-color: #bf77c8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-50 {
  background-color: #b04abd !important;
}

.hover\:bg-violet-warm-50:hover {
  background-color: #b04abd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-60 {
  background-color: #864381 !important;
}

.hover\:bg-violet-warm-60:hover {
  background-color: #864381 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-70 {
  background-color: #5c395a !important;
}

.hover\:bg-violet-warm-70:hover {
  background-color: #5c395a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-80 {
  background-color: #382936 !important;
}

.hover\:bg-violet-warm-80:hover {
  background-color: #382936 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-90 {
  background-color: #1b151b !important;
}

.hover\:bg-violet-warm-90:hover {
  background-color: #1b151b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-5v {
  background-color: #fef2ff !important;
}

.hover\:bg-violet-warm-5v:hover {
  background-color: #fef2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-10v {
  background-color: #fbdcff !important;
}

.hover\:bg-violet-warm-10v:hover {
  background-color: #fbdcff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-20v {
  background-color: #f4b2ff !important;
}

.hover\:bg-violet-warm-20v:hover {
  background-color: #f4b2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-30v {
  background-color: #ee83ff !important;
}

.hover\:bg-violet-warm-30v:hover {
  background-color: #ee83ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-40v {
  background-color: #d85bef !important;
}

.hover\:bg-violet-warm-40v:hover {
  background-color: #d85bef !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-50v {
  background-color: #be32d0 !important;
}

.hover\:bg-violet-warm-50v:hover {
  background-color: #be32d0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-60v {
  background-color: #93348c !important;
}

.hover\:bg-violet-warm-60v:hover {
  background-color: #93348c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-70v {
  background-color: #711e6c !important;
}

.hover\:bg-violet-warm-70v:hover {
  background-color: #711e6c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet-warm-80v {
  background-color: #481441 !important;
}

.hover\:bg-violet-warm-80v:hover {
  background-color: #481441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-5 {
  background-color: rgba(255, 255, 255, 0.01) !important;
}

.hover\:bg-white-transparent-5:hover {
  background-color: rgba(255, 255, 255, 0.01) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-10 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.hover\:bg-white-transparent-10:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-20 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.hover\:bg-white-transparent-20:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-30 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.hover\:bg-white-transparent-30:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-40 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.hover\:bg-white-transparent-40:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.hover\:bg-white-transparent-50:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-60 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.hover\:bg-white-transparent-60:hover {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-70 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.hover\:bg-white-transparent-70:hover {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-80 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.hover\:bg-white-transparent-80:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white-transparent-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.hover\:bg-white-transparent-90:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-5 {
  background-color: #faf3d1 !important;
}

.hover\:bg-yellow-5:hover {
  background-color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-10 {
  background-color: #f5e6af !important;
}

.hover\:bg-yellow-10:hover {
  background-color: #f5e6af !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-20 {
  background-color: #e6c74c !important;
}

.hover\:bg-yellow-20:hover {
  background-color: #e6c74c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-30 {
  background-color: #c9ab48 !important;
}

.hover\:bg-yellow-30:hover {
  background-color: #c9ab48 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-40 {
  background-color: #a88f48 !important;
}

.hover\:bg-yellow-40:hover {
  background-color: #a88f48 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-50 {
  background-color: #8a7237 !important;
}

.hover\:bg-yellow-50:hover {
  background-color: #8a7237 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-60 {
  background-color: #6b5a39 !important;
}

.hover\:bg-yellow-60:hover {
  background-color: #6b5a39 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-70 {
  background-color: #504332 !important;
}

.hover\:bg-yellow-70:hover {
  background-color: #504332 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-80 {
  background-color: #332d27 !important;
}

.hover\:bg-yellow-80:hover {
  background-color: #332d27 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-90 {
  background-color: #1a1614 !important;
}

.hover\:bg-yellow-90:hover {
  background-color: #1a1614 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-5v {
  background-color: #fff5c2 !important;
}

.hover\:bg-yellow-5v:hover {
  background-color: #fff5c2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-10v {
  background-color: #fee685 !important;
}

.hover\:bg-yellow-10v:hover {
  background-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-20v {
  background-color: #face00 !important;
}

.hover\:bg-yellow-20v:hover {
  background-color: #face00 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-30v {
  background-color: #ddaa01 !important;
}

.hover\:bg-yellow-30v:hover {
  background-color: #ddaa01 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-40v {
  background-color: #b38c00 !important;
}

.hover\:bg-yellow-40v:hover {
  background-color: #b38c00 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-50v {
  background-color: #947100 !important;
}

.hover\:bg-yellow-50v:hover {
  background-color: #947100 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-60v {
  background-color: #776017 !important;
}

.hover\:bg-yellow-60v:hover {
  background-color: #776017 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-70v {
  background-color: #5c4809 !important;
}

.hover\:bg-yellow-70v:hover {
  background-color: #5c4809 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow-80v {
  background-color: #422d19 !important;
}

.hover\:bg-yellow-80v:hover {
  background-color: #422d19 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-error-lighter {
  background-color: #f4e3db !important;
}

.hover\:bg-error-lighter:hover {
  background-color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-error-light {
  background-color: #f39268 !important;
}

.hover\:bg-error-light:hover {
  background-color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-error {
  background-color: #d54309 !important;
}

.hover\:bg-error:hover {
  background-color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-error-dark {
  background-color: #b50909 !important;
}

.hover\:bg-error-dark:hover {
  background-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-error-darker {
  background-color: #6f3331 !important;
}

.hover\:bg-error-darker:hover {
  background-color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-warning-lighter {
  background-color: #faf3d1 !important;
}

.hover\:bg-warning-lighter:hover {
  background-color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-warning-light {
  background-color: #fee685 !important;
}

.hover\:bg-warning-light:hover {
  background-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-warning {
  background-color: #ffbe2e !important;
}

.hover\:bg-warning:hover {
  background-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-warning-dark {
  background-color: #e5a000 !important;
}

.hover\:bg-warning-dark:hover {
  background-color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-warning-darker {
  background-color: #936f38 !important;
}

.hover\:bg-warning-darker:hover {
  background-color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-success-lighter {
  background-color: #ecf3ec !important;
}

.hover\:bg-success-lighter:hover {
  background-color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-success-light {
  background-color: #70e17b !important;
}

.hover\:bg-success-light:hover {
  background-color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-success {
  background-color: #00a91c !important;
}

.hover\:bg-success:hover {
  background-color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-success-dark {
  background-color: #4d8055 !important;
}

.hover\:bg-success-dark:hover {
  background-color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-success-darker {
  background-color: #446443 !important;
}

.hover\:bg-success-darker:hover {
  background-color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-info-lighter {
  background-color: #e7f6f8 !important;
}

.hover\:bg-info-lighter:hover {
  background-color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-info-light {
  background-color: #99deea !important;
}

.hover\:bg-info-light:hover {
  background-color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-info {
  background-color: #00bde3 !important;
}

.hover\:bg-info:hover {
  background-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-info-dark {
  background-color: #009ec1 !important;
}

.hover\:bg-info-dark:hover {
  background-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-info-darker {
  background-color: #2e6276 !important;
}

.hover\:bg-info-darker:hover {
  background-color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-disabled-light {
  background-color: #e6e6e6 !important;
}

.hover\:bg-disabled-light:hover {
  background-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-disabled {
  background-color: #c9c9c9 !important;
}

.hover\:bg-disabled:hover {
  background-color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-disabled-dark {
  background-color: #adadad !important;
}

.hover\:bg-disabled-dark:hover {
  background-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-emergency {
  background-color: #9c3d10 !important;
}

.hover\:bg-emergency:hover {
  background-color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-emergency-dark {
  background-color: #332d29 !important;
}

.hover\:bg-emergency-dark:hover {
  background-color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-transparent {
  background-color: transparent !important;
}

.hover\:bg-transparent:hover {
  background-color: transparent !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-black {
  background-color: black !important;
}

.hover\:bg-black:hover {
  background-color: black !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-white {
  background-color: white !important;
}

.hover\:bg-white:hover {
  background-color: white !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-red {
  background-color: #e52207 !important;
}

.hover\:bg-red:hover {
  background-color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-orange {
  background-color: #e66f0e !important;
}

.hover\:bg-orange:hover {
  background-color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-gold {
  background-color: #ffbe2e !important;
}

.hover\:bg-gold:hover {
  background-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-yellow {
  background-color: #fee685 !important;
}

.hover\:bg-yellow:hover {
  background-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-green {
  background-color: #538200 !important;
}

.hover\:bg-green:hover {
  background-color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-mint {
  background-color: #04c585 !important;
}

.hover\:bg-mint:hover {
  background-color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-cyan {
  background-color: #009ec1 !important;
}

.hover\:bg-cyan:hover {
  background-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-blue {
  background-color: #0076d6 !important;
}

.hover\:bg-blue:hover {
  background-color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-indigo {
  background-color: #676cc8 !important;
}

.hover\:bg-indigo:hover {
  background-color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-violet {
  background-color: #8168b3 !important;
}

.hover\:bg-violet:hover {
  background-color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-magenta {
  background-color: #d72d79 !important;
}

.hover\:bg-magenta:hover {
  background-color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base-lightest {
  background-color: #f0f0f0 !important;
}

.hover\:bg-base-lightest:hover {
  background-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base-lighter {
  background-color: #e6e6e6 !important;
}

.hover\:bg-base-lighter:hover {
  background-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base-light {
  background-color: #adadad !important;
}

.hover\:bg-base-light:hover {
  background-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base {
  background-color: #757575 !important;
}

.hover\:bg-base:hover {
  background-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base-dark {
  background-color: #5c5c5c !important;
}

.hover\:bg-base-dark:hover {
  background-color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base-darker {
  background-color: #2e2e2e !important;
}

.hover\:bg-base-darker:hover {
  background-color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-base-darkest {
  background-color: #1b1b1b !important;
}

.hover\:bg-base-darkest:hover {
  background-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-ink {
  background-color: #1b1b1b !important;
}

.hover\:bg-ink:hover {
  background-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-primary-lighter {
  background-color: #d9e8f6 !important;
}

.hover\:bg-primary-lighter:hover {
  background-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-primary-light {
  background-color: #73b3e7 !important;
}

.hover\:bg-primary-light:hover {
  background-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-primary {
  background-color: #005ea2 !important;
}

.hover\:bg-primary:hover {
  background-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-primary-vivid {
  background-color: #0050d8 !important;
}

.hover\:bg-primary-vivid:hover {
  background-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-primary-dark {
  background-color: #1a4480 !important;
}

.hover\:bg-primary-dark:hover {
  background-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-primary-darker {
  background-color: #162e51 !important;
}

.hover\:bg-primary-darker:hover {
  background-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-secondary-lighter {
  background-color: #f3e1e4 !important;
}

.hover\:bg-secondary-lighter:hover {
  background-color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-secondary-light {
  background-color: #f2938c !important;
}

.hover\:bg-secondary-light:hover {
  background-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-secondary {
  background-color: #d83933 !important;
}

.hover\:bg-secondary:hover {
  background-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-secondary-vivid {
  background-color: #e41d3d !important;
}

.hover\:bg-secondary-vivid:hover {
  background-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-secondary-dark {
  background-color: #b50909 !important;
}

.hover\:bg-secondary-dark:hover {
  background-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-secondary-darker {
  background-color: #8b0a03 !important;
}

.hover\:bg-secondary-darker:hover {
  background-color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-warm-darker {
  background-color: #775540 !important;
}

.hover\:bg-accent-warm-darker:hover {
  background-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-warm-dark {
  background-color: #c05600 !important;
}

.hover\:bg-accent-warm-dark:hover {
  background-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-warm {
  background-color: #fa9441 !important;
}

.hover\:bg-accent-warm:hover {
  background-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-warm-light {
  background-color: #ffbc78 !important;
}

.hover\:bg-accent-warm-light:hover {
  background-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-warm-lighter {
  background-color: #f2e4d4 !important;
}

.hover\:bg-accent-warm-lighter:hover {
  background-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-cool-darker {
  background-color: #07648d !important;
}

.hover\:bg-accent-cool-darker:hover {
  background-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-cool-dark {
  background-color: #28a0cb !important;
}

.hover\:bg-accent-cool-dark:hover {
  background-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-cool {
  background-color: #00bde3 !important;
}

.hover\:bg-accent-cool:hover {
  background-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-cool-light {
  background-color: #97d4ea !important;
}

.hover\:bg-accent-cool-light:hover {
  background-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-accent-cool-lighter {
  background-color: #e1f3f8 !important;
}

.hover\:bg-accent-cool-lighter:hover {
  background-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base-lightest {
  background-color: #f0f0f0 !important;
}

.hover\:bg-default-base-lightest:hover {
  background-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base-lighter {
  background-color: #dcdee0 !important;
}

.hover\:bg-default-base-lighter:hover {
  background-color: #dcdee0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base-light {
  background-color: #a9aeb1 !important;
}

.hover\:bg-default-base-light:hover {
  background-color: #a9aeb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base {
  background-color: #71767a !important;
}

.hover\:bg-default-base:hover {
  background-color: #71767a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base-dark {
  background-color: #565c65 !important;
}

.hover\:bg-default-base-dark:hover {
  background-color: #565c65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base-darker {
  background-color: #3d4551 !important;
}

.hover\:bg-default-base-darker:hover {
  background-color: #3d4551 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-base-darkest {
  background-color: #1b1b1b !important;
}

.hover\:bg-default-base-darkest:hover {
  background-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-ink {
  background-color: #1b1b1b !important;
}

.hover\:bg-default-ink:hover {
  background-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-primary-lighter {
  background-color: #d9e8f6 !important;
}

.hover\:bg-default-primary-lighter:hover {
  background-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-primary-light {
  background-color: #73b3e7 !important;
}

.hover\:bg-default-primary-light:hover {
  background-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-primary {
  background-color: #005ea2 !important;
}

.hover\:bg-default-primary:hover {
  background-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-primary-vivid {
  background-color: #0050d8 !important;
}

.hover\:bg-default-primary-vivid:hover {
  background-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-primary-dark {
  background-color: #1a4480 !important;
}

.hover\:bg-default-primary-dark:hover {
  background-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-primary-darker {
  background-color: #162e51 !important;
}

.hover\:bg-default-primary-darker:hover {
  background-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-secondary-lighter {
  background-color: #f8dfe2 !important;
}

.hover\:bg-default-secondary-lighter:hover {
  background-color: #f8dfe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-secondary-light {
  background-color: #f2938c !important;
}

.hover\:bg-default-secondary-light:hover {
  background-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-secondary {
  background-color: #d83933 !important;
}

.hover\:bg-default-secondary:hover {
  background-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-secondary-vivid {
  background-color: #e41d3d !important;
}

.hover\:bg-default-secondary-vivid:hover {
  background-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-secondary-dark {
  background-color: #b51d09 !important;
}

.hover\:bg-default-secondary-dark:hover {
  background-color: #b51d09 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-secondary-darker {
  background-color: #8b1303 !important;
}

.hover\:bg-default-secondary-darker:hover {
  background-color: #8b1303 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-warm-lighter {
  background-color: #f2e4d4 !important;
}

.hover\:bg-default-accent-warm-lighter:hover {
  background-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-warm-light {
  background-color: #ffbc78 !important;
}

.hover\:bg-default-accent-warm-light:hover {
  background-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-warm {
  background-color: #fa9441 !important;
}

.hover\:bg-default-accent-warm:hover {
  background-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-warm-dark {
  background-color: #c05600 !important;
}

.hover\:bg-default-accent-warm-dark:hover {
  background-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-warm-darker {
  background-color: #775540 !important;
}

.hover\:bg-default-accent-warm-darker:hover {
  background-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-cool-lighter {
  background-color: #e1f3f8 !important;
}

.hover\:bg-default-accent-cool-lighter:hover {
  background-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-cool-light {
  background-color: #97d4ea !important;
}

.hover\:bg-default-accent-cool-light:hover {
  background-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-cool {
  background-color: #00bde3 !important;
}

.hover\:bg-default-accent-cool:hover {
  background-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-cool-dark {
  background-color: #28a0cb !important;
}

.hover\:bg-default-accent-cool-dark:hover {
  background-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bg-default-accent-cool-darker {
  background-color: #07648d !important;
}

.hover\:bg-default-accent-cool-darker:hover {
  background-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-1px {
  border: 1px solid !important;
}

.hover\:border-1px:hover {
  border: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-1px {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

.hover\:border-y-1px:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-1px {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

.hover\:border-x-1px:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-1px {
  border-top: 1px solid !important;
}

.hover\:border-top-1px:hover {
  border-top: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-1px {
  border-right: 1px solid !important;
}

.hover\:border-right-1px:hover {
  border-right: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-1px {
  border-bottom: 1px solid !important;
}

.hover\:border-bottom-1px:hover {
  border-bottom: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-1px {
  border-left: 1px solid !important;
}

.hover\:border-left-1px:hover {
  border-left: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-2px {
  border: 2px solid !important;
}

.hover\:border-2px:hover {
  border: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-2px {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}

.hover\:border-y-2px:hover {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-2px {
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}

.hover\:border-x-2px:hover {
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-2px {
  border-top: 2px solid !important;
}

.hover\:border-top-2px:hover {
  border-top: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-2px {
  border-right: 2px solid !important;
}

.hover\:border-right-2px:hover {
  border-right: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-2px {
  border-bottom: 2px solid !important;
}

.hover\:border-bottom-2px:hover {
  border-bottom: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-2px {
  border-left: 2px solid !important;
}

.hover\:border-left-2px:hover {
  border-left: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-05 {
  border: 0.25rem solid !important;
}

.hover\:border-05:hover {
  border: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-05 {
  border-top: 0.25rem solid !important;
  border-bottom: 0.25rem solid !important;
}

.hover\:border-y-05:hover {
  border-top: 0.25rem solid !important;
  border-bottom: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-05 {
  border-left: 0.25rem solid !important;
  border-right: 0.25rem solid !important;
}

.hover\:border-x-05:hover {
  border-left: 0.25rem solid !important;
  border-right: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-05 {
  border-top: 0.25rem solid !important;
}

.hover\:border-top-05:hover {
  border-top: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-05 {
  border-right: 0.25rem solid !important;
}

.hover\:border-right-05:hover {
  border-right: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-05 {
  border-bottom: 0.25rem solid !important;
}

.hover\:border-bottom-05:hover {
  border-bottom: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-05 {
  border-left: 0.25rem solid !important;
}

.hover\:border-left-05:hover {
  border-left: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-1 {
  border: 0.5rem solid !important;
}

.hover\:border-1:hover {
  border: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-1 {
  border-top: 0.5rem solid !important;
  border-bottom: 0.5rem solid !important;
}

.hover\:border-y-1:hover {
  border-top: 0.5rem solid !important;
  border-bottom: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-1 {
  border-left: 0.5rem solid !important;
  border-right: 0.5rem solid !important;
}

.hover\:border-x-1:hover {
  border-left: 0.5rem solid !important;
  border-right: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-1 {
  border-top: 0.5rem solid !important;
}

.hover\:border-top-1:hover {
  border-top: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-1 {
  border-right: 0.5rem solid !important;
}

.hover\:border-right-1:hover {
  border-right: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-1 {
  border-bottom: 0.5rem solid !important;
}

.hover\:border-bottom-1:hover {
  border-bottom: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-1 {
  border-left: 0.5rem solid !important;
}

.hover\:border-left-1:hover {
  border-left: 0.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-105 {
  border: 0.75rem solid !important;
}

.hover\:border-105:hover {
  border: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-105 {
  border-top: 0.75rem solid !important;
  border-bottom: 0.75rem solid !important;
}

.hover\:border-y-105:hover {
  border-top: 0.75rem solid !important;
  border-bottom: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-105 {
  border-left: 0.75rem solid !important;
  border-right: 0.75rem solid !important;
}

.hover\:border-x-105:hover {
  border-left: 0.75rem solid !important;
  border-right: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-105 {
  border-top: 0.75rem solid !important;
}

.hover\:border-top-105:hover {
  border-top: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-105 {
  border-right: 0.75rem solid !important;
}

.hover\:border-right-105:hover {
  border-right: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-105 {
  border-bottom: 0.75rem solid !important;
}

.hover\:border-bottom-105:hover {
  border-bottom: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-105 {
  border-left: 0.75rem solid !important;
}

.hover\:border-left-105:hover {
  border-left: 0.75rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-2 {
  border: 1rem solid !important;
}

.hover\:border-2:hover {
  border: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-2 {
  border-top: 1rem solid !important;
  border-bottom: 1rem solid !important;
}

.hover\:border-y-2:hover {
  border-top: 1rem solid !important;
  border-bottom: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-2 {
  border-left: 1rem solid !important;
  border-right: 1rem solid !important;
}

.hover\:border-x-2:hover {
  border-left: 1rem solid !important;
  border-right: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-2 {
  border-top: 1rem solid !important;
}

.hover\:border-top-2:hover {
  border-top: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-2 {
  border-right: 1rem solid !important;
}

.hover\:border-right-2:hover {
  border-right: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-2 {
  border-bottom: 1rem solid !important;
}

.hover\:border-bottom-2:hover {
  border-bottom: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-2 {
  border-left: 1rem solid !important;
}

.hover\:border-left-2:hover {
  border-left: 1rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-205 {
  border: 1.25rem solid !important;
}

.hover\:border-205:hover {
  border: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-205 {
  border-top: 1.25rem solid !important;
  border-bottom: 1.25rem solid !important;
}

.hover\:border-y-205:hover {
  border-top: 1.25rem solid !important;
  border-bottom: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-205 {
  border-left: 1.25rem solid !important;
  border-right: 1.25rem solid !important;
}

.hover\:border-x-205:hover {
  border-left: 1.25rem solid !important;
  border-right: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-205 {
  border-top: 1.25rem solid !important;
}

.hover\:border-top-205:hover {
  border-top: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-205 {
  border-right: 1.25rem solid !important;
}

.hover\:border-right-205:hover {
  border-right: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-205 {
  border-bottom: 1.25rem solid !important;
}

.hover\:border-bottom-205:hover {
  border-bottom: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-205 {
  border-left: 1.25rem solid !important;
}

.hover\:border-left-205:hover {
  border-left: 1.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-3 {
  border: 1.5rem solid !important;
}

.hover\:border-3:hover {
  border: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-3 {
  border-top: 1.5rem solid !important;
  border-bottom: 1.5rem solid !important;
}

.hover\:border-y-3:hover {
  border-top: 1.5rem solid !important;
  border-bottom: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-3 {
  border-left: 1.5rem solid !important;
  border-right: 1.5rem solid !important;
}

.hover\:border-x-3:hover {
  border-left: 1.5rem solid !important;
  border-right: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-3 {
  border-top: 1.5rem solid !important;
}

.hover\:border-top-3:hover {
  border-top: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-3 {
  border-right: 1.5rem solid !important;
}

.hover\:border-right-3:hover {
  border-right: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-3 {
  border-bottom: 1.5rem solid !important;
}

.hover\:border-bottom-3:hover {
  border-bottom: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-3 {
  border-left: 1.5rem solid !important;
}

.hover\:border-left-3:hover {
  border-left: 1.5rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-0 {
  border: 0 solid !important;
}

.hover\:border-0:hover {
  border: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-0 {
  border-top: 0 solid !important;
  border-bottom: 0 solid !important;
}

.hover\:border-y-0:hover {
  border-top: 0 solid !important;
  border-bottom: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-0 {
  border-left: 0 solid !important;
  border-right: 0 solid !important;
}

.hover\:border-x-0:hover {
  border-left: 0 solid !important;
  border-right: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-0 {
  border-top: 0 solid !important;
}

.hover\:border-top-0:hover {
  border-top: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-0 {
  border-right: 0 solid !important;
}

.hover\:border-right-0:hover {
  border-right: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-0 {
  border-bottom: 0 solid !important;
}

.hover\:border-bottom-0:hover {
  border-bottom: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-0 {
  border-left: 0 solid !important;
}

.hover\:border-left-0:hover {
  border-left: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border {
  border: 1px solid !important;
}

.hover\:border:hover {
  border: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

.hover\:border-y:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

.hover\:border-x:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top {
  border-top: 1px solid !important;
}

.hover\:border-top:hover {
  border-top: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right {
  border-right: 1px solid !important;
}

.hover\:border-right:hover {
  border-right: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom {
  border-bottom: 1px solid !important;
}

.hover\:border-bottom:hover {
  border-bottom: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left {
  border-left: 1px solid !important;
}

.hover\:border-left:hover {
  border-left: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-transparent {
  border-color: transparent !important;
}

.hover\:border-transparent:hover {
  border-color: transparent !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-black {
  border-color: black !important;
}

.hover\:border-black:hover {
  border-color: black !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-white {
  border-color: white !important;
}

.hover\:border-white:hover {
  border-color: white !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-red {
  border-color: #e52207 !important;
}

.hover\:border-red:hover {
  border-color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-orange {
  border-color: #e66f0e !important;
}

.hover\:border-orange:hover {
  border-color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gold {
  border-color: #ffbe2e !important;
}

.hover\:border-gold:hover {
  border-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-yellow {
  border-color: #fee685 !important;
}

.hover\:border-yellow:hover {
  border-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-green {
  border-color: #538200 !important;
}

.hover\:border-green:hover {
  border-color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-mint {
  border-color: #04c585 !important;
}

.hover\:border-mint:hover {
  border-color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-cyan {
  border-color: #009ec1 !important;
}

.hover\:border-cyan:hover {
  border-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-blue {
  border-color: #0076d6 !important;
}

.hover\:border-blue:hover {
  border-color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-indigo {
  border-color: #676cc8 !important;
}

.hover\:border-indigo:hover {
  border-color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-violet {
  border-color: #8168b3 !important;
}

.hover\:border-violet:hover {
  border-color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-magenta {
  border-color: #d72d79 !important;
}

.hover\:border-magenta:hover {
  border-color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gray-5 {
  border-color: #f0f0f0 !important;
}

.hover\:border-gray-5:hover {
  border-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gray-10 {
  border-color: #e6e6e6 !important;
}

.hover\:border-gray-10:hover {
  border-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gray-30 {
  border-color: #adadad !important;
}

.hover\:border-gray-30:hover {
  border-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gray-50 {
  border-color: #757575 !important;
}

.hover\:border-gray-50:hover {
  border-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gray-70 {
  border-color: #454545 !important;
}

.hover\:border-gray-70:hover {
  border-color: #454545 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-gray-90 {
  border-color: #1b1b1b !important;
}

.hover\:border-gray-90:hover {
  border-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base-lightest {
  border-color: #f0f0f0 !important;
}

.hover\:border-base-lightest:hover {
  border-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base-lighter {
  border-color: #e6e6e6 !important;
}

.hover\:border-base-lighter:hover {
  border-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base-light {
  border-color: #adadad !important;
}

.hover\:border-base-light:hover {
  border-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base {
  border-color: #757575 !important;
}

.hover\:border-base:hover {
  border-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base-dark {
  border-color: #5c5c5c !important;
}

.hover\:border-base-dark:hover {
  border-color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base-darker {
  border-color: #2e2e2e !important;
}

.hover\:border-base-darker:hover {
  border-color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-base-darkest {
  border-color: #1b1b1b !important;
}

.hover\:border-base-darkest:hover {
  border-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-ink {
  border-color: #1b1b1b !important;
}

.hover\:border-ink:hover {
  border-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-primary-lighter {
  border-color: #d9e8f6 !important;
}

.hover\:border-primary-lighter:hover {
  border-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-primary-light {
  border-color: #73b3e7 !important;
}

.hover\:border-primary-light:hover {
  border-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-primary {
  border-color: #005ea2 !important;
}

.hover\:border-primary:hover {
  border-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-primary-vivid {
  border-color: #0050d8 !important;
}

.hover\:border-primary-vivid:hover {
  border-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-primary-dark {
  border-color: #1a4480 !important;
}

.hover\:border-primary-dark:hover {
  border-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-primary-darker {
  border-color: #162e51 !important;
}

.hover\:border-primary-darker:hover {
  border-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-secondary-lighter {
  border-color: #f3e1e4 !important;
}

.hover\:border-secondary-lighter:hover {
  border-color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-secondary-light {
  border-color: #f2938c !important;
}

.hover\:border-secondary-light:hover {
  border-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-secondary {
  border-color: #d83933 !important;
}

.hover\:border-secondary:hover {
  border-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-secondary-vivid {
  border-color: #e41d3d !important;
}

.hover\:border-secondary-vivid:hover {
  border-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-secondary-dark {
  border-color: #b50909 !important;
}

.hover\:border-secondary-dark:hover {
  border-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-secondary-darker {
  border-color: #8b0a03 !important;
}

.hover\:border-secondary-darker:hover {
  border-color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-warm-darker {
  border-color: #775540 !important;
}

.hover\:border-accent-warm-darker:hover {
  border-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-warm-dark {
  border-color: #c05600 !important;
}

.hover\:border-accent-warm-dark:hover {
  border-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-warm {
  border-color: #fa9441 !important;
}

.hover\:border-accent-warm:hover {
  border-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-warm-light {
  border-color: #ffbc78 !important;
}

.hover\:border-accent-warm-light:hover {
  border-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-warm-lighter {
  border-color: #f2e4d4 !important;
}

.hover\:border-accent-warm-lighter:hover {
  border-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-cool-darker {
  border-color: #07648d !important;
}

.hover\:border-accent-cool-darker:hover {
  border-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-cool-dark {
  border-color: #28a0cb !important;
}

.hover\:border-accent-cool-dark:hover {
  border-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-cool {
  border-color: #00bde3 !important;
}

.hover\:border-accent-cool:hover {
  border-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-cool-light {
  border-color: #97d4ea !important;
}

.hover\:border-accent-cool-light:hover {
  border-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-accent-cool-lighter {
  border-color: #e1f3f8 !important;
}

.hover\:border-accent-cool-lighter:hover {
  border-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-error-lighter {
  border-color: #f4e3db !important;
}

.hover\:border-error-lighter:hover {
  border-color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-error-light {
  border-color: #f39268 !important;
}

.hover\:border-error-light:hover {
  border-color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-error {
  border-color: #d54309 !important;
}

.hover\:border-error:hover {
  border-color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-error-dark {
  border-color: #b50909 !important;
}

.hover\:border-error-dark:hover {
  border-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-error-darker {
  border-color: #6f3331 !important;
}

.hover\:border-error-darker:hover {
  border-color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-warning-lighter {
  border-color: #faf3d1 !important;
}

.hover\:border-warning-lighter:hover {
  border-color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-warning-light {
  border-color: #fee685 !important;
}

.hover\:border-warning-light:hover {
  border-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-warning {
  border-color: #ffbe2e !important;
}

.hover\:border-warning:hover {
  border-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-warning-dark {
  border-color: #e5a000 !important;
}

.hover\:border-warning-dark:hover {
  border-color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-warning-darker {
  border-color: #936f38 !important;
}

.hover\:border-warning-darker:hover {
  border-color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-success-lighter {
  border-color: #ecf3ec !important;
}

.hover\:border-success-lighter:hover {
  border-color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-success-light {
  border-color: #70e17b !important;
}

.hover\:border-success-light:hover {
  border-color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-success {
  border-color: #00a91c !important;
}

.hover\:border-success:hover {
  border-color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-success-dark {
  border-color: #4d8055 !important;
}

.hover\:border-success-dark:hover {
  border-color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-success-darker {
  border-color: #446443 !important;
}

.hover\:border-success-darker:hover {
  border-color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-info-lighter {
  border-color: #e7f6f8 !important;
}

.hover\:border-info-lighter:hover {
  border-color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-info-light {
  border-color: #99deea !important;
}

.hover\:border-info-light:hover {
  border-color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-info {
  border-color: #00bde3 !important;
}

.hover\:border-info:hover {
  border-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-info-dark {
  border-color: #009ec1 !important;
}

.hover\:border-info-dark:hover {
  border-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-info-darker {
  border-color: #2e6276 !important;
}

.hover\:border-info-darker:hover {
  border-color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-disabled-light {
  border-color: #e6e6e6 !important;
}

.hover\:border-disabled-light:hover {
  border-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-disabled {
  border-color: #c9c9c9 !important;
}

.hover\:border-disabled:hover {
  border-color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-disabled-dark {
  border-color: #adadad !important;
}

.hover\:border-disabled-dark:hover {
  border-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-emergency {
  border-color: #9c3d10 !important;
}

.hover\:border-emergency:hover {
  border-color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-emergency-dark {
  border-color: #332d29 !important;
}

.hover\:border-emergency-dark:hover {
  border-color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base-lightest {
  border-color: #f0f0f0 !important;
}

.hover\:border-default-base-lightest:hover {
  border-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base-lighter {
  border-color: #dcdee0 !important;
}

.hover\:border-default-base-lighter:hover {
  border-color: #dcdee0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base-light {
  border-color: #a9aeb1 !important;
}

.hover\:border-default-base-light:hover {
  border-color: #a9aeb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base {
  border-color: #71767a !important;
}

.hover\:border-default-base:hover {
  border-color: #71767a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base-dark {
  border-color: #565c65 !important;
}

.hover\:border-default-base-dark:hover {
  border-color: #565c65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base-darker {
  border-color: #3d4551 !important;
}

.hover\:border-default-base-darker:hover {
  border-color: #3d4551 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-base-darkest {
  border-color: #1b1b1b !important;
}

.hover\:border-default-base-darkest:hover {
  border-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-ink {
  border-color: #1b1b1b !important;
}

.hover\:border-default-ink:hover {
  border-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-primary-lighter {
  border-color: #d9e8f6 !important;
}

.hover\:border-default-primary-lighter:hover {
  border-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-primary-light {
  border-color: #73b3e7 !important;
}

.hover\:border-default-primary-light:hover {
  border-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-primary {
  border-color: #005ea2 !important;
}

.hover\:border-default-primary:hover {
  border-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-primary-vivid {
  border-color: #0050d8 !important;
}

.hover\:border-default-primary-vivid:hover {
  border-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-primary-dark {
  border-color: #1a4480 !important;
}

.hover\:border-default-primary-dark:hover {
  border-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-primary-darker {
  border-color: #162e51 !important;
}

.hover\:border-default-primary-darker:hover {
  border-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-secondary-lighter {
  border-color: #f8dfe2 !important;
}

.hover\:border-default-secondary-lighter:hover {
  border-color: #f8dfe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-secondary-light {
  border-color: #f2938c !important;
}

.hover\:border-default-secondary-light:hover {
  border-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-secondary {
  border-color: #d83933 !important;
}

.hover\:border-default-secondary:hover {
  border-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-secondary-vivid {
  border-color: #e41d3d !important;
}

.hover\:border-default-secondary-vivid:hover {
  border-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-secondary-dark {
  border-color: #b51d09 !important;
}

.hover\:border-default-secondary-dark:hover {
  border-color: #b51d09 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-secondary-darker {
  border-color: #8b1303 !important;
}

.hover\:border-default-secondary-darker:hover {
  border-color: #8b1303 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-warm-lighter {
  border-color: #f2e4d4 !important;
}

.hover\:border-default-accent-warm-lighter:hover {
  border-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-warm-light {
  border-color: #ffbc78 !important;
}

.hover\:border-default-accent-warm-light:hover {
  border-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-warm {
  border-color: #fa9441 !important;
}

.hover\:border-default-accent-warm:hover {
  border-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-warm-dark {
  border-color: #c05600 !important;
}

.hover\:border-default-accent-warm-dark:hover {
  border-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-warm-darker {
  border-color: #775540 !important;
}

.hover\:border-default-accent-warm-darker:hover {
  border-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-cool-lighter {
  border-color: #e1f3f8 !important;
}

.hover\:border-default-accent-cool-lighter:hover {
  border-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-cool-light {
  border-color: #97d4ea !important;
}

.hover\:border-default-accent-cool-light:hover {
  border-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-cool {
  border-color: #00bde3 !important;
}

.hover\:border-default-accent-cool:hover {
  border-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-cool-dark {
  border-color: #28a0cb !important;
}

.hover\:border-default-accent-cool-dark:hover {
  border-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-default-accent-cool-darker {
  border-color: #07648d !important;
}

.hover\:border-default-accent-cool-darker:hover {
  border-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-0 {
  border-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-none {
  border-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-top-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-right-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-bottom-none {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-left-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-sm {
  border-radius: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-top-sm {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-right-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-bottom-sm {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-left-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-md {
  border-radius: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-top-md {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-right-md {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-bottom-md {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-left-md {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-lg {
  border-radius: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-bottom-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-pill {
  border-radius: 99rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-top-pill {
  border-top-left-radius: 99rem !important;
  border-top-right-radius: 99rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-right-pill {
  border-top-right-radius: 99rem !important;
  border-bottom-right-radius: 99rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-bottom-pill {
  border-bottom-left-radius: 99rem !important;
  border-bottom-right-radius: 99rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.radius-left-pill {
  border-top-left-radius: 99rem !important;
  border-bottom-left-radius: 99rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-dashed {
  border-style: dashed !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-dotted {
  border-style: dotted !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-solid {
  border-style: solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-1px {
  border-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-1px {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-1px {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-1px {
  border-top-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-1px {
  border-right-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-1px {
  border-bottom-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-1px {
  border-left-width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-2px {
  border-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-2px {
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-2px {
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-2px {
  border-top-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-2px {
  border-right-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-2px {
  border-bottom-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-2px {
  border-left-width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-05 {
  border-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-05 {
  border-top-width: 0.25rem !important;
  border-bottom-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-05 {
  border-left-width: 0.25rem !important;
  border-right-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-05 {
  border-top-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-05 {
  border-right-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-05 {
  border-bottom-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-05 {
  border-left-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-1 {
  border-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-1 {
  border-top-width: 0.5rem !important;
  border-bottom-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-1 {
  border-left-width: 0.5rem !important;
  border-right-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-1 {
  border-top-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-1 {
  border-right-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-1 {
  border-bottom-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-1 {
  border-left-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-105 {
  border-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-105 {
  border-top-width: 0.75rem !important;
  border-bottom-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-105 {
  border-left-width: 0.75rem !important;
  border-right-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-105 {
  border-top-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-105 {
  border-right-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-105 {
  border-bottom-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-105 {
  border-left-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-2 {
  border-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-2 {
  border-top-width: 1rem !important;
  border-bottom-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-2 {
  border-left-width: 1rem !important;
  border-right-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-2 {
  border-top-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-2 {
  border-right-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-2 {
  border-bottom-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-2 {
  border-left-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-205 {
  border-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-205 {
  border-top-width: 1.25rem !important;
  border-bottom-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-205 {
  border-left-width: 1.25rem !important;
  border-right-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-205 {
  border-top-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-205 {
  border-right-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-205 {
  border-bottom-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-205 {
  border-left-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-3 {
  border-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-3 {
  border-top-width: 1.5rem !important;
  border-bottom-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-3 {
  border-left-width: 1.5rem !important;
  border-right-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-3 {
  border-top-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-3 {
  border-right-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-3 {
  border-bottom-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-3 {
  border-left-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-width-0 {
  border-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-y-width-0 {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-x-width-0 {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-top-width-0 {
  border-top-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-right-width-0 {
  border-right-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-bottom-width-0 {
  border-bottom-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.border-left-width-0 {
  border-left-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-1px {
  bottom: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-2px {
  bottom: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-05 {
  bottom: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-1 {
  bottom: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-105 {
  bottom: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-2 {
  bottom: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-205 {
  bottom: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-3 {
  bottom: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-1px {
  bottom: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-2px {
  bottom: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-05 {
  bottom: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-1 {
  bottom: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-105 {
  bottom: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-2 {
  bottom: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-205 {
  bottom: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-neg-3 {
  bottom: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-0 {
  bottom: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-auto {
  bottom: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.bottom-full {
  bottom: 100% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.shadow-none {
  box-shadow: none !important;
}

.hover\:shadow-none:hover {
  box-shadow: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.shadow-1 {
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-1:hover {
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.shadow-2 {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-2:hover {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.shadow-3 {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-3:hover {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.shadow-4 {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-4:hover {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.shadow-5 {
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-5:hover {
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-1px {
  height: 1px !important;
  width: 1px !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-2px {
  height: 2px !important;
  width: 2px !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-05 {
  height: 0.25rem !important;
  width: 0.25rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-1 {
  height: 0.5rem !important;
  width: 0.5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-105 {
  height: 0.75rem !important;
  width: 0.75rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-2 {
  height: 1rem !important;
  width: 1rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-205 {
  height: 1.25rem !important;
  width: 1.25rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-3 {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-4 {
  height: 2rem !important;
  width: 2rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-5 {
  height: 2.5rem !important;
  width: 2.5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-6 {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-7 {
  height: 3.5rem !important;
  width: 3.5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-8 {
  height: 4rem !important;
  width: 4rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-9 {
  height: 4.5rem !important;
  width: 4.5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-10 {
  height: 5rem !important;
  width: 5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-15 {
  height: 7.5rem !important;
  width: 7.5rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-card {
  height: 10rem !important;
  width: 10rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-card-lg {
  height: 15rem !important;
  width: 15rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.circle-mobile {
  height: 20rem !important;
  width: 20rem !important;
  border-radius: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-transparent {
  color: transparent !important;
}

.hover\:text-transparent:hover {
  color: transparent !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black {
  color: black !important;
}

.hover\:text-black:hover {
  color: black !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white {
  color: white !important;
}

.hover\:text-white:hover {
  color: white !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-5 {
  color: rgba(0, 0, 0, 0.01) !important;
}

.hover\:text-black-transparent-5:hover {
  color: rgba(0, 0, 0, 0.01) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-10 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.hover\:text-black-transparent-10:hover {
  color: rgba(0, 0, 0, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-20 {
  color: rgba(0, 0, 0, 0.2) !important;
}

.hover\:text-black-transparent-20:hover {
  color: rgba(0, 0, 0, 0.2) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-30 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.hover\:text-black-transparent-30:hover {
  color: rgba(0, 0, 0, 0.3) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-40 {
  color: rgba(0, 0, 0, 0.4) !important;
}

.hover\:text-black-transparent-40:hover {
  color: rgba(0, 0, 0, 0.4) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.hover\:text-black-transparent-50:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-60 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.hover\:text-black-transparent-60:hover {
  color: rgba(0, 0, 0, 0.6) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-70 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.hover\:text-black-transparent-70:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-80 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.hover\:text-black-transparent-80:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-black-transparent-90 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.hover\:text-black-transparent-90:hover {
  color: rgba(0, 0, 0, 0.9) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-1 {
  color: #fbfcfd !important;
}

.hover\:text-gray-cool-1:hover {
  color: #fbfcfd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-2 {
  color: #f7f9fa !important;
}

.hover\:text-gray-cool-2:hover {
  color: #f7f9fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-3 {
  color: #f5f6f7 !important;
}

.hover\:text-gray-cool-3:hover {
  color: #f5f6f7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-4 {
  color: #f1f3f6 !important;
}

.hover\:text-gray-cool-4:hover {
  color: #f1f3f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-5 {
  color: #edeff0 !important;
}

.hover\:text-gray-cool-5:hover {
  color: #edeff0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-10 {
  color: #dfe1e2 !important;
}

.hover\:text-gray-cool-10:hover {
  color: #dfe1e2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-20 {
  color: #c6cace !important;
}

.hover\:text-gray-cool-20:hover {
  color: #c6cace !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-30 {
  color: #a9aeb1 !important;
}

.hover\:text-gray-cool-30:hover {
  color: #a9aeb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-40 {
  color: #8d9297 !important;
}

.hover\:text-gray-cool-40:hover {
  color: #8d9297 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-50 {
  color: #71767a !important;
}

.hover\:text-gray-cool-50:hover {
  color: #71767a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-60 {
  color: #565c65 !important;
}

.hover\:text-gray-cool-60:hover {
  color: #565c65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-70 {
  color: #3d4551 !important;
}

.hover\:text-gray-cool-70:hover {
  color: #3d4551 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-80 {
  color: #2d2e2f !important;
}

.hover\:text-gray-cool-80:hover {
  color: #2d2e2f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-cool-90 {
  color: #1c1d1f !important;
}

.hover\:text-gray-cool-90:hover {
  color: #1c1d1f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-1 {
  color: #fcfcfc !important;
}

.hover\:text-gray-1:hover {
  color: #fcfcfc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-2 {
  color: #f9f9f9 !important;
}

.hover\:text-gray-2:hover {
  color: #f9f9f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-3 {
  color: #f6f6f6 !important;
}

.hover\:text-gray-3:hover {
  color: #f6f6f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-4 {
  color: #f3f3f3 !important;
}

.hover\:text-gray-4:hover {
  color: #f3f3f3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-5 {
  color: #f0f0f0 !important;
}

.hover\:text-gray-5:hover {
  color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-10 {
  color: #e6e6e6 !important;
}

.hover\:text-gray-10:hover {
  color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-20 {
  color: #c9c9c9 !important;
}

.hover\:text-gray-20:hover {
  color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-30 {
  color: #adadad !important;
}

.hover\:text-gray-30:hover {
  color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-40 {
  color: #919191 !important;
}

.hover\:text-gray-40:hover {
  color: #919191 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-50 {
  color: #757575 !important;
}

.hover\:text-gray-50:hover {
  color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-60 {
  color: #5c5c5c !important;
}

.hover\:text-gray-60:hover {
  color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-70 {
  color: #454545 !important;
}

.hover\:text-gray-70:hover {
  color: #454545 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-80 {
  color: #2e2e2e !important;
}

.hover\:text-gray-80:hover {
  color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-90 {
  color: #1b1b1b !important;
}

.hover\:text-gray-90:hover {
  color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-1 {
  color: #fcfcfb !important;
}

.hover\:text-gray-warm-1:hover {
  color: #fcfcfb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-2 {
  color: #f9f9f7 !important;
}

.hover\:text-gray-warm-2:hover {
  color: #f9f9f7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-3 {
  color: #f6f6f2 !important;
}

.hover\:text-gray-warm-3:hover {
  color: #f6f6f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-4 {
  color: #f5f5f0 !important;
}

.hover\:text-gray-warm-4:hover {
  color: #f5f5f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-5 {
  color: #f0f0ec !important;
}

.hover\:text-gray-warm-5:hover {
  color: #f0f0ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-10 {
  color: #e6e6e2 !important;
}

.hover\:text-gray-warm-10:hover {
  color: #e6e6e2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-20 {
  color: #cac9c0 !important;
}

.hover\:text-gray-warm-20:hover {
  color: #cac9c0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-30 {
  color: #afaea2 !important;
}

.hover\:text-gray-warm-30:hover {
  color: #afaea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-40 {
  color: #929285 !important;
}

.hover\:text-gray-warm-40:hover {
  color: #929285 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-50 {
  color: #76766a !important;
}

.hover\:text-gray-warm-50:hover {
  color: #76766a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-60 {
  color: #5d5d52 !important;
}

.hover\:text-gray-warm-60:hover {
  color: #5d5d52 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-70 {
  color: #454540 !important;
}

.hover\:text-gray-warm-70:hover {
  color: #454540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-80 {
  color: #2e2e2a !important;
}

.hover\:text-gray-warm-80:hover {
  color: #2e2e2a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gray-warm-90 {
  color: #171716 !important;
}

.hover\:text-gray-warm-90:hover {
  color: #171716 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-5 {
  color: #e7f2f5 !important;
}

.hover\:text-blue-cool-5:hover {
  color: #e7f2f5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-10 {
  color: #dae9ee !important;
}

.hover\:text-blue-cool-10:hover {
  color: #dae9ee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-20 {
  color: #adcfdc !important;
}

.hover\:text-blue-cool-20:hover {
  color: #adcfdc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-30 {
  color: #82b4c9 !important;
}

.hover\:text-blue-cool-30:hover {
  color: #82b4c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-40 {
  color: #6499af !important;
}

.hover\:text-blue-cool-40:hover {
  color: #6499af !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-50 {
  color: #3a7d95 !important;
}

.hover\:text-blue-cool-50:hover {
  color: #3a7d95 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-60 {
  color: #2e6276 !important;
}

.hover\:text-blue-cool-60:hover {
  color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-70 {
  color: #224a58 !important;
}

.hover\:text-blue-cool-70:hover {
  color: #224a58 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-80 {
  color: #14333d !important;
}

.hover\:text-blue-cool-80:hover {
  color: #14333d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-90 {
  color: #0f191c !important;
}

.hover\:text-blue-cool-90:hover {
  color: #0f191c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-5v {
  color: #e1f3f8 !important;
}

.hover\:text-blue-cool-5v:hover {
  color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-10v {
  color: #c3ebfa !important;
}

.hover\:text-blue-cool-10v:hover {
  color: #c3ebfa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-20v {
  color: #97d4ea !important;
}

.hover\:text-blue-cool-20v:hover {
  color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-30v {
  color: #59b9de !important;
}

.hover\:text-blue-cool-30v:hover {
  color: #59b9de !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-40v {
  color: #28a0cb !important;
}

.hover\:text-blue-cool-40v:hover {
  color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-50v {
  color: #0d7ea2 !important;
}

.hover\:text-blue-cool-50v:hover {
  color: #0d7ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-60v {
  color: #07648d !important;
}

.hover\:text-blue-cool-60v:hover {
  color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-70v {
  color: #074b69 !important;
}

.hover\:text-blue-cool-70v:hover {
  color: #074b69 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-cool-80v {
  color: #002d3f !important;
}

.hover\:text-blue-cool-80v:hover {
  color: #002d3f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-5 {
  color: #eff6fb !important;
}

.hover\:text-blue-5:hover {
  color: #eff6fb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-10 {
  color: #d9e8f6 !important;
}

.hover\:text-blue-10:hover {
  color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-20 {
  color: #aacdec !important;
}

.hover\:text-blue-20:hover {
  color: #aacdec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-30 {
  color: #73b3e7 !important;
}

.hover\:text-blue-30:hover {
  color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-40 {
  color: #4f97d1 !important;
}

.hover\:text-blue-40:hover {
  color: #4f97d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-50 {
  color: #2378c3 !important;
}

.hover\:text-blue-50:hover {
  color: #2378c3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-60 {
  color: #2c608a !important;
}

.hover\:text-blue-60:hover {
  color: #2c608a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-70 {
  color: #274863 !important;
}

.hover\:text-blue-70:hover {
  color: #274863 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-80 {
  color: #1f303e !important;
}

.hover\:text-blue-80:hover {
  color: #1f303e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-90 {
  color: #11181d !important;
}

.hover\:text-blue-90:hover {
  color: #11181d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-5v {
  color: #e8f5ff !important;
}

.hover\:text-blue-5v:hover {
  color: #e8f5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-10v {
  color: #cfe8ff !important;
}

.hover\:text-blue-10v:hover {
  color: #cfe8ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-20v {
  color: #a1d3ff !important;
}

.hover\:text-blue-20v:hover {
  color: #a1d3ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-30v {
  color: #58b4ff !important;
}

.hover\:text-blue-30v:hover {
  color: #58b4ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-40v {
  color: #2491ff !important;
}

.hover\:text-blue-40v:hover {
  color: #2491ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-50v {
  color: #0076d6 !important;
}

.hover\:text-blue-50v:hover {
  color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-60v {
  color: #005ea2 !important;
}

.hover\:text-blue-60v:hover {
  color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-70v {
  color: #0b4778 !important;
}

.hover\:text-blue-70v:hover {
  color: #0b4778 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-80v {
  color: #112f4e !important;
}

.hover\:text-blue-80v:hover {
  color: #112f4e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-5 {
  color: #ecf1f7 !important;
}

.hover\:text-blue-warm-5:hover {
  color: #ecf1f7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-10 {
  color: #e1e7f1 !important;
}

.hover\:text-blue-warm-10:hover {
  color: #e1e7f1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-20 {
  color: #bbcae4 !important;
}

.hover\:text-blue-warm-20:hover {
  color: #bbcae4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-30 {
  color: #98afd2 !important;
}

.hover\:text-blue-warm-30:hover {
  color: #98afd2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-40 {
  color: #7292c7 !important;
}

.hover\:text-blue-warm-40:hover {
  color: #7292c7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-50 {
  color: #4a77b4 !important;
}

.hover\:text-blue-warm-50:hover {
  color: #4a77b4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-60 {
  color: #345d96 !important;
}

.hover\:text-blue-warm-60:hover {
  color: #345d96 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-70 {
  color: #2f4668 !important;
}

.hover\:text-blue-warm-70:hover {
  color: #2f4668 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-80 {
  color: #252f3e !important;
}

.hover\:text-blue-warm-80:hover {
  color: #252f3e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-90 {
  color: #13171f !important;
}

.hover\:text-blue-warm-90:hover {
  color: #13171f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-5v {
  color: #edf5ff !important;
}

.hover\:text-blue-warm-5v:hover {
  color: #edf5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-10v {
  color: #d4e5ff !important;
}

.hover\:text-blue-warm-10v:hover {
  color: #d4e5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-20v {
  color: #adcdff !important;
}

.hover\:text-blue-warm-20v:hover {
  color: #adcdff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-30v {
  color: #81aefc !important;
}

.hover\:text-blue-warm-30v:hover {
  color: #81aefc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-40v {
  color: #5994f6 !important;
}

.hover\:text-blue-warm-40v:hover {
  color: #5994f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-50v {
  color: #2672de !important;
}

.hover\:text-blue-warm-50v:hover {
  color: #2672de !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-60v {
  color: #0050d8 !important;
}

.hover\:text-blue-warm-60v:hover {
  color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-70v {
  color: #1a4480 !important;
}

.hover\:text-blue-warm-70v:hover {
  color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue-warm-80v {
  color: #162e51 !important;
}

.hover\:text-blue-warm-80v:hover {
  color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-5 {
  color: #e7f6f8 !important;
}

.hover\:text-cyan-5:hover {
  color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-10 {
  color: #ccecf2 !important;
}

.hover\:text-cyan-10:hover {
  color: #ccecf2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-20 {
  color: #99deea !important;
}

.hover\:text-cyan-20:hover {
  color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-30 {
  color: #5dc0d1 !important;
}

.hover\:text-cyan-30:hover {
  color: #5dc0d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-40 {
  color: #449dac !important;
}

.hover\:text-cyan-40:hover {
  color: #449dac !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-50 {
  color: #168092 !important;
}

.hover\:text-cyan-50:hover {
  color: #168092 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-60 {
  color: #2a646d !important;
}

.hover\:text-cyan-60:hover {
  color: #2a646d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-70 {
  color: #2c4a4e !important;
}

.hover\:text-cyan-70:hover {
  color: #2c4a4e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-80 {
  color: #203133 !important;
}

.hover\:text-cyan-80:hover {
  color: #203133 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-90 {
  color: #111819 !important;
}

.hover\:text-cyan-90:hover {
  color: #111819 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-5v {
  color: #e5faff !important;
}

.hover\:text-cyan-5v:hover {
  color: #e5faff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-10v {
  color: #a8f2ff !important;
}

.hover\:text-cyan-10v:hover {
  color: #a8f2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-20v {
  color: #52daf2 !important;
}

.hover\:text-cyan-20v:hover {
  color: #52daf2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-30v {
  color: #00bde3 !important;
}

.hover\:text-cyan-30v:hover {
  color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-40v {
  color: #009ec1 !important;
}

.hover\:text-cyan-40v:hover {
  color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-50v {
  color: #0081a1 !important;
}

.hover\:text-cyan-50v:hover {
  color: #0081a1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-60v {
  color: #00687d !important;
}

.hover\:text-cyan-60v:hover {
  color: #00687d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-70v {
  color: #0e4f5c !important;
}

.hover\:text-cyan-70v:hover {
  color: #0e4f5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan-80v {
  color: #093b44 !important;
}

.hover\:text-cyan-80v:hover {
  color: #093b44 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-5 {
  color: #f5f0e6 !important;
}

.hover\:text-gold-5:hover {
  color: #f5f0e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-10 {
  color: #f1e5cd !important;
}

.hover\:text-gold-10:hover {
  color: #f1e5cd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-20 {
  color: #dec69a !important;
}

.hover\:text-gold-20:hover {
  color: #dec69a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-30 {
  color: #c7a97b !important;
}

.hover\:text-gold-30:hover {
  color: #c7a97b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-40 {
  color: #ad8b65 !important;
}

.hover\:text-gold-40:hover {
  color: #ad8b65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-50 {
  color: #8e704f !important;
}

.hover\:text-gold-50:hover {
  color: #8e704f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-60 {
  color: #6b5947 !important;
}

.hover\:text-gold-60:hover {
  color: #6b5947 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-70 {
  color: #4d4438 !important;
}

.hover\:text-gold-70:hover {
  color: #4d4438 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-80 {
  color: #322d26 !important;
}

.hover\:text-gold-80:hover {
  color: #322d26 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-90 {
  color: #191714 !important;
}

.hover\:text-gold-90:hover {
  color: #191714 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-5v {
  color: #fef0c8 !important;
}

.hover\:text-gold-5v:hover {
  color: #fef0c8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-10v {
  color: #ffe396 !important;
}

.hover\:text-gold-10v:hover {
  color: #ffe396 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-20v {
  color: #ffbe2e !important;
}

.hover\:text-gold-20v:hover {
  color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-30v {
  color: #e5a000 !important;
}

.hover\:text-gold-30v:hover {
  color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-40v {
  color: #c2850c !important;
}

.hover\:text-gold-40v:hover {
  color: #c2850c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-50v {
  color: #936f38 !important;
}

.hover\:text-gold-50v:hover {
  color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-60v {
  color: #7a591a !important;
}

.hover\:text-gold-60v:hover {
  color: #7a591a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-70v {
  color: #5c410a !important;
}

.hover\:text-gold-70v:hover {
  color: #5c410a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold-80v {
  color: #3b2b15 !important;
}

.hover\:text-gold-80v:hover {
  color: #3b2b15 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-5 {
  color: #ecf3ec !important;
}

.hover\:text-green-cool-5:hover {
  color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-10 {
  color: #dbebde !important;
}

.hover\:text-green-cool-10:hover {
  color: #dbebde !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-20 {
  color: #b4d0b9 !important;
}

.hover\:text-green-cool-20:hover {
  color: #b4d0b9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-30 {
  color: #86b98e !important;
}

.hover\:text-green-cool-30:hover {
  color: #86b98e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-40 {
  color: #5e9f69 !important;
}

.hover\:text-green-cool-40:hover {
  color: #5e9f69 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-50 {
  color: #4d8055 !important;
}

.hover\:text-green-cool-50:hover {
  color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-60 {
  color: #446443 !important;
}

.hover\:text-green-cool-60:hover {
  color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-70 {
  color: #37493b !important;
}

.hover\:text-green-cool-70:hover {
  color: #37493b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-80 {
  color: #28312a !important;
}

.hover\:text-green-cool-80:hover {
  color: #28312a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-90 {
  color: #1a1f1a !important;
}

.hover\:text-green-cool-90:hover {
  color: #1a1f1a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-5v {
  color: #e3f5e1 !important;
}

.hover\:text-green-cool-5v:hover {
  color: #e3f5e1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-10v {
  color: #b7f5bd !important;
}

.hover\:text-green-cool-10v:hover {
  color: #b7f5bd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-20v {
  color: #70e17b !important;
}

.hover\:text-green-cool-20v:hover {
  color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-30v {
  color: #21c834 !important;
}

.hover\:text-green-cool-30v:hover {
  color: #21c834 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-40v {
  color: #00a91c !important;
}

.hover\:text-green-cool-40v:hover {
  color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-50v {
  color: #008817 !important;
}

.hover\:text-green-cool-50v:hover {
  color: #008817 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-60v {
  color: #216e1f !important;
}

.hover\:text-green-cool-60v:hover {
  color: #216e1f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-70v {
  color: #154c21 !important;
}

.hover\:text-green-cool-70v:hover {
  color: #154c21 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-cool-80v {
  color: #19311e !important;
}

.hover\:text-green-cool-80v:hover {
  color: #19311e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-5 {
  color: #eaf4dd !important;
}

.hover\:text-green-5:hover {
  color: #eaf4dd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-10 {
  color: #dfeacd !important;
}

.hover\:text-green-10:hover {
  color: #dfeacd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-20 {
  color: #b8d293 !important;
}

.hover\:text-green-20:hover {
  color: #b8d293 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-30 {
  color: #9bb672 !important;
}

.hover\:text-green-30:hover {
  color: #9bb672 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-40 {
  color: #7d9b4e !important;
}

.hover\:text-green-40:hover {
  color: #7d9b4e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-50 {
  color: #607f35 !important;
}

.hover\:text-green-50:hover {
  color: #607f35 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-60 {
  color: #4c6424 !important;
}

.hover\:text-green-60:hover {
  color: #4c6424 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-70 {
  color: #3c4a29 !important;
}

.hover\:text-green-70:hover {
  color: #3c4a29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-80 {
  color: #293021 !important;
}

.hover\:text-green-80:hover {
  color: #293021 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-90 {
  color: #161814 !important;
}

.hover\:text-green-90:hover {
  color: #161814 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-5v {
  color: #ddf9c7 !important;
}

.hover\:text-green-5v:hover {
  color: #ddf9c7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-10v {
  color: #c5ee93 !important;
}

.hover\:text-green-10v:hover {
  color: #c5ee93 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-20v {
  color: #98d035 !important;
}

.hover\:text-green-20v:hover {
  color: #98d035 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-30v {
  color: #7fb135 !important;
}

.hover\:text-green-30v:hover {
  color: #7fb135 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-40v {
  color: #719f2a !important;
}

.hover\:text-green-40v:hover {
  color: #719f2a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-50v {
  color: #538200 !important;
}

.hover\:text-green-50v:hover {
  color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-60v {
  color: #466c04 !important;
}

.hover\:text-green-60v:hover {
  color: #466c04 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-70v {
  color: #2f4a0b !important;
}

.hover\:text-green-70v:hover {
  color: #2f4a0b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-80v {
  color: #243413 !important;
}

.hover\:text-green-80v:hover {
  color: #243413 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-5 {
  color: #f1f4d7 !important;
}

.hover\:text-green-warm-5:hover {
  color: #f1f4d7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-10 {
  color: #e7eab7 !important;
}

.hover\:text-green-warm-10:hover {
  color: #e7eab7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-20 {
  color: #cbd17a !important;
}

.hover\:text-green-warm-20:hover {
  color: #cbd17a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-30 {
  color: #a6b557 !important;
}

.hover\:text-green-warm-30:hover {
  color: #a6b557 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-40 {
  color: #8a984b !important;
}

.hover\:text-green-warm-40:hover {
  color: #8a984b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-50 {
  color: #6f7a41 !important;
}

.hover\:text-green-warm-50:hover {
  color: #6f7a41 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-60 {
  color: #5a5f38 !important;
}

.hover\:text-green-warm-60:hover {
  color: #5a5f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-70 {
  color: #45472f !important;
}

.hover\:text-green-warm-70:hover {
  color: #45472f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-80 {
  color: #2d2f21 !important;
}

.hover\:text-green-warm-80:hover {
  color: #2d2f21 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-90 {
  color: #171712 !important;
}

.hover\:text-green-warm-90:hover {
  color: #171712 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-5v {
  color: #f5fbc1 !important;
}

.hover\:text-green-warm-5v:hover {
  color: #f5fbc1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-10v {
  color: #e7f434 !important;
}

.hover\:text-green-warm-10v:hover {
  color: #e7f434 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-20v {
  color: #c5d30a !important;
}

.hover\:text-green-warm-20v:hover {
  color: #c5d30a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-30v {
  color: #a3b72c !important;
}

.hover\:text-green-warm-30v:hover {
  color: #a3b72c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-40v {
  color: #7e9c1d !important;
}

.hover\:text-green-warm-40v:hover {
  color: #7e9c1d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-50v {
  color: #6a7d00 !important;
}

.hover\:text-green-warm-50v:hover {
  color: #6a7d00 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-60v {
  color: #5a6613 !important;
}

.hover\:text-green-warm-60v:hover {
  color: #5a6613 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-70v {
  color: #4b4e10 !important;
}

.hover\:text-green-warm-70v:hover {
  color: #4b4e10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green-warm-80v {
  color: #38380b !important;
}

.hover\:text-green-warm-80v:hover {
  color: #38380b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-5 {
  color: #eef0f9 !important;
}

.hover\:text-indigo-cool-5:hover {
  color: #eef0f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-10 {
  color: #e1e6f9 !important;
}

.hover\:text-indigo-cool-10:hover {
  color: #e1e6f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-20 {
  color: #bbc8f5 !important;
}

.hover\:text-indigo-cool-20:hover {
  color: #bbc8f5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-30 {
  color: #96abee !important;
}

.hover\:text-indigo-cool-30:hover {
  color: #96abee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-40 {
  color: #6b8ee8 !important;
}

.hover\:text-indigo-cool-40:hover {
  color: #6b8ee8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-50 {
  color: #496fd8 !important;
}

.hover\:text-indigo-cool-50:hover {
  color: #496fd8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-60 {
  color: #3f57a6 !important;
}

.hover\:text-indigo-cool-60:hover {
  color: #3f57a6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-70 {
  color: #374274 !important;
}

.hover\:text-indigo-cool-70:hover {
  color: #374274 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-80 {
  color: #292d42 !important;
}

.hover\:text-indigo-cool-80:hover {
  color: #292d42 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-90 {
  color: #151622 !important;
}

.hover\:text-indigo-cool-90:hover {
  color: #151622 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-5v {
  color: #edf0ff !important;
}

.hover\:text-indigo-cool-5v:hover {
  color: #edf0ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-10v {
  color: #dee5ff !important;
}

.hover\:text-indigo-cool-10v:hover {
  color: #dee5ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-20v {
  color: #b8c8ff !important;
}

.hover\:text-indigo-cool-20v:hover {
  color: #b8c8ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-30v {
  color: #94adff !important;
}

.hover\:text-indigo-cool-30v:hover {
  color: #94adff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-40v {
  color: #628ef4 !important;
}

.hover\:text-indigo-cool-40v:hover {
  color: #628ef4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-50v {
  color: #4866ff !important;
}

.hover\:text-indigo-cool-50v:hover {
  color: #4866ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-60v {
  color: #3e4ded !important;
}

.hover\:text-indigo-cool-60v:hover {
  color: #3e4ded !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-70v {
  color: #222fbf !important;
}

.hover\:text-indigo-cool-70v:hover {
  color: #222fbf !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-cool-80v {
  color: #1b2b85 !important;
}

.hover\:text-indigo-cool-80v:hover {
  color: #1b2b85 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-5 {
  color: #efeff8 !important;
}

.hover\:text-indigo-5:hover {
  color: #efeff8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-10 {
  color: #e5e4fa !important;
}

.hover\:text-indigo-10:hover {
  color: #e5e4fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-20 {
  color: #c5c5f3 !important;
}

.hover\:text-indigo-20:hover {
  color: #c5c5f3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-30 {
  color: #a5a8eb !important;
}

.hover\:text-indigo-30:hover {
  color: #a5a8eb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-40 {
  color: #8889db !important;
}

.hover\:text-indigo-40:hover {
  color: #8889db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-50 {
  color: #676cc8 !important;
}

.hover\:text-indigo-50:hover {
  color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-60 {
  color: #4d52af !important;
}

.hover\:text-indigo-60:hover {
  color: #4d52af !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-70 {
  color: #3d4076 !important;
}

.hover\:text-indigo-70:hover {
  color: #3d4076 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-80 {
  color: #2b2c40 !important;
}

.hover\:text-indigo-80:hover {
  color: #2b2c40 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-90 {
  color: #16171f !important;
}

.hover\:text-indigo-90:hover {
  color: #16171f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-5v {
  color: #f0f0ff !important;
}

.hover\:text-indigo-5v:hover {
  color: #f0f0ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-10v {
  color: #e0e0ff !important;
}

.hover\:text-indigo-10v:hover {
  color: #e0e0ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-20v {
  color: #ccceff !important;
}

.hover\:text-indigo-20v:hover {
  color: #ccceff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-30v {
  color: #a3a7fa !important;
}

.hover\:text-indigo-30v:hover {
  color: #a3a7fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-40v {
  color: #8289ff !important;
}

.hover\:text-indigo-40v:hover {
  color: #8289ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-50v {
  color: #656bd7 !important;
}

.hover\:text-indigo-50v:hover {
  color: #656bd7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-60v {
  color: #4a50c4 !important;
}

.hover\:text-indigo-60v:hover {
  color: #4a50c4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-70v {
  color: #3333a3 !important;
}

.hover\:text-indigo-70v:hover {
  color: #3333a3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-80v {
  color: #212463 !important;
}

.hover\:text-indigo-80v:hover {
  color: #212463 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-5 {
  color: #f1eff7 !important;
}

.hover\:text-indigo-warm-5:hover {
  color: #f1eff7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-10 {
  color: #e7e3fa !important;
}

.hover\:text-indigo-warm-10:hover {
  color: #e7e3fa !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-20 {
  color: #cbc4f2 !important;
}

.hover\:text-indigo-warm-20:hover {
  color: #cbc4f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-30 {
  color: #afa5e8 !important;
}

.hover\:text-indigo-warm-30:hover {
  color: #afa5e8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-40 {
  color: #9287d8 !important;
}

.hover\:text-indigo-warm-40:hover {
  color: #9287d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-50 {
  color: #7665d1 !important;
}

.hover\:text-indigo-warm-50:hover {
  color: #7665d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-60 {
  color: #5e519e !important;
}

.hover\:text-indigo-warm-60:hover {
  color: #5e519e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-70 {
  color: #453c7b !important;
}

.hover\:text-indigo-warm-70:hover {
  color: #453c7b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-80 {
  color: #2e2c40 !important;
}

.hover\:text-indigo-warm-80:hover {
  color: #2e2c40 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-90 {
  color: #18161d !important;
}

.hover\:text-indigo-warm-90:hover {
  color: #18161d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-5v {
  color: #f5f2ff !important;
}

.hover\:text-indigo-warm-5v:hover {
  color: #f5f2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-10v {
  color: #e4deff !important;
}

.hover\:text-indigo-warm-10v:hover {
  color: #e4deff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-20v {
  color: #cfc4fd !important;
}

.hover\:text-indigo-warm-20v:hover {
  color: #cfc4fd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-30v {
  color: #b69fff !important;
}

.hover\:text-indigo-warm-30v:hover {
  color: #b69fff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-40v {
  color: #967efb !important;
}

.hover\:text-indigo-warm-40v:hover {
  color: #967efb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-50v {
  color: #745fe9 !important;
}

.hover\:text-indigo-warm-50v:hover {
  color: #745fe9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-60v {
  color: #5942d2 !important;
}

.hover\:text-indigo-warm-60v:hover {
  color: #5942d2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-70v {
  color: #3d2c9d !important;
}

.hover\:text-indigo-warm-70v:hover {
  color: #3d2c9d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo-warm-80v {
  color: #261f5b !important;
}

.hover\:text-indigo-warm-80v:hover {
  color: #261f5b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-5 {
  color: #f9f0f2 !important;
}

.hover\:text-magenta-5:hover {
  color: #f9f0f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-10 {
  color: #f6e1e8 !important;
}

.hover\:text-magenta-10:hover {
  color: #f6e1e8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-20 {
  color: #f0bbcc !important;
}

.hover\:text-magenta-20:hover {
  color: #f0bbcc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-30 {
  color: #e895b3 !important;
}

.hover\:text-magenta-30:hover {
  color: #e895b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-40 {
  color: #e0699f !important;
}

.hover\:text-magenta-40:hover {
  color: #e0699f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-50 {
  color: #c84281 !important;
}

.hover\:text-magenta-50:hover {
  color: #c84281 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-60 {
  color: #8b4566 !important;
}

.hover\:text-magenta-60:hover {
  color: #8b4566 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-70 {
  color: #66364b !important;
}

.hover\:text-magenta-70:hover {
  color: #66364b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-80 {
  color: #402731 !important;
}

.hover\:text-magenta-80:hover {
  color: #402731 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-90 {
  color: #1b1617 !important;
}

.hover\:text-magenta-90:hover {
  color: #1b1617 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-5v {
  color: #f9f0f2 !important;
}

.hover\:text-magenta-5v:hover {
  color: #f9f0f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-10v {
  color: #ffddea !important;
}

.hover\:text-magenta-10v:hover {
  color: #ffddea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-20v {
  color: #ffb4cf !important;
}

.hover\:text-magenta-20v:hover {
  color: #ffb4cf !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-30v {
  color: #ff87b2 !important;
}

.hover\:text-magenta-30v:hover {
  color: #ff87b2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-40v {
  color: #fd4496 !important;
}

.hover\:text-magenta-40v:hover {
  color: #fd4496 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-50v {
  color: #d72d79 !important;
}

.hover\:text-magenta-50v:hover {
  color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-60v {
  color: #ab2165 !important;
}

.hover\:text-magenta-60v:hover {
  color: #ab2165 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-70v {
  color: #731f44 !important;
}

.hover\:text-magenta-70v:hover {
  color: #731f44 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta-80v {
  color: #4f172e !important;
}

.hover\:text-magenta-80v:hover {
  color: #4f172e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-5 {
  color: #e0f7f6 !important;
}

.hover\:text-mint-cool-5:hover {
  color: #e0f7f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-10 {
  color: #c4eeeb !important;
}

.hover\:text-mint-cool-10:hover {
  color: #c4eeeb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-20 {
  color: #9bd4cf !important;
}

.hover\:text-mint-cool-20:hover {
  color: #9bd4cf !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-30 {
  color: #6fbab3 !important;
}

.hover\:text-mint-cool-30:hover {
  color: #6fbab3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-40 {
  color: #4f9e99 !important;
}

.hover\:text-mint-cool-40:hover {
  color: #4f9e99 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-50 {
  color: #40807e !important;
}

.hover\:text-mint-cool-50:hover {
  color: #40807e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-60 {
  color: #376462 !important;
}

.hover\:text-mint-cool-60:hover {
  color: #376462 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-70 {
  color: #2a4b45 !important;
}

.hover\:text-mint-cool-70:hover {
  color: #2a4b45 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-80 {
  color: #203131 !important;
}

.hover\:text-mint-cool-80:hover {
  color: #203131 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-90 {
  color: #111818 !important;
}

.hover\:text-mint-cool-90:hover {
  color: #111818 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-5v {
  color: #d5fbf3 !important;
}

.hover\:text-mint-cool-5v:hover {
  color: #d5fbf3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-10v {
  color: #7efbe1 !important;
}

.hover\:text-mint-cool-10v:hover {
  color: #7efbe1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-20v {
  color: #29e1cb !important;
}

.hover\:text-mint-cool-20v:hover {
  color: #29e1cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-30v {
  color: #1dc2ae !important;
}

.hover\:text-mint-cool-30v:hover {
  color: #1dc2ae !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-40v {
  color: #00a398 !important;
}

.hover\:text-mint-cool-40v:hover {
  color: #00a398 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-50v {
  color: #008480 !important;
}

.hover\:text-mint-cool-50v:hover {
  color: #008480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-60v {
  color: #0f6460 !important;
}

.hover\:text-mint-cool-60v:hover {
  color: #0f6460 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-70v {
  color: #0b4b3f !important;
}

.hover\:text-mint-cool-70v:hover {
  color: #0b4b3f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-cool-80v {
  color: #123131 !important;
}

.hover\:text-mint-cool-80v:hover {
  color: #123131 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-5 {
  color: #dbf6ed !important;
}

.hover\:text-mint-5:hover {
  color: #dbf6ed !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-10 {
  color: #c7efe2 !important;
}

.hover\:text-mint-10:hover {
  color: #c7efe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-20 {
  color: #92d9bb !important;
}

.hover\:text-mint-20:hover {
  color: #92d9bb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-30 {
  color: #5abf95 !important;
}

.hover\:text-mint-30:hover {
  color: #5abf95 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-40 {
  color: #34a37e !important;
}

.hover\:text-mint-40:hover {
  color: #34a37e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-50 {
  color: #2e8367 !important;
}

.hover\:text-mint-50:hover {
  color: #2e8367 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-60 {
  color: #286846 !important;
}

.hover\:text-mint-60:hover {
  color: #286846 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-70 {
  color: #204e34 !important;
}

.hover\:text-mint-70:hover {
  color: #204e34 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-80 {
  color: #193324 !important;
}

.hover\:text-mint-80:hover {
  color: #193324 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-90 {
  color: #0d1a12 !important;
}

.hover\:text-mint-90:hover {
  color: #0d1a12 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-5v {
  color: #c9fbeb !important;
}

.hover\:text-mint-5v:hover {
  color: #c9fbeb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-10v {
  color: #83fcd4 !important;
}

.hover\:text-mint-10v:hover {
  color: #83fcd4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-20v {
  color: #0ceda6 !important;
}

.hover\:text-mint-20v:hover {
  color: #0ceda6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-30v {
  color: #04c585 !important;
}

.hover\:text-mint-30v:hover {
  color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-40v {
  color: #00a871 !important;
}

.hover\:text-mint-40v:hover {
  color: #00a871 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-50v {
  color: #008659 !important;
}

.hover\:text-mint-50v:hover {
  color: #008659 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-60v {
  color: #146947 !important;
}

.hover\:text-mint-60v:hover {
  color: #146947 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-70v {
  color: #0c4e29 !important;
}

.hover\:text-mint-70v:hover {
  color: #0c4e29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint-80v {
  color: #0d351e !important;
}

.hover\:text-mint-80v:hover {
  color: #0d351e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-5 {
  color: #f6efe9 !important;
}

.hover\:text-orange-5:hover {
  color: #f6efe9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-10 {
  color: #f2e4d4 !important;
}

.hover\:text-orange-10:hover {
  color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-20 {
  color: #f3bf90 !important;
}

.hover\:text-orange-20:hover {
  color: #f3bf90 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-30 {
  color: #f09860 !important;
}

.hover\:text-orange-30:hover {
  color: #f09860 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-40 {
  color: #dd7533 !important;
}

.hover\:text-orange-40:hover {
  color: #dd7533 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-50 {
  color: #a86437 !important;
}

.hover\:text-orange-50:hover {
  color: #a86437 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-60 {
  color: #775540 !important;
}

.hover\:text-orange-60:hover {
  color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-70 {
  color: #524236 !important;
}

.hover\:text-orange-70:hover {
  color: #524236 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-80 {
  color: #332d27 !important;
}

.hover\:text-orange-80:hover {
  color: #332d27 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-90 {
  color: #1b1614 !important;
}

.hover\:text-orange-90:hover {
  color: #1b1614 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-5v {
  color: #fef2e4 !important;
}

.hover\:text-orange-5v:hover {
  color: #fef2e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-10v {
  color: #fce2c5 !important;
}

.hover\:text-orange-10v:hover {
  color: #fce2c5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-20v {
  color: #ffbc78 !important;
}

.hover\:text-orange-20v:hover {
  color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-30v {
  color: #fa9441 !important;
}

.hover\:text-orange-30v:hover {
  color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-40v {
  color: #e66f0e !important;
}

.hover\:text-orange-40v:hover {
  color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-50v {
  color: #c05600 !important;
}

.hover\:text-orange-50v:hover {
  color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-60v {
  color: #8c471c !important;
}

.hover\:text-orange-60v:hover {
  color: #8c471c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-70v {
  color: #5f3617 !important;
}

.hover\:text-orange-70v:hover {
  color: #5f3617 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-80v {
  color: #352313 !important;
}

.hover\:text-orange-80v:hover {
  color: #352313 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-5 {
  color: #faeee5 !important;
}

.hover\:text-orange-warm-5:hover {
  color: #faeee5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-10 {
  color: #fbe0d0 !important;
}

.hover\:text-orange-warm-10:hover {
  color: #fbe0d0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-20 {
  color: #f7bca2 !important;
}

.hover\:text-orange-warm-20:hover {
  color: #f7bca2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-30 {
  color: #f3966d !important;
}

.hover\:text-orange-warm-30:hover {
  color: #f3966d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-40 {
  color: #e17141 !important;
}

.hover\:text-orange-warm-40:hover {
  color: #e17141 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-50 {
  color: #bd5727 !important;
}

.hover\:text-orange-warm-50:hover {
  color: #bd5727 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-60 {
  color: #914734 !important;
}

.hover\:text-orange-warm-60:hover {
  color: #914734 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-70 {
  color: #633a32 !important;
}

.hover\:text-orange-warm-70:hover {
  color: #633a32 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-80 {
  color: #3d2925 !important;
}

.hover\:text-orange-warm-80:hover {
  color: #3d2925 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-90 {
  color: #1c1615 !important;
}

.hover\:text-orange-warm-90:hover {
  color: #1c1615 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-5v {
  color: #fff3ea !important;
}

.hover\:text-orange-warm-5v:hover {
  color: #fff3ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-10v {
  color: #ffe2d1 !important;
}

.hover\:text-orange-warm-10v:hover {
  color: #ffe2d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-20v {
  color: #fbbaa7 !important;
}

.hover\:text-orange-warm-20v:hover {
  color: #fbbaa7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-30v {
  color: #fc906d !important;
}

.hover\:text-orange-warm-30v:hover {
  color: #fc906d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-40v {
  color: #ff580a !important;
}

.hover\:text-orange-warm-40v:hover {
  color: #ff580a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-50v {
  color: #cf4900 !important;
}

.hover\:text-orange-warm-50v:hover {
  color: #cf4900 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-60v {
  color: #a72f10 !important;
}

.hover\:text-orange-warm-60v:hover {
  color: #a72f10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-70v {
  color: #782312 !important;
}

.hover\:text-orange-warm-70v:hover {
  color: #782312 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange-warm-80v {
  color: #3d231d !important;
}

.hover\:text-orange-warm-80v:hover {
  color: #3d231d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-5 {
  color: #f8eff1 !important;
}

.hover\:text-red-cool-5:hover {
  color: #f8eff1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-10 {
  color: #f3e1e4 !important;
}

.hover\:text-red-cool-10:hover {
  color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-20 {
  color: #ecbec6 !important;
}

.hover\:text-red-cool-20:hover {
  color: #ecbec6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-30 {
  color: #e09aa6 !important;
}

.hover\:text-red-cool-30:hover {
  color: #e09aa6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-40 {
  color: #e16b80 !important;
}

.hover\:text-red-cool-40:hover {
  color: #e16b80 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-50 {
  color: #cd425b !important;
}

.hover\:text-red-cool-50:hover {
  color: #cd425b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-60 {
  color: #9e394b !important;
}

.hover\:text-red-cool-60:hover {
  color: #9e394b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-70 {
  color: #68363f !important;
}

.hover\:text-red-cool-70:hover {
  color: #68363f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-80 {
  color: #40282c !important;
}

.hover\:text-red-cool-80:hover {
  color: #40282c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-90 {
  color: #1e1517 !important;
}

.hover\:text-red-cool-90:hover {
  color: #1e1517 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-5v {
  color: #fff2f5 !important;
}

.hover\:text-red-cool-5v:hover {
  color: #fff2f5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-10v {
  color: #f8dfe2 !important;
}

.hover\:text-red-cool-10v:hover {
  color: #f8dfe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-20v {
  color: #f8b9c5 !important;
}

.hover\:text-red-cool-20v:hover {
  color: #f8b9c5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-30v {
  color: #fd8ba0 !important;
}

.hover\:text-red-cool-30v:hover {
  color: #fd8ba0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-40v {
  color: #f45d79 !important;
}

.hover\:text-red-cool-40v:hover {
  color: #f45d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-50v {
  color: #e41d3d !important;
}

.hover\:text-red-cool-50v:hover {
  color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-60v {
  color: #b21d38 !important;
}

.hover\:text-red-cool-60v:hover {
  color: #b21d38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-70v {
  color: #822133 !important;
}

.hover\:text-red-cool-70v:hover {
  color: #822133 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-cool-80v {
  color: #4f1c24 !important;
}

.hover\:text-red-cool-80v:hover {
  color: #4f1c24 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-5 {
  color: #f9eeee !important;
}

.hover\:text-red-5:hover {
  color: #f9eeee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-10 {
  color: #f8e1de !important;
}

.hover\:text-red-10:hover {
  color: #f8e1de !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-20 {
  color: #f7bbb1 !important;
}

.hover\:text-red-20:hover {
  color: #f7bbb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-30 {
  color: #f2938c !important;
}

.hover\:text-red-30:hover {
  color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-40 {
  color: #e9695f !important;
}

.hover\:text-red-40:hover {
  color: #e9695f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-50 {
  color: #d83933 !important;
}

.hover\:text-red-50:hover {
  color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-60 {
  color: #a23737 !important;
}

.hover\:text-red-60:hover {
  color: #a23737 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-70 {
  color: #6f3331 !important;
}

.hover\:text-red-70:hover {
  color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-80 {
  color: #3e2927 !important;
}

.hover\:text-red-80:hover {
  color: #3e2927 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-90 {
  color: #1b1616 !important;
}

.hover\:text-red-90:hover {
  color: #1b1616 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-5v {
  color: #fff3f2 !important;
}

.hover\:text-red-5v:hover {
  color: #fff3f2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-10v {
  color: #fde0db !important;
}

.hover\:text-red-10v:hover {
  color: #fde0db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-20v {
  color: #fdb8ae !important;
}

.hover\:text-red-20v:hover {
  color: #fdb8ae !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-30v {
  color: #ff8d7b !important;
}

.hover\:text-red-30v:hover {
  color: #ff8d7b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-40v {
  color: #fb5a47 !important;
}

.hover\:text-red-40v:hover {
  color: #fb5a47 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-50v {
  color: #e52207 !important;
}

.hover\:text-red-50v:hover {
  color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-60v {
  color: #b50909 !important;
}

.hover\:text-red-60v:hover {
  color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-70v {
  color: #8b0a03 !important;
}

.hover\:text-red-70v:hover {
  color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-80v {
  color: #5c1111 !important;
}

.hover\:text-red-80v:hover {
  color: #5c1111 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-5 {
  color: #f6efea !important;
}

.hover\:text-red-warm-5:hover {
  color: #f6efea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-10 {
  color: #f4e3db !important;
}

.hover\:text-red-warm-10:hover {
  color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-20 {
  color: #ecc0a7 !important;
}

.hover\:text-red-warm-20:hover {
  color: #ecc0a7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-30 {
  color: #dca081 !important;
}

.hover\:text-red-warm-30:hover {
  color: #dca081 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-40 {
  color: #d27a56 !important;
}

.hover\:text-red-warm-40:hover {
  color: #d27a56 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-50 {
  color: #c3512c !important;
}

.hover\:text-red-warm-50:hover {
  color: #c3512c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-60 {
  color: #805039 !important;
}

.hover\:text-red-warm-60:hover {
  color: #805039 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-70 {
  color: #524236 !important;
}

.hover\:text-red-warm-70:hover {
  color: #524236 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-80 {
  color: #332d29 !important;
}

.hover\:text-red-warm-80:hover {
  color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-90 {
  color: #1f1c18 !important;
}

.hover\:text-red-warm-90:hover {
  color: #1f1c18 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-5v {
  color: #fff5ee !important;
}

.hover\:text-red-warm-5v:hover {
  color: #fff5ee !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-10v {
  color: #fce1d4 !important;
}

.hover\:text-red-warm-10v:hover {
  color: #fce1d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-20v {
  color: #f6bd9c !important;
}

.hover\:text-red-warm-20v:hover {
  color: #f6bd9c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-30v {
  color: #f39268 !important;
}

.hover\:text-red-warm-30v:hover {
  color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-40v {
  color: #ef5e25 !important;
}

.hover\:text-red-warm-40v:hover {
  color: #ef5e25 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-50v {
  color: #d54309 !important;
}

.hover\:text-red-warm-50v:hover {
  color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-60v {
  color: #9c3d10 !important;
}

.hover\:text-red-warm-60v:hover {
  color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-70v {
  color: #63340f !important;
}

.hover\:text-red-warm-70v:hover {
  color: #63340f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red-warm-80v {
  color: #3e2a1e !important;
}

.hover\:text-red-warm-80v:hover {
  color: #3e2a1e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-5 {
  color: #f4f1f9 !important;
}

.hover\:text-violet-5:hover {
  color: #f4f1f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-10 {
  color: #ebe3f9 !important;
}

.hover\:text-violet-10:hover {
  color: #ebe3f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-20 {
  color: #d0c3e9 !important;
}

.hover\:text-violet-20:hover {
  color: #d0c3e9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-30 {
  color: #b8a2e3 !important;
}

.hover\:text-violet-30:hover {
  color: #b8a2e3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-40 {
  color: #9d84d2 !important;
}

.hover\:text-violet-40:hover {
  color: #9d84d2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-50 {
  color: #8168b3 !important;
}

.hover\:text-violet-50:hover {
  color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-60 {
  color: #665190 !important;
}

.hover\:text-violet-60:hover {
  color: #665190 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-70 {
  color: #4c3d69 !important;
}

.hover\:text-violet-70:hover {
  color: #4c3d69 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-80 {
  color: #312b3f !important;
}

.hover\:text-violet-80:hover {
  color: #312b3f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-90 {
  color: #18161d !important;
}

.hover\:text-violet-90:hover {
  color: #18161d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-5v {
  color: #f7f2ff !important;
}

.hover\:text-violet-5v:hover {
  color: #f7f2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-10v {
  color: #ede3ff !important;
}

.hover\:text-violet-10v:hover {
  color: #ede3ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-20v {
  color: #d5bfff !important;
}

.hover\:text-violet-20v:hover {
  color: #d5bfff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-30v {
  color: #c39deb !important;
}

.hover\:text-violet-30v:hover {
  color: #c39deb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-40v {
  color: #ad79e9 !important;
}

.hover\:text-violet-40v:hover {
  color: #ad79e9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-50v {
  color: #9355dc !important;
}

.hover\:text-violet-50v:hover {
  color: #9355dc !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-60v {
  color: #783cb9 !important;
}

.hover\:text-violet-60v:hover {
  color: #783cb9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-70v {
  color: #54278f !important;
}

.hover\:text-violet-70v:hover {
  color: #54278f !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-80v {
  color: #39215e !important;
}

.hover\:text-violet-80v:hover {
  color: #39215e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-5 {
  color: #f8f0f9 !important;
}

.hover\:text-violet-warm-5:hover {
  color: #f8f0f9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-10 {
  color: #f6dff8 !important;
}

.hover\:text-violet-warm-10:hover {
  color: #f6dff8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-20 {
  color: #e2bee4 !important;
}

.hover\:text-violet-warm-20:hover {
  color: #e2bee4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-30 {
  color: #d29ad8 !important;
}

.hover\:text-violet-warm-30:hover {
  color: #d29ad8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-40 {
  color: #bf77c8 !important;
}

.hover\:text-violet-warm-40:hover {
  color: #bf77c8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-50 {
  color: #b04abd !important;
}

.hover\:text-violet-warm-50:hover {
  color: #b04abd !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-60 {
  color: #864381 !important;
}

.hover\:text-violet-warm-60:hover {
  color: #864381 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-70 {
  color: #5c395a !important;
}

.hover\:text-violet-warm-70:hover {
  color: #5c395a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-80 {
  color: #382936 !important;
}

.hover\:text-violet-warm-80:hover {
  color: #382936 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-90 {
  color: #1b151b !important;
}

.hover\:text-violet-warm-90:hover {
  color: #1b151b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-5v {
  color: #fef2ff !important;
}

.hover\:text-violet-warm-5v:hover {
  color: #fef2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-10v {
  color: #fbdcff !important;
}

.hover\:text-violet-warm-10v:hover {
  color: #fbdcff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-20v {
  color: #f4b2ff !important;
}

.hover\:text-violet-warm-20v:hover {
  color: #f4b2ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-30v {
  color: #ee83ff !important;
}

.hover\:text-violet-warm-30v:hover {
  color: #ee83ff !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-40v {
  color: #d85bef !important;
}

.hover\:text-violet-warm-40v:hover {
  color: #d85bef !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-50v {
  color: #be32d0 !important;
}

.hover\:text-violet-warm-50v:hover {
  color: #be32d0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-60v {
  color: #93348c !important;
}

.hover\:text-violet-warm-60v:hover {
  color: #93348c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-70v {
  color: #711e6c !important;
}

.hover\:text-violet-warm-70v:hover {
  color: #711e6c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet-warm-80v {
  color: #481441 !important;
}

.hover\:text-violet-warm-80v:hover {
  color: #481441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-5 {
  color: rgba(255, 255, 255, 0.01) !important;
}

.hover\:text-white-transparent-5:hover {
  color: rgba(255, 255, 255, 0.01) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-10 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.hover\:text-white-transparent-10:hover {
  color: rgba(255, 255, 255, 0.1) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-20 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.hover\:text-white-transparent-20:hover {
  color: rgba(255, 255, 255, 0.2) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-30 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.hover\:text-white-transparent-30:hover {
  color: rgba(255, 255, 255, 0.3) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-40 {
  color: rgba(255, 255, 255, 0.4) !important;
}

.hover\:text-white-transparent-40:hover {
  color: rgba(255, 255, 255, 0.4) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.hover\:text-white-transparent-50:hover {
  color: rgba(255, 255, 255, 0.5) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.hover\:text-white-transparent-60:hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.hover\:text-white-transparent-70:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.hover\:text-white-transparent-80:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-white-transparent-90 {
  color: rgba(255, 255, 255, 0.9) !important;
}

.hover\:text-white-transparent-90:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-5 {
  color: #faf3d1 !important;
}

.hover\:text-yellow-5:hover {
  color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-10 {
  color: #f5e6af !important;
}

.hover\:text-yellow-10:hover {
  color: #f5e6af !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-20 {
  color: #e6c74c !important;
}

.hover\:text-yellow-20:hover {
  color: #e6c74c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-30 {
  color: #c9ab48 !important;
}

.hover\:text-yellow-30:hover {
  color: #c9ab48 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-40 {
  color: #a88f48 !important;
}

.hover\:text-yellow-40:hover {
  color: #a88f48 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-50 {
  color: #8a7237 !important;
}

.hover\:text-yellow-50:hover {
  color: #8a7237 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-60 {
  color: #6b5a39 !important;
}

.hover\:text-yellow-60:hover {
  color: #6b5a39 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-70 {
  color: #504332 !important;
}

.hover\:text-yellow-70:hover {
  color: #504332 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-80 {
  color: #332d27 !important;
}

.hover\:text-yellow-80:hover {
  color: #332d27 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-90 {
  color: #1a1614 !important;
}

.hover\:text-yellow-90:hover {
  color: #1a1614 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-5v {
  color: #fff5c2 !important;
}

.hover\:text-yellow-5v:hover {
  color: #fff5c2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-10v {
  color: #fee685 !important;
}

.hover\:text-yellow-10v:hover {
  color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-20v {
  color: #face00 !important;
}

.hover\:text-yellow-20v:hover {
  color: #face00 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-30v {
  color: #ddaa01 !important;
}

.hover\:text-yellow-30v:hover {
  color: #ddaa01 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-40v {
  color: #b38c00 !important;
}

.hover\:text-yellow-40v:hover {
  color: #b38c00 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-50v {
  color: #947100 !important;
}

.hover\:text-yellow-50v:hover {
  color: #947100 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-60v {
  color: #776017 !important;
}

.hover\:text-yellow-60v:hover {
  color: #776017 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-70v {
  color: #5c4809 !important;
}

.hover\:text-yellow-70v:hover {
  color: #5c4809 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow-80v {
  color: #422d19 !important;
}

.hover\:text-yellow-80v:hover {
  color: #422d19 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-red {
  color: #e52207 !important;
}

.hover\:text-red:hover {
  color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-orange {
  color: #e66f0e !important;
}

.hover\:text-orange:hover {
  color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-gold {
  color: #ffbe2e !important;
}

.hover\:text-gold:hover {
  color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-yellow {
  color: #fee685 !important;
}

.hover\:text-yellow:hover {
  color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-green {
  color: #538200 !important;
}

.hover\:text-green:hover {
  color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-mint {
  color: #04c585 !important;
}

.hover\:text-mint:hover {
  color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-cyan {
  color: #009ec1 !important;
}

.hover\:text-cyan:hover {
  color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-blue {
  color: #0076d6 !important;
}

.hover\:text-blue:hover {
  color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indigo {
  color: #676cc8 !important;
}

.hover\:text-indigo:hover {
  color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-violet {
  color: #8168b3 !important;
}

.hover\:text-violet:hover {
  color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-magenta {
  color: #d72d79 !important;
}

.hover\:text-magenta:hover {
  color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base-lightest {
  color: #f0f0f0 !important;
}

.hover\:text-base-lightest:hover {
  color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base-lighter {
  color: #e6e6e6 !important;
}

.hover\:text-base-lighter:hover {
  color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base-light {
  color: #adadad !important;
}

.hover\:text-base-light:hover {
  color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base {
  color: #757575 !important;
}

.hover\:text-base:hover {
  color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base-dark {
  color: #5c5c5c !important;
}

.hover\:text-base-dark:hover {
  color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base-darker {
  color: #2e2e2e !important;
}

.hover\:text-base-darker:hover {
  color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-base-darkest {
  color: #1b1b1b !important;
}

.hover\:text-base-darkest:hover {
  color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ink {
  color: #1b1b1b !important;
}

.hover\:text-ink:hover {
  color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-primary-lighter {
  color: #d9e8f6 !important;
}

.hover\:text-primary-lighter:hover {
  color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-primary-light {
  color: #73b3e7 !important;
}

.hover\:text-primary-light:hover {
  color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-primary {
  color: #005ea2 !important;
}

.hover\:text-primary:hover {
  color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-primary-vivid {
  color: #0050d8 !important;
}

.hover\:text-primary-vivid:hover {
  color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-primary-dark {
  color: #1a4480 !important;
}

.hover\:text-primary-dark:hover {
  color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-primary-darker {
  color: #162e51 !important;
}

.hover\:text-primary-darker:hover {
  color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-secondary-lighter {
  color: #f3e1e4 !important;
}

.hover\:text-secondary-lighter:hover {
  color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-secondary-light {
  color: #f2938c !important;
}

.hover\:text-secondary-light:hover {
  color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-secondary {
  color: #d83933 !important;
}

.hover\:text-secondary:hover {
  color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-secondary-vivid {
  color: #e41d3d !important;
}

.hover\:text-secondary-vivid:hover {
  color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-secondary-dark {
  color: #b50909 !important;
}

.hover\:text-secondary-dark:hover {
  color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-secondary-darker {
  color: #8b0a03 !important;
}

.hover\:text-secondary-darker:hover {
  color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-warm-darker {
  color: #775540 !important;
}

.hover\:text-accent-warm-darker:hover {
  color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-warm-dark {
  color: #c05600 !important;
}

.hover\:text-accent-warm-dark:hover {
  color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-warm {
  color: #fa9441 !important;
}

.hover\:text-accent-warm:hover {
  color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-warm-light {
  color: #ffbc78 !important;
}

.hover\:text-accent-warm-light:hover {
  color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-warm-lighter {
  color: #f2e4d4 !important;
}

.hover\:text-accent-warm-lighter:hover {
  color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-cool-darker {
  color: #07648d !important;
}

.hover\:text-accent-cool-darker:hover {
  color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-cool-dark {
  color: #28a0cb !important;
}

.hover\:text-accent-cool-dark:hover {
  color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-cool {
  color: #00bde3 !important;
}

.hover\:text-accent-cool:hover {
  color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-cool-light {
  color: #97d4ea !important;
}

.hover\:text-accent-cool-light:hover {
  color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-accent-cool-lighter {
  color: #e1f3f8 !important;
}

.hover\:text-accent-cool-lighter:hover {
  color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-error-lighter {
  color: #f4e3db !important;
}

.hover\:text-error-lighter:hover {
  color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-error-light {
  color: #f39268 !important;
}

.hover\:text-error-light:hover {
  color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-error {
  color: #d54309 !important;
}

.hover\:text-error:hover {
  color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-error-dark {
  color: #b50909 !important;
}

.hover\:text-error-dark:hover {
  color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-error-darker {
  color: #6f3331 !important;
}

.hover\:text-error-darker:hover {
  color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-warning-lighter {
  color: #faf3d1 !important;
}

.hover\:text-warning-lighter:hover {
  color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-warning-light {
  color: #fee685 !important;
}

.hover\:text-warning-light:hover {
  color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-warning {
  color: #ffbe2e !important;
}

.hover\:text-warning:hover {
  color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-warning-dark {
  color: #e5a000 !important;
}

.hover\:text-warning-dark:hover {
  color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-warning-darker {
  color: #936f38 !important;
}

.hover\:text-warning-darker:hover {
  color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-success-lighter {
  color: #ecf3ec !important;
}

.hover\:text-success-lighter:hover {
  color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-success-light {
  color: #70e17b !important;
}

.hover\:text-success-light:hover {
  color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-success {
  color: #00a91c !important;
}

.hover\:text-success:hover {
  color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-success-dark {
  color: #4d8055 !important;
}

.hover\:text-success-dark:hover {
  color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-success-darker {
  color: #446443 !important;
}

.hover\:text-success-darker:hover {
  color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-info-lighter {
  color: #e7f6f8 !important;
}

.hover\:text-info-lighter:hover {
  color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-info-light {
  color: #99deea !important;
}

.hover\:text-info-light:hover {
  color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-info {
  color: #00bde3 !important;
}

.hover\:text-info:hover {
  color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-info-dark {
  color: #009ec1 !important;
}

.hover\:text-info-dark:hover {
  color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-info-darker {
  color: #2e6276 !important;
}

.hover\:text-info-darker:hover {
  color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-disabled-light {
  color: #e6e6e6 !important;
}

.hover\:text-disabled-light:hover {
  color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-disabled {
  color: #c9c9c9 !important;
}

.hover\:text-disabled:hover {
  color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-disabled-dark {
  color: #adadad !important;
}

.hover\:text-disabled-dark:hover {
  color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-emergency {
  color: #9c3d10 !important;
}

.hover\:text-emergency:hover {
  color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-emergency-dark {
  color: #332d29 !important;
}

.hover\:text-emergency-dark:hover {
  color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base-lightest {
  color: #f0f0f0 !important;
}

.hover\:text-default-base-lightest:hover {
  color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base-lighter {
  color: #dcdee0 !important;
}

.hover\:text-default-base-lighter:hover {
  color: #dcdee0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base-light {
  color: #a9aeb1 !important;
}

.hover\:text-default-base-light:hover {
  color: #a9aeb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base {
  color: #71767a !important;
}

.hover\:text-default-base:hover {
  color: #71767a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base-dark {
  color: #565c65 !important;
}

.hover\:text-default-base-dark:hover {
  color: #565c65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base-darker {
  color: #3d4551 !important;
}

.hover\:text-default-base-darker:hover {
  color: #3d4551 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-base-darkest {
  color: #1b1b1b !important;
}

.hover\:text-default-base-darkest:hover {
  color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-ink {
  color: #1b1b1b !important;
}

.hover\:text-default-ink:hover {
  color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-primary-lighter {
  color: #d9e8f6 !important;
}

.hover\:text-default-primary-lighter:hover {
  color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-primary-light {
  color: #73b3e7 !important;
}

.hover\:text-default-primary-light:hover {
  color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-primary {
  color: #005ea2 !important;
}

.hover\:text-default-primary:hover {
  color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-primary-vivid {
  color: #0050d8 !important;
}

.hover\:text-default-primary-vivid:hover {
  color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-primary-dark {
  color: #1a4480 !important;
}

.hover\:text-default-primary-dark:hover {
  color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-primary-darker {
  color: #162e51 !important;
}

.hover\:text-default-primary-darker:hover {
  color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-secondary-lighter {
  color: #f8dfe2 !important;
}

.hover\:text-default-secondary-lighter:hover {
  color: #f8dfe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-secondary-light {
  color: #f2938c !important;
}

.hover\:text-default-secondary-light:hover {
  color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-secondary {
  color: #d83933 !important;
}

.hover\:text-default-secondary:hover {
  color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-secondary-vivid {
  color: #e41d3d !important;
}

.hover\:text-default-secondary-vivid:hover {
  color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-secondary-dark {
  color: #b51d09 !important;
}

.hover\:text-default-secondary-dark:hover {
  color: #b51d09 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-secondary-darker {
  color: #8b1303 !important;
}

.hover\:text-default-secondary-darker:hover {
  color: #8b1303 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-warm-lighter {
  color: #f2e4d4 !important;
}

.hover\:text-default-accent-warm-lighter:hover {
  color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-warm-light {
  color: #ffbc78 !important;
}

.hover\:text-default-accent-warm-light:hover {
  color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-warm {
  color: #fa9441 !important;
}

.hover\:text-default-accent-warm:hover {
  color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-warm-dark {
  color: #c05600 !important;
}

.hover\:text-default-accent-warm-dark:hover {
  color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-warm-darker {
  color: #775540 !important;
}

.hover\:text-default-accent-warm-darker:hover {
  color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-cool-lighter {
  color: #e1f3f8 !important;
}

.hover\:text-default-accent-cool-lighter:hover {
  color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-cool-light {
  color: #97d4ea !important;
}

.hover\:text-default-accent-cool-light:hover {
  color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-cool {
  color: #00bde3 !important;
}

.hover\:text-default-accent-cool:hover {
  color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-cool-dark {
  color: #28a0cb !important;
}

.hover\:text-default-accent-cool-dark:hover {
  color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-default-accent-cool-darker {
  color: #07648d !important;
}

.hover\:text-default-accent-cool-darker:hover {
  color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.cursor-auto {
  cursor: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.cursor-default {
  cursor: default !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.cursor-pointer {
  cursor: pointer !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.cursor-wait {
  cursor: wait !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.cursor-move {
  cursor: move !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.cursor-not-allowed {
  cursor: not-allowed !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-block {
  display: block !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-flex {
  display: flex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-none {
  display: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-inline {
  display: inline !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-inline-block {
  display: inline-block !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-inline-flex {
  display: inline-flex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-table {
  display: table !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-table-cell {
  display: table-cell !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.display-table-row {
  display: table-row !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-1 {
  flex: 1 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-2 {
  flex: 2 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-3 {
  flex: 3 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-4 {
  flex: 4 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-5 {
  flex: 5 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-6 {
  flex: 6 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-7 {
  flex: 7 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-8 {
  flex: 8 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-9 {
  flex: 9 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-10 {
  flex: 10 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-11 {
  flex: 11 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-12 {
  flex: 12 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-fill {
  flex: 1 1 0% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-auto {
  flex: 0 1 auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-row {
  flex-direction: row !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-column {
  flex-direction: column !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-wrap {
  flex-wrap: wrap !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-no-wrap {
  flex-wrap: nowrap !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.float-left {
  float: left !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.float-none {
  float: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.float-right {
  float: right !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-3xs {
  font-size: 0.81rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-2xs {
  font-size: 0.88rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-xs {
  font-size: 0.94rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-sm {
  font-size: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-md {
  font-size: 1.06rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-lg {
  font-size: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-xl {
  font-size: 1.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-2xl {
  font-size: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-3xl {
  font-size: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-3xs {
  font-size: 0.77rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-2xs {
  font-size: 0.83rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-xs {
  font-size: 0.89rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-sm {
  font-size: 0.95rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-md {
  font-size: 1.01rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-lg {
  font-size: 1.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-xl {
  font-size: 1.67rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-2xl {
  font-size: 2.38rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-3xl {
  font-size: 2.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-3xs {
  font-size: 0.87rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-2xs {
  font-size: 0.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-xs {
  font-size: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-sm {
  font-size: 1.06rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-md {
  font-size: 1.13rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-lg {
  font-size: 1.33rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-xl {
  font-size: 1.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-2xl {
  font-size: 2.66rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-3xl {
  font-size: 3.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-3xs {
  font-size: 0.79rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-2xs {
  font-size: 0.85rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-xs {
  font-size: 0.91rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-sm {
  font-size: 0.98rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-md {
  font-size: 1.04rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-lg {
  font-size: 1.22rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-xl {
  font-size: 1.71rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-2xl {
  font-size: 2.44rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-3xl {
  font-size: 2.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-3xs {
  font-size: 0.79rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-2xs {
  font-size: 0.85rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-xs {
  font-size: 0.91rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-sm {
  font-size: 0.98rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-md {
  font-size: 1.04rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-lg {
  font-size: 1.22rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-xl {
  font-size: 1.71rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-2xl {
  font-size: 2.44rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-heading-3xl {
  font-size: 2.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-3xs {
  font-size: 0.87rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-2xs {
  font-size: 0.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-xs {
  font-size: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-sm {
  font-size: 1.06rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-md {
  font-size: 1.13rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-lg {
  font-size: 1.33rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-xl {
  font-size: 1.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-2xl {
  font-size: 2.66rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-body-3xl {
  font-size: 3.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-3xs {
  font-size: 0.77rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-2xs {
  font-size: 0.83rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-xs {
  font-size: 0.89rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-sm {
  font-size: 0.95rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-md {
  font-size: 1.01rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-lg {
  font-size: 1.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-xl {
  font-size: 1.67rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-2xl {
  font-size: 2.38rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-code-3xl {
  font-size: 2.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-3xs {
  font-size: 0.79rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-2xs {
  font-size: 0.85rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-xs {
  font-size: 0.91rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-sm {
  font-size: 0.98rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-md {
  font-size: 1.04rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-lg {
  font-size: 1.22rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-xl {
  font-size: 1.71rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-2xl {
  font-size: 2.44rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-alt-3xl {
  font-size: 2.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-3xs {
  font-size: 0.87rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-2xs {
  font-size: 0.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-xs {
  font-size: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-sm {
  font-size: 1.06rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-md {
  font-size: 1.13rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-lg {
  font-size: 1.33rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-xl {
  font-size: 1.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-2xl {
  font-size: 2.66rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-ui-3xl {
  font-size: 3.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-micro {
  font-size: 0.63rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-1 {
  font-size: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-2 {
  font-size: 0.81rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-3 {
  font-size: 0.88rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-4 {
  font-size: 0.94rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-5 {
  font-size: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-6 {
  font-size: 1.06rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-7 {
  font-size: 1.13rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-8 {
  font-size: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-9 {
  font-size: 1.38rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-10 {
  font-size: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-11 {
  font-size: 1.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-12 {
  font-size: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-13 {
  font-size: 2.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-14 {
  font-size: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-15 {
  font-size: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-16 {
  font-size: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-17 {
  font-size: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-18 {
  font-size: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-19 {
  font-size: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-lang-20 {
  font-size: 8.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-micro {
  font-size: 0.6rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-1 {
  font-size: 0.71rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-2 {
  font-size: 0.77rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-3 {
  font-size: 0.83rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-4 {
  font-size: 0.89rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-5 {
  font-size: 0.95rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-6 {
  font-size: 1.01rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-7 {
  font-size: 1.07rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-8 {
  font-size: 1.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-9 {
  font-size: 1.31rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-10 {
  font-size: 1.43rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-11 {
  font-size: 1.67rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-12 {
  font-size: 1.91rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-13 {
  font-size: 2.14rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-14 {
  font-size: 2.38rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-15 {
  font-size: 2.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-16 {
  font-size: 3.33rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-17 {
  font-size: 3.81rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-18 {
  font-size: 4.76rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-19 {
  font-size: 7.14rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-mono-20 {
  font-size: 8.34rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-micro {
  font-size: 0.67rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-1 {
  font-size: 0.8rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-2 {
  font-size: 0.87rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-3 {
  font-size: 0.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-4 {
  font-size: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-5 {
  font-size: 1.06rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-6 {
  font-size: 1.13rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-7 {
  font-size: 1.2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-8 {
  font-size: 1.33rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-9 {
  font-size: 1.46rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-10 {
  font-size: 1.6rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-11 {
  font-size: 1.86rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-12 {
  font-size: 2.13rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-13 {
  font-size: 2.4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-14 {
  font-size: 2.66rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-15 {
  font-size: 3.19rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-16 {
  font-size: 3.73rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-17 {
  font-size: 4.26rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-18 {
  font-size: 5.32rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-19 {
  font-size: 7.99rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-sans-20 {
  font-size: 9.32rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-micro {
  font-size: 0.61rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-1 {
  font-size: 0.73rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-2 {
  font-size: 0.79rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-3 {
  font-size: 0.85rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-4 {
  font-size: 0.91rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-5 {
  font-size: 0.98rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-6 {
  font-size: 1.04rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-7 {
  font-size: 1.1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-8 {
  font-size: 1.22rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-9 {
  font-size: 1.34rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-10 {
  font-size: 1.46rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-11 {
  font-size: 1.71rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-12 {
  font-size: 1.95rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-13 {
  font-size: 2.2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-14 {
  font-size: 2.44rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-15 {
  font-size: 2.93rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-16 {
  font-size: 3.42rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-17 {
  font-size: 3.9rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-18 {
  font-size: 4.88rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-19 {
  font-size: 7.32rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-serif-20 {
  font-size: 8.54rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-lang {
  font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-mono {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-sans {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-serif {
  font-family: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-ui {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-heading {
  font-family: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-body {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-code {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.font-family-alt {
  font-family: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-tabular {
  font-feature-settings: "tnum" 1, "kern" 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-tabular {
  font-feature-settings: "kern" 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-italic {
  font-style: italic !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-italic {
  font-style: normal !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-thin {
  font-weight: 200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-light {
  font-weight: 300 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-normal {
  font-weight: normal !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-medium {
  font-weight: 500 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-semibold {
  font-weight: 600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-bold {
  font-weight: 700 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-heavy {
  font-weight: 800 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-100 {
  font-weight: 100 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-200 {
  font-weight: 200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-300 {
  font-weight: 300 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-400 {
  font-weight: 400 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-500 {
  font-weight: 500 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-600 {
  font-weight: 600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-700 {
  font-weight: 700 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-800 {
  font-weight: 800 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-900 {
  font-weight: 900 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-1px {
  height: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-2px {
  height: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-05 {
  height: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-1 {
  height: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-105 {
  height: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-2 {
  height: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-205 {
  height: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-3 {
  height: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-4 {
  height: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-5 {
  height: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-6 {
  height: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-7 {
  height: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-8 {
  height: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-9 {
  height: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-10 {
  height: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-15 {
  height: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-card {
  height: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-card-lg {
  height: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-mobile {
  height: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-0 {
  height: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-auto {
  height: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-full {
  height: 100% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.height-viewport {
  height: 100vh !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-justify-center {
  justify-content: center !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-justify-start {
  justify-content: flex-start !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-justify-end {
  justify-content: flex-end !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.flex-justify {
  justify-content: space-between !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-1px {
  left: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-2px {
  left: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-05 {
  left: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-1 {
  left: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-105 {
  left: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-2 {
  left: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-205 {
  left: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-3 {
  left: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-1px {
  left: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-2px {
  left: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-05 {
  left: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-1 {
  left: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-105 {
  left: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-2 {
  left: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-205 {
  left: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-neg-3 {
  left: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-0 {
  left: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.left-auto {
  left: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-auto {
  letter-spacing: initial !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-neg-3 {
  letter-spacing: -0.03em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-neg-2 {
  letter-spacing: -0.02em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-neg-1 {
  letter-spacing: -0.01em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-1 {
  letter-spacing: 0.025em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-2 {
  letter-spacing: 0.1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ls-3 {
  letter-spacing: 0.15em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-sans-1 {
  line-height: 0.9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-sans-2 {
  line-height: 1.1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-sans-3 {
  line-height: 1.3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-sans-4 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-sans-5 {
  line-height: 1.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-sans-6 {
  line-height: 1.6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-serif-1 {
  line-height: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-serif-2 {
  line-height: 1.2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-serif-3 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-serif-4 {
  line-height: 1.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-serif-5 {
  line-height: 1.7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-serif-6 {
  line-height: 1.8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-mono-1 {
  line-height: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-mono-2 {
  line-height: 1.3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-mono-3 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-mono-4 {
  line-height: 1.6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-mono-5 {
  line-height: 1.7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-mono-6 {
  line-height: 1.8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-heading-1 {
  line-height: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-heading-2 {
  line-height: 1.2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-heading-3 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-heading-4 {
  line-height: 1.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-heading-5 {
  line-height: 1.7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-heading-6 {
  line-height: 1.8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-ui-1 {
  line-height: 0.9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-ui-2 {
  line-height: 1.1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-ui-3 {
  line-height: 1.3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-ui-4 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-ui-5 {
  line-height: 1.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-ui-6 {
  line-height: 1.6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-body-1 {
  line-height: 0.9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-body-2 {
  line-height: 1.1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-body-3 {
  line-height: 1.3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-body-4 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-body-5 {
  line-height: 1.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-body-6 {
  line-height: 1.6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-code-1 {
  line-height: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-code-2 {
  line-height: 1.3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-code-3 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-code-4 {
  line-height: 1.6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-code-5 {
  line-height: 1.7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-code-6 {
  line-height: 1.8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-alt-1 {
  line-height: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-alt-2 {
  line-height: 1.2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-alt-3 {
  line-height: 1.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-alt-4 {
  line-height: 1.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-alt-5 {
  line-height: 1.7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.line-height-alt-6 {
  line-height: 1.8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-1px {
  margin: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-2px {
  margin: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-05 {
  margin: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-1 {
  margin: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-105 {
  margin: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-2 {
  margin: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-205 {
  margin: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-3 {
  margin: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-1px {
  margin: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-2px {
  margin: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-05 {
  margin: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-1 {
  margin: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-105 {
  margin: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-2 {
  margin: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-205 {
  margin: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-3 {
  margin: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-4 {
  margin: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-5 {
  margin: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-6 {
  margin: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-7 {
  margin: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-8 {
  margin: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-9 {
  margin: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-10 {
  margin: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-neg-15 {
  margin: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-4 {
  margin: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-5 {
  margin: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-6 {
  margin: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-7 {
  margin: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-8 {
  margin: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-9 {
  margin: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-10 {
  margin: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-15 {
  margin: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-05em {
  margin: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-1em {
  margin: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-105em {
  margin: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-2em {
  margin: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-0 {
  margin: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-1px {
  margin-top: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-1px {
  margin-bottom: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-2px {
  margin-top: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-2px {
  margin-bottom: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-05 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-05 {
  margin-top: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-05 {
  margin-bottom: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-1 {
  margin-top: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-1 {
  margin-bottom: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-105 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-105 {
  margin-top: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-105 {
  margin-bottom: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-2 {
  margin-top: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-2 {
  margin-bottom: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-205 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-205 {
  margin-top: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-205 {
  margin-bottom: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-3 {
  margin-top: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-3 {
  margin-bottom: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-1px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-1px {
  margin-top: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-1px {
  margin-bottom: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-2px {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-2px {
  margin-top: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-2px {
  margin-bottom: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-05 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-05 {
  margin-top: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-05 {
  margin-bottom: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-1 {
  margin-top: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-1 {
  margin-bottom: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-105 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-105 {
  margin-top: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-105 {
  margin-bottom: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-2 {
  margin-top: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-2 {
  margin-bottom: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-205 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-205 {
  margin-top: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-205 {
  margin-bottom: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-3 {
  margin-top: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-3 {
  margin-bottom: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-4 {
  margin-top: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-4 {
  margin-bottom: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-5 {
  margin-top: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-5 {
  margin-bottom: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-6 {
  margin-top: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-6 {
  margin-bottom: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-7 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-7 {
  margin-top: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-7 {
  margin-bottom: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-8 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-8 {
  margin-top: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-8 {
  margin-bottom: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-9 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-9 {
  margin-top: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-9 {
  margin-bottom: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-10 {
  margin-top: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-10 {
  margin-bottom: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-neg-15 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-neg-15 {
  margin-top: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-neg-15 {
  margin-bottom: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-4 {
  margin-top: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-4 {
  margin-bottom: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-5 {
  margin-top: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-5 {
  margin-bottom: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-6 {
  margin-top: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-6 {
  margin-bottom: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-7 {
  margin-top: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-7 {
  margin-bottom: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-8 {
  margin-top: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-8 {
  margin-bottom: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-9 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-9 {
  margin-top: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-9 {
  margin-bottom: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-10 {
  margin-top: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-10 {
  margin-bottom: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-15 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-15 {
  margin-top: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-15 {
  margin-bottom: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-05em {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-05em {
  margin-top: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-05em {
  margin-bottom: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-1em {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-1em {
  margin-top: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-1em {
  margin-bottom: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-105em {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-105em {
  margin-top: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-105em {
  margin-bottom: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-2em {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-2em {
  margin-top: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-2em {
  margin-bottom: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-0 {
  margin-top: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-0 {
  margin-bottom: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-top-auto {
  margin-top: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-bottom-auto {
  margin-bottom: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-1px {
  margin-right: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-1px {
  margin-left: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-2px {
  margin-right: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-2px {
  margin-left: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-05 {
  margin-right: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-05 {
  margin-left: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-1 {
  margin-right: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-1 {
  margin-left: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-105 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-105 {
  margin-right: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-105 {
  margin-left: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-2 {
  margin-right: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-2 {
  margin-left: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-205 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-205 {
  margin-right: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-205 {
  margin-left: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-3 {
  margin-right: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-3 {
  margin-left: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-1px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-1px {
  margin-right: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-1px {
  margin-left: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-2px {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-2px {
  margin-right: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-2px {
  margin-left: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-05 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-05 {
  margin-right: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-05 {
  margin-left: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-1 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-1 {
  margin-right: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-1 {
  margin-left: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-105 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-105 {
  margin-right: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-105 {
  margin-left: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-2 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-2 {
  margin-right: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-2 {
  margin-left: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-205 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-205 {
  margin-right: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-205 {
  margin-left: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-3 {
  margin-right: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-3 {
  margin-left: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-4 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-4 {
  margin-right: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-4 {
  margin-left: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-5 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-5 {
  margin-right: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-5 {
  margin-left: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-6 {
  margin-right: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-6 {
  margin-left: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-7 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-7 {
  margin-right: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-7 {
  margin-left: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-8 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-8 {
  margin-right: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-8 {
  margin-left: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-9 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-9 {
  margin-right: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-9 {
  margin-left: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-10 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-10 {
  margin-right: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-10 {
  margin-left: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-neg-15 {
  margin-left: -7.5rem !important;
  margin-right: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-neg-15 {
  margin-right: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-neg-15 {
  margin-left: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-4 {
  margin-right: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-4 {
  margin-left: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-5 {
  margin-right: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-5 {
  margin-left: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-6 {
  margin-right: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-6 {
  margin-left: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-7 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-7 {
  margin-right: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-7 {
  margin-left: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-8 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-8 {
  margin-right: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-8 {
  margin-left: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-9 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-9 {
  margin-right: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-9 {
  margin-left: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-10 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-10 {
  margin-right: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-10 {
  margin-left: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-15 {
  margin-left: 7.5rem !important;
  margin-right: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-15 {
  margin-right: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-15 {
  margin-left: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-card {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-card {
  margin-right: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-card {
  margin-left: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-card-lg {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-card-lg {
  margin-right: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-card-lg {
  margin-left: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-mobile {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-mobile {
  margin-right: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-mobile {
  margin-left: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-05em {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-05em {
  margin-right: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-05em {
  margin-left: 0.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-1em {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-1em {
  margin-right: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-1em {
  margin-left: 1em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-105em {
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-105em {
  margin-right: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-105em {
  margin-left: 1.5em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-2em {
  margin-left: 2em !important;
  margin-right: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-2em {
  margin-right: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-2em {
  margin-left: 2em !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-0 {
  margin-right: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-0 {
  margin-left: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-right-auto {
  margin-right: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.margin-left-auto {
  margin-left: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-05 {
  max-height: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-1 {
  max-height: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-105 {
  max-height: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-2 {
  max-height: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-205 {
  max-height: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-3 {
  max-height: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-4 {
  max-height: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-5 {
  max-height: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-6 {
  max-height: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-7 {
  max-height: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-8 {
  max-height: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-9 {
  max-height: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-10 {
  max-height: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-15 {
  max-height: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-card {
  max-height: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-card-lg {
  max-height: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-mobile {
  max-height: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-mobile-lg {
  max-height: 30rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-tablet {
  max-height: 40rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-tablet-lg {
  max-height: 55rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-none {
  max-height: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxh-viewport {
  max-height: 100vh !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-05 {
  max-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-1 {
  max-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-105 {
  max-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-2 {
  max-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-205 {
  max-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-3 {
  max-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-4 {
  max-width: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-5 {
  max-width: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-6 {
  max-width: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-7 {
  max-width: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-8 {
  max-width: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-9 {
  max-width: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-10 {
  max-width: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-15 {
  max-width: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-card {
  max-width: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-card-lg {
  max-width: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-mobile {
  max-width: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-mobile-lg {
  max-width: 30rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-tablet {
  max-width: 40rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-tablet-lg {
  max-width: 55rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-desktop {
  max-width: 64rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-desktop-lg {
  max-width: 75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-widescreen {
  max-width: 87.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-none {
  max-width: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.maxw-full {
  max-width: 100% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-1 {
  max-width: 44ex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-2 {
  max-width: 60ex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-3 {
  max-width: 64ex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-4 {
  max-width: 68ex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-5 {
  max-width: 72ex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-6 {
  max-width: 88ex !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.measure-none {
  max-width: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-1px {
  min-height: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-2px {
  min-height: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-05 {
  min-height: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-1 {
  min-height: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-105 {
  min-height: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-2 {
  min-height: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-205 {
  min-height: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-3 {
  min-height: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-4 {
  min-height: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-5 {
  min-height: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-6 {
  min-height: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-7 {
  min-height: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-8 {
  min-height: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-9 {
  min-height: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-10 {
  min-height: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-15 {
  min-height: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-card {
  min-height: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-card-lg {
  min-height: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-mobile {
  min-height: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-mobile-lg {
  min-height: 30rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-tablet {
  min-height: 40rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-tablet-lg {
  min-height: 55rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-0 {
  min-height: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-full {
  min-height: 100% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minh-viewport {
  min-height: 100vh !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-05 {
  min-width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-1 {
  min-width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-105 {
  min-width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-2 {
  min-width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-205 {
  min-width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-3 {
  min-width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-4 {
  min-width: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-5 {
  min-width: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-6 {
  min-width: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-7 {
  min-width: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-8 {
  min-width: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-9 {
  min-width: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-10 {
  min-width: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-15 {
  min-width: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-0 {
  min-width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-10ct {
  min-width: 10% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-20ct {
  min-width: 20% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-25ct {
  min-width: 25% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-30ct {
  min-width: 30% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-33ct {
  min-width: 33.33333333% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-40ct {
  min-width: 40% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-50ct {
  min-width: 50% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-60ct {
  min-width: 60% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-66ct {
  min-width: 66.66666666% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-70ct {
  min-width: 70% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-75ct {
  min-width: 75% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-80ct {
  min-width: 80% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-90ct {
  min-width: 90% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.minw-100ct {
  min-width: 100% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-0 {
  opacity: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-10 {
  opacity: 0.1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-20 {
  opacity: 0.2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-30 {
  opacity: 0.3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-40 {
  opacity: 0.4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-50 {
  opacity: 0.5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-60 {
  opacity: 0.6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-70 {
  opacity: 0.7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-80 {
  opacity: 0.8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-90 {
  opacity: 0.9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.opacity-100 {
  opacity: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-first {
  order: -1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-last {
  order: 999 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-initial {
  order: initial !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-0 {
  order: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-1 {
  order: 1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-2 {
  order: 2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-3 {
  order: 3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-4 {
  order: 4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-5 {
  order: 5 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-6 {
  order: 6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-7 {
  order: 7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-8 {
  order: 8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-9 {
  order: 9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-10 {
  order: 10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.order-11 {
  order: 11 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-1px {
  outline: 1px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-2px {
  outline: 2px solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-0 {
  outline: 0 solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-05 {
  outline: 0.25rem solid !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-transparent {
  outline-color: transparent !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-black {
  outline-color: black !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-white {
  outline-color: white !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-red {
  outline-color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-orange {
  outline-color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gold {
  outline-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-yellow {
  outline-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-green {
  outline-color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-mint {
  outline-color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-cyan {
  outline-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-blue {
  outline-color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-indigo {
  outline-color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-violet {
  outline-color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-magenta {
  outline-color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gray-5 {
  outline-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gray-10 {
  outline-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gray-30 {
  outline-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gray-50 {
  outline-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gray-70 {
  outline-color: #454545 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-gray-90 {
  outline-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base-lightest {
  outline-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base-lighter {
  outline-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base-light {
  outline-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base {
  outline-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base-dark {
  outline-color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base-darker {
  outline-color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-base-darkest {
  outline-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-ink {
  outline-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-primary-lighter {
  outline-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-primary-light {
  outline-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-primary {
  outline-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-primary-vivid {
  outline-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-primary-dark {
  outline-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-primary-darker {
  outline-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-secondary-lighter {
  outline-color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-secondary-light {
  outline-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-secondary {
  outline-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-secondary-vivid {
  outline-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-secondary-dark {
  outline-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-secondary-darker {
  outline-color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-warm-darker {
  outline-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-warm-dark {
  outline-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-warm {
  outline-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-warm-light {
  outline-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-warm-lighter {
  outline-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-cool-darker {
  outline-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-cool-dark {
  outline-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-cool {
  outline-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-cool-light {
  outline-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-accent-cool-lighter {
  outline-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-error-lighter {
  outline-color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-error-light {
  outline-color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-error {
  outline-color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-error-dark {
  outline-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-error-darker {
  outline-color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-warning-lighter {
  outline-color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-warning-light {
  outline-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-warning {
  outline-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-warning-dark {
  outline-color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-warning-darker {
  outline-color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-success-lighter {
  outline-color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-success-light {
  outline-color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-success {
  outline-color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-success-dark {
  outline-color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-success-darker {
  outline-color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-info-lighter {
  outline-color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-info-light {
  outline-color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-info {
  outline-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-info-dark {
  outline-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-info-darker {
  outline-color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-disabled-light {
  outline-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-disabled {
  outline-color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-disabled-dark {
  outline-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-emergency {
  outline-color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.outline-emergency-dark {
  outline-color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-hidden {
  overflow: hidden !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-y-hidden {
  overflow-y: hidden !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-x-hidden {
  overflow-x: hidden !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-scroll {
  overflow: scroll !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-y-scroll {
  overflow-y: scroll !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-x-scroll {
  overflow-x: scroll !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-auto {
  overflow: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-y-auto {
  overflow-y: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-x-auto {
  overflow-x: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-visible {
  overflow: visible !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-y-visible {
  overflow-y: visible !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.overflow-x-visible {
  overflow-x: visible !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-1px {
  padding: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-1px {
  padding-top: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-1px {
  padding-right: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-1px {
  padding-bottom: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-1px {
  padding-left: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-2px {
  padding: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-2px {
  padding-top: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-2px {
  padding-right: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-2px {
  padding-bottom: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-2px {
  padding-left: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-05 {
  padding: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-05 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-05 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-05 {
  padding-top: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-05 {
  padding-right: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-05 {
  padding-bottom: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-05 {
  padding-left: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-1 {
  padding: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-1 {
  padding-top: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-1 {
  padding-right: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-1 {
  padding-bottom: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-1 {
  padding-left: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-105 {
  padding: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-105 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-105 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-105 {
  padding-top: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-105 {
  padding-right: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-105 {
  padding-bottom: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-105 {
  padding-left: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-2 {
  padding: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-2 {
  padding-top: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-2 {
  padding-right: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-2 {
  padding-bottom: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-2 {
  padding-left: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-205 {
  padding: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-205 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-205 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-205 {
  padding-top: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-205 {
  padding-right: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-205 {
  padding-bottom: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-205 {
  padding-left: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-3 {
  padding: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-3 {
  padding-top: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-3 {
  padding-right: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-3 {
  padding-bottom: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-3 {
  padding-left: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-4 {
  padding: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-4 {
  padding-top: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-4 {
  padding-right: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-4 {
  padding-bottom: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-4 {
  padding-left: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-5 {
  padding: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-5 {
  padding-top: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-5 {
  padding-right: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-5 {
  padding-bottom: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-5 {
  padding-left: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-6 {
  padding: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-6 {
  padding-top: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-6 {
  padding-right: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-6 {
  padding-bottom: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-6 {
  padding-left: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-7 {
  padding: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-7 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-7 {
  padding-top: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-7 {
  padding-right: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-7 {
  padding-bottom: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-7 {
  padding-left: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-8 {
  padding: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-8 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-8 {
  padding-top: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-8 {
  padding-right: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-8 {
  padding-bottom: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-8 {
  padding-left: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-9 {
  padding: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-9 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-9 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-9 {
  padding-top: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-9 {
  padding-right: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-9 {
  padding-bottom: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-9 {
  padding-left: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-10 {
  padding: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-10 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-10 {
  padding-top: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-10 {
  padding-right: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-10 {
  padding-bottom: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-10 {
  padding-left: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-15 {
  padding: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-15 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-15 {
  padding-left: 7.5rem !important;
  padding-right: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-15 {
  padding-top: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-15 {
  padding-right: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-15 {
  padding-bottom: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-15 {
  padding-left: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-0 {
  padding: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-top-0 {
  padding-top: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-right-0 {
  padding-right: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-bottom-0 {
  padding-bottom: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.padding-left-0 {
  padding-left: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-all {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-x {
  left: 0 !important;
  right: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-y {
  bottom: 0 !important;
  top: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-bottom {
  bottom: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-left {
  left: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-right {
  right: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-top {
  top: 0 !important;
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.pin-none {
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  position: static !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.position-absolute {
  position: absolute !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.position-fixed {
  position: fixed !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.position-relative {
  position: relative !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.position-static {
  position: static !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.position-sticky {
  position: sticky !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-1px {
  right: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-2px {
  right: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-05 {
  right: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-1 {
  right: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-105 {
  right: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-2 {
  right: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-205 {
  right: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-3 {
  right: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-1px {
  right: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-2px {
  right: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-05 {
  right: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-1 {
  right: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-105 {
  right: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-2 {
  right: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-205 {
  right: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-neg-3 {
  right: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-0 {
  right: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.right-auto {
  right: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-1px {
  height: 1px !important;
  width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-2px {
  height: 2px !important;
  width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-05 {
  height: 0.25rem !important;
  width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-1 {
  height: 0.5rem !important;
  width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-105 {
  height: 0.75rem !important;
  width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-2 {
  height: 1rem !important;
  width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-205 {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-3 {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-4 {
  height: 2rem !important;
  width: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-5 {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-6 {
  height: 3rem !important;
  width: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-7 {
  height: 3.5rem !important;
  width: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-8 {
  height: 4rem !important;
  width: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-9 {
  height: 4.5rem !important;
  width: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-10 {
  height: 5rem !important;
  width: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-15 {
  height: 7.5rem !important;
  width: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-card {
  height: 10rem !important;
  width: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-card-lg {
  height: 15rem !important;
  width: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.square-mobile {
  height: 20rem !important;
  width: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-center {
  text-align: center !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-left {
  text-align: left !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-justify {
  text-align: justify !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-right {
  text-align: right !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-strike {
  text-decoration: line-through !important;
}

.hover\:text-strike:hover {
  text-decoration: line-through !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-underline {
  text-decoration: underline !important;
}

.hover\:text-underline:hover {
  text-decoration: underline !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-underline {
  text-decoration: none !important;
}

.hover\:text-no-underline:hover {
  text-decoration: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-strike {
  text-decoration: none !important;
}

.hover\:text-no-strike:hover {
  text-decoration: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-red {
  text-decoration-color: #e52207 !important;
}

.hover\:underline-red:hover {
  text-decoration-color: #e52207 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-orange {
  text-decoration-color: #e66f0e !important;
}

.hover\:underline-orange:hover {
  text-decoration-color: #e66f0e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gold {
  text-decoration-color: #ffbe2e !important;
}

.hover\:underline-gold:hover {
  text-decoration-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-yellow {
  text-decoration-color: #fee685 !important;
}

.hover\:underline-yellow:hover {
  text-decoration-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-green {
  text-decoration-color: #538200 !important;
}

.hover\:underline-green:hover {
  text-decoration-color: #538200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-mint {
  text-decoration-color: #04c585 !important;
}

.hover\:underline-mint:hover {
  text-decoration-color: #04c585 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-cyan {
  text-decoration-color: #009ec1 !important;
}

.hover\:underline-cyan:hover {
  text-decoration-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-blue {
  text-decoration-color: #0076d6 !important;
}

.hover\:underline-blue:hover {
  text-decoration-color: #0076d6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-indigo {
  text-decoration-color: #676cc8 !important;
}

.hover\:underline-indigo:hover {
  text-decoration-color: #676cc8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-violet {
  text-decoration-color: #8168b3 !important;
}

.hover\:underline-violet:hover {
  text-decoration-color: #8168b3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-magenta {
  text-decoration-color: #d72d79 !important;
}

.hover\:underline-magenta:hover {
  text-decoration-color: #d72d79 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gray-5 {
  text-decoration-color: #f0f0f0 !important;
}

.hover\:underline-gray-5:hover {
  text-decoration-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gray-10 {
  text-decoration-color: #e6e6e6 !important;
}

.hover\:underline-gray-10:hover {
  text-decoration-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gray-30 {
  text-decoration-color: #adadad !important;
}

.hover\:underline-gray-30:hover {
  text-decoration-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gray-50 {
  text-decoration-color: #757575 !important;
}

.hover\:underline-gray-50:hover {
  text-decoration-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gray-70 {
  text-decoration-color: #454545 !important;
}

.hover\:underline-gray-70:hover {
  text-decoration-color: #454545 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-gray-90 {
  text-decoration-color: #1b1b1b !important;
}

.hover\:underline-gray-90:hover {
  text-decoration-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base-lightest {
  text-decoration-color: #f0f0f0 !important;
}

.hover\:underline-base-lightest:hover {
  text-decoration-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base-lighter {
  text-decoration-color: #e6e6e6 !important;
}

.hover\:underline-base-lighter:hover {
  text-decoration-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base-light {
  text-decoration-color: #adadad !important;
}

.hover\:underline-base-light:hover {
  text-decoration-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base {
  text-decoration-color: #757575 !important;
}

.hover\:underline-base:hover {
  text-decoration-color: #757575 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base-dark {
  text-decoration-color: #5c5c5c !important;
}

.hover\:underline-base-dark:hover {
  text-decoration-color: #5c5c5c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base-darker {
  text-decoration-color: #2e2e2e !important;
}

.hover\:underline-base-darker:hover {
  text-decoration-color: #2e2e2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-base-darkest {
  text-decoration-color: #1b1b1b !important;
}

.hover\:underline-base-darkest:hover {
  text-decoration-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-ink {
  text-decoration-color: #1b1b1b !important;
}

.hover\:underline-ink:hover {
  text-decoration-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-primary-lighter {
  text-decoration-color: #d9e8f6 !important;
}

.hover\:underline-primary-lighter:hover {
  text-decoration-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-primary-light {
  text-decoration-color: #73b3e7 !important;
}

.hover\:underline-primary-light:hover {
  text-decoration-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-primary {
  text-decoration-color: #005ea2 !important;
}

.hover\:underline-primary:hover {
  text-decoration-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-primary-vivid {
  text-decoration-color: #0050d8 !important;
}

.hover\:underline-primary-vivid:hover {
  text-decoration-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-primary-dark {
  text-decoration-color: #1a4480 !important;
}

.hover\:underline-primary-dark:hover {
  text-decoration-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-primary-darker {
  text-decoration-color: #162e51 !important;
}

.hover\:underline-primary-darker:hover {
  text-decoration-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-secondary-lighter {
  text-decoration-color: #f3e1e4 !important;
}

.hover\:underline-secondary-lighter:hover {
  text-decoration-color: #f3e1e4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-secondary-light {
  text-decoration-color: #f2938c !important;
}

.hover\:underline-secondary-light:hover {
  text-decoration-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-secondary {
  text-decoration-color: #d83933 !important;
}

.hover\:underline-secondary:hover {
  text-decoration-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-secondary-vivid {
  text-decoration-color: #e41d3d !important;
}

.hover\:underline-secondary-vivid:hover {
  text-decoration-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-secondary-dark {
  text-decoration-color: #b50909 !important;
}

.hover\:underline-secondary-dark:hover {
  text-decoration-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-secondary-darker {
  text-decoration-color: #8b0a03 !important;
}

.hover\:underline-secondary-darker:hover {
  text-decoration-color: #8b0a03 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-warm-darker {
  text-decoration-color: #775540 !important;
}

.hover\:underline-accent-warm-darker:hover {
  text-decoration-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-warm-dark {
  text-decoration-color: #c05600 !important;
}

.hover\:underline-accent-warm-dark:hover {
  text-decoration-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-warm {
  text-decoration-color: #fa9441 !important;
}

.hover\:underline-accent-warm:hover {
  text-decoration-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-warm-light {
  text-decoration-color: #ffbc78 !important;
}

.hover\:underline-accent-warm-light:hover {
  text-decoration-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-warm-lighter {
  text-decoration-color: #f2e4d4 !important;
}

.hover\:underline-accent-warm-lighter:hover {
  text-decoration-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-cool-darker {
  text-decoration-color: #07648d !important;
}

.hover\:underline-accent-cool-darker:hover {
  text-decoration-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-cool-dark {
  text-decoration-color: #28a0cb !important;
}

.hover\:underline-accent-cool-dark:hover {
  text-decoration-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-cool {
  text-decoration-color: #00bde3 !important;
}

.hover\:underline-accent-cool:hover {
  text-decoration-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-cool-light {
  text-decoration-color: #97d4ea !important;
}

.hover\:underline-accent-cool-light:hover {
  text-decoration-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-accent-cool-lighter {
  text-decoration-color: #e1f3f8 !important;
}

.hover\:underline-accent-cool-lighter:hover {
  text-decoration-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-error-lighter {
  text-decoration-color: #f4e3db !important;
}

.hover\:underline-error-lighter:hover {
  text-decoration-color: #f4e3db !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-error-light {
  text-decoration-color: #f39268 !important;
}

.hover\:underline-error-light:hover {
  text-decoration-color: #f39268 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-error {
  text-decoration-color: #d54309 !important;
}

.hover\:underline-error:hover {
  text-decoration-color: #d54309 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-error-dark {
  text-decoration-color: #b50909 !important;
}

.hover\:underline-error-dark:hover {
  text-decoration-color: #b50909 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-error-darker {
  text-decoration-color: #6f3331 !important;
}

.hover\:underline-error-darker:hover {
  text-decoration-color: #6f3331 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-warning-lighter {
  text-decoration-color: #faf3d1 !important;
}

.hover\:underline-warning-lighter:hover {
  text-decoration-color: #faf3d1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-warning-light {
  text-decoration-color: #fee685 !important;
}

.hover\:underline-warning-light:hover {
  text-decoration-color: #fee685 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-warning {
  text-decoration-color: #ffbe2e !important;
}

.hover\:underline-warning:hover {
  text-decoration-color: #ffbe2e !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-warning-dark {
  text-decoration-color: #e5a000 !important;
}

.hover\:underline-warning-dark:hover {
  text-decoration-color: #e5a000 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-warning-darker {
  text-decoration-color: #936f38 !important;
}

.hover\:underline-warning-darker:hover {
  text-decoration-color: #936f38 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-success-lighter {
  text-decoration-color: #ecf3ec !important;
}

.hover\:underline-success-lighter:hover {
  text-decoration-color: #ecf3ec !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-success-light {
  text-decoration-color: #70e17b !important;
}

.hover\:underline-success-light:hover {
  text-decoration-color: #70e17b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-success {
  text-decoration-color: #00a91c !important;
}

.hover\:underline-success:hover {
  text-decoration-color: #00a91c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-success-dark {
  text-decoration-color: #4d8055 !important;
}

.hover\:underline-success-dark:hover {
  text-decoration-color: #4d8055 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-success-darker {
  text-decoration-color: #446443 !important;
}

.hover\:underline-success-darker:hover {
  text-decoration-color: #446443 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-info-lighter {
  text-decoration-color: #e7f6f8 !important;
}

.hover\:underline-info-lighter:hover {
  text-decoration-color: #e7f6f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-info-light {
  text-decoration-color: #99deea !important;
}

.hover\:underline-info-light:hover {
  text-decoration-color: #99deea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-info {
  text-decoration-color: #00bde3 !important;
}

.hover\:underline-info:hover {
  text-decoration-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-info-dark {
  text-decoration-color: #009ec1 !important;
}

.hover\:underline-info-dark:hover {
  text-decoration-color: #009ec1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-info-darker {
  text-decoration-color: #2e6276 !important;
}

.hover\:underline-info-darker:hover {
  text-decoration-color: #2e6276 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-disabled-light {
  text-decoration-color: #e6e6e6 !important;
}

.hover\:underline-disabled-light:hover {
  text-decoration-color: #e6e6e6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-disabled {
  text-decoration-color: #c9c9c9 !important;
}

.hover\:underline-disabled:hover {
  text-decoration-color: #c9c9c9 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-disabled-dark {
  text-decoration-color: #adadad !important;
}

.hover\:underline-disabled-dark:hover {
  text-decoration-color: #adadad !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-emergency {
  text-decoration-color: #9c3d10 !important;
}

.hover\:underline-emergency:hover {
  text-decoration-color: #9c3d10 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-emergency-dark {
  text-decoration-color: #332d29 !important;
}

.hover\:underline-emergency-dark:hover {
  text-decoration-color: #332d29 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base-lightest {
  text-decoration-color: #f0f0f0 !important;
}

.hover\:underline-default-base-lightest:hover {
  text-decoration-color: #f0f0f0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base-lighter {
  text-decoration-color: #dcdee0 !important;
}

.hover\:underline-default-base-lighter:hover {
  text-decoration-color: #dcdee0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base-light {
  text-decoration-color: #a9aeb1 !important;
}

.hover\:underline-default-base-light:hover {
  text-decoration-color: #a9aeb1 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base {
  text-decoration-color: #71767a !important;
}

.hover\:underline-default-base:hover {
  text-decoration-color: #71767a !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base-dark {
  text-decoration-color: #565c65 !important;
}

.hover\:underline-default-base-dark:hover {
  text-decoration-color: #565c65 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base-darker {
  text-decoration-color: #3d4551 !important;
}

.hover\:underline-default-base-darker:hover {
  text-decoration-color: #3d4551 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-base-darkest {
  text-decoration-color: #1b1b1b !important;
}

.hover\:underline-default-base-darkest:hover {
  text-decoration-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-ink {
  text-decoration-color: #1b1b1b !important;
}

.hover\:underline-default-ink:hover {
  text-decoration-color: #1b1b1b !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-primary-lighter {
  text-decoration-color: #d9e8f6 !important;
}

.hover\:underline-default-primary-lighter:hover {
  text-decoration-color: #d9e8f6 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-primary-light {
  text-decoration-color: #73b3e7 !important;
}

.hover\:underline-default-primary-light:hover {
  text-decoration-color: #73b3e7 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-primary {
  text-decoration-color: #005ea2 !important;
}

.hover\:underline-default-primary:hover {
  text-decoration-color: #005ea2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-primary-vivid {
  text-decoration-color: #0050d8 !important;
}

.hover\:underline-default-primary-vivid:hover {
  text-decoration-color: #0050d8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-primary-dark {
  text-decoration-color: #1a4480 !important;
}

.hover\:underline-default-primary-dark:hover {
  text-decoration-color: #1a4480 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-primary-darker {
  text-decoration-color: #162e51 !important;
}

.hover\:underline-default-primary-darker:hover {
  text-decoration-color: #162e51 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-secondary-lighter {
  text-decoration-color: #f8dfe2 !important;
}

.hover\:underline-default-secondary-lighter:hover {
  text-decoration-color: #f8dfe2 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-secondary-light {
  text-decoration-color: #f2938c !important;
}

.hover\:underline-default-secondary-light:hover {
  text-decoration-color: #f2938c !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-secondary {
  text-decoration-color: #d83933 !important;
}

.hover\:underline-default-secondary:hover {
  text-decoration-color: #d83933 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-secondary-vivid {
  text-decoration-color: #e41d3d !important;
}

.hover\:underline-default-secondary-vivid:hover {
  text-decoration-color: #e41d3d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-secondary-dark {
  text-decoration-color: #b51d09 !important;
}

.hover\:underline-default-secondary-dark:hover {
  text-decoration-color: #b51d09 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-secondary-darker {
  text-decoration-color: #8b1303 !important;
}

.hover\:underline-default-secondary-darker:hover {
  text-decoration-color: #8b1303 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-warm-lighter {
  text-decoration-color: #f2e4d4 !important;
}

.hover\:underline-default-accent-warm-lighter:hover {
  text-decoration-color: #f2e4d4 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-warm-light {
  text-decoration-color: #ffbc78 !important;
}

.hover\:underline-default-accent-warm-light:hover {
  text-decoration-color: #ffbc78 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-warm {
  text-decoration-color: #fa9441 !important;
}

.hover\:underline-default-accent-warm:hover {
  text-decoration-color: #fa9441 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-warm-dark {
  text-decoration-color: #c05600 !important;
}

.hover\:underline-default-accent-warm-dark:hover {
  text-decoration-color: #c05600 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-warm-darker {
  text-decoration-color: #775540 !important;
}

.hover\:underline-default-accent-warm-darker:hover {
  text-decoration-color: #775540 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-cool-lighter {
  text-decoration-color: #e1f3f8 !important;
}

.hover\:underline-default-accent-cool-lighter:hover {
  text-decoration-color: #e1f3f8 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-cool-light {
  text-decoration-color: #97d4ea !important;
}

.hover\:underline-default-accent-cool-light:hover {
  text-decoration-color: #97d4ea !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-cool {
  text-decoration-color: #00bde3 !important;
}

.hover\:underline-default-accent-cool:hover {
  text-decoration-color: #00bde3 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-cool-dark {
  text-decoration-color: #28a0cb !important;
}

.hover\:underline-default-accent-cool-dark:hover {
  text-decoration-color: #28a0cb !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.underline-default-accent-cool-darker {
  text-decoration-color: #07648d !important;
}

.hover\:underline-default-accent-cool-darker:hover {
  text-decoration-color: #07648d !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-0 {
  text-indent: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-05 {
  text-indent: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-1 {
  text-indent: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-105 {
  text-indent: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-2 {
  text-indent: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-205 {
  text-indent: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-3 {
  text-indent: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-05 {
  text-indent: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-1 {
  text-indent: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-105 {
  text-indent: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-2 {
  text-indent: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-205 {
  text-indent: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-3 {
  text-indent: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-4 {
  text-indent: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-5 {
  text-indent: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-6 {
  text-indent: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-7 {
  text-indent: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-8 {
  text-indent: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-9 {
  text-indent: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-10 {
  text-indent: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-15 {
  text-indent: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-4 {
  text-indent: -2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-5 {
  text-indent: -2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-6 {
  text-indent: -3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-7 {
  text-indent: -3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-8 {
  text-indent: -4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-9 {
  text-indent: -4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-10 {
  text-indent: -5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-indent-neg-15 {
  text-indent: -7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-uppercase {
  text-transform: uppercase !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-uppercase {
  text-transform: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-lowercase {
  text-transform: lowercase !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-lowercase {
  text-transform: none !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-1px {
  top: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-2px {
  top: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-05 {
  top: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-1 {
  top: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-105 {
  top: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-2 {
  top: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-205 {
  top: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-3 {
  top: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-1px {
  top: -1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-2px {
  top: -2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-05 {
  top: -0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-1 {
  top: -0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-105 {
  top: -0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-2 {
  top: -1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-205 {
  top: -1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-neg-3 {
  top: -1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-0 {
  top: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.top-auto {
  top: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-baseline {
  vertical-align: baseline !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-bottom {
  vertical-align: bottom !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-middle {
  vertical-align: middle !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-sub {
  vertical-align: sub !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-super {
  vertical-align: super !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-tbottom {
  vertical-align: text-bottom !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-ttop {
  vertical-align: text-top !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-top {
  vertical-align: top !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-pre {
  white-space: pre !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-pre-line {
  white-space: pre-line !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-pre-wrap {
  white-space: pre-wrap !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-wrap {
  white-space: normal !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.text-no-wrap {
  white-space: nowrap !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-1px {
  width: 1px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-2px {
  width: 2px !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-05 {
  width: 0.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-1 {
  width: 0.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-105 {
  width: 0.75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-2 {
  width: 1rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-205 {
  width: 1.25rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-3 {
  width: 1.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-4 {
  width: 2rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-5 {
  width: 2.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-6 {
  width: 3rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-7 {
  width: 3.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-8 {
  width: 4rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-9 {
  width: 4.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-10 {
  width: 5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-15 {
  width: 7.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-card {
  width: 10rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-card-lg {
  width: 15rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-mobile {
  width: 20rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-mobile-lg {
  width: 30rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-tablet {
  width: 40rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-tablet-lg {
  width: 55rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-desktop {
  width: 64rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-desktop-lg {
  width: 75rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-widescreen {
  width: 87.5rem !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-0 {
  width: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-full {
  width: 100% !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.width-auto {
  width: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-auto {
  z-index: auto !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-bottom {
  z-index: -100 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-top {
  z-index: 99999 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-0 {
  z-index: 0 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-100 {
  z-index: 100 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-200 {
  z-index: 200 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-300 {
  z-index: 300 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-400 {
  z-index: 400 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
.z-500 {
  z-index: 500 !important;
}

/*
@if map-deep-get($utility, settings, responsive) {
  @include render-media-queries(
    $utility,
    $selector,
    $property,
    $value,
    $val-props
  );
}
*/
@media all and (min-width: 30em) {
  .mobile-lg\:border-1px {
    border: 1px solid !important;
  }

  .mobile-lg\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .mobile-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-1px {
    border-top: 1px solid !important;
  }

  .mobile-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-1px {
    border-right: 1px solid !important;
  }

  .mobile-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-1px {
    border-left: 1px solid !important;
  }

  .mobile-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-2px {
    border: 2px solid !important;
  }

  .mobile-lg\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .mobile-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .mobile-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-2px {
    border-top: 2px solid !important;
  }

  .mobile-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-2px {
    border-right: 2px solid !important;
  }

  .mobile-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }

  .mobile-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-2px {
    border-left: 2px solid !important;
  }

  .mobile-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-05 {
    border: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-05 {
    border-top: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-05 {
    border-right: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-05 {
    border-left: 0.25rem solid !important;
  }

  .mobile-lg\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-1 {
    border: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-1 {
    border-top: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-1 {
    border-right: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-1 {
    border-left: 0.5rem solid !important;
  }

  .mobile-lg\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-105 {
    border: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-105 {
    border-top: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-105 {
    border-right: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-105 {
    border-left: 0.75rem solid !important;
  }

  .mobile-lg\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-2 {
    border: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-2 {
    border-top: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-2 {
    border-right: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-2 {
    border-left: 1rem solid !important;
  }

  .mobile-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-205 {
    border: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-205 {
    border-top: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-205 {
    border-right: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-205 {
    border-left: 1.25rem solid !important;
  }

  .mobile-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-3 {
    border: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-3 {
    border-top: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-3 {
    border-right: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-3 {
    border-left: 1.5rem solid !important;
  }

  .mobile-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-0 {
    border: 0 solid !important;
  }

  .mobile-lg\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .mobile-lg\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .mobile-lg\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top-0 {
    border-top: 0 solid !important;
  }

  .mobile-lg\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right-0 {
    border-right: 0 solid !important;
  }

  .mobile-lg\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }

  .mobile-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left-0 {
    border-left: 0 solid !important;
  }

  .mobile-lg\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border {
    border: 1px solid !important;
  }

  .mobile-lg\:hover\:border:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .mobile-lg\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-top {
    border-top: 1px solid !important;
  }

  .mobile-lg\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-right {
    border-right: 1px solid !important;
  }

  .mobile-lg\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-bottom {
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-left {
    border-left: 1px solid !important;
  }

  .mobile-lg\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-transparent {
    border-color: transparent !important;
  }

  .mobile-lg\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-black {
    border-color: black !important;
  }

  .mobile-lg\:hover\:border-black:hover {
    border-color: black !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-white {
    border-color: white !important;
  }

  .mobile-lg\:hover\:border-white:hover {
    border-color: white !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-red {
    border-color: #e52207 !important;
  }

  .mobile-lg\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-orange {
    border-color: #e66f0e !important;
  }

  .mobile-lg\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gold {
    border-color: #ffbe2e !important;
  }

  .mobile-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-yellow {
    border-color: #fee685 !important;
  }

  .mobile-lg\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-green {
    border-color: #538200 !important;
  }

  .mobile-lg\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-mint {
    border-color: #04c585 !important;
  }

  .mobile-lg\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-cyan {
    border-color: #009ec1 !important;
  }

  .mobile-lg\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-blue {
    border-color: #0076d6 !important;
  }

  .mobile-lg\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-indigo {
    border-color: #676cc8 !important;
  }

  .mobile-lg\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-violet {
    border-color: #8168b3 !important;
  }

  .mobile-lg\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-magenta {
    border-color: #d72d79 !important;
  }

  .mobile-lg\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }

  .mobile-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gray-30 {
    border-color: #adadad !important;
  }

  .mobile-lg\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gray-50 {
    border-color: #757575 !important;
  }

  .mobile-lg\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gray-70 {
    border-color: #454545 !important;
  }

  .mobile-lg\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-gray-90 {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base-lighter {
    border-color: #e6e6e6 !important;
  }

  .mobile-lg\:hover\:border-base-lighter:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base-light {
    border-color: #adadad !important;
  }

  .mobile-lg\:hover\:border-base-light:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base {
    border-color: #757575 !important;
  }

  .mobile-lg\:hover\:border-base:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base-dark {
    border-color: #5c5c5c !important;
  }

  .mobile-lg\:hover\:border-base-dark:hover {
    border-color: #5c5c5c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base-darker {
    border-color: #2e2e2e !important;
  }

  .mobile-lg\:hover\:border-base-darker:hover {
    border-color: #2e2e2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-base-darkest {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-ink {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:hover\:border-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .mobile-lg\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-primary-light {
    border-color: #73b3e7 !important;
  }

  .mobile-lg\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-primary {
    border-color: #005ea2 !important;
  }

  .mobile-lg\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-primary-vivid {
    border-color: #0050d8 !important;
  }

  .mobile-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-primary-dark {
    border-color: #1a4480 !important;
  }

  .mobile-lg\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-primary-darker {
    border-color: #162e51 !important;
  }

  .mobile-lg\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }

  .mobile-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-secondary-light {
    border-color: #f2938c !important;
  }

  .mobile-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-secondary {
    border-color: #d83933 !important;
  }

  .mobile-lg\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .mobile-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-secondary-dark {
    border-color: #b50909 !important;
  }

  .mobile-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }

  .mobile-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-warm-darker {
    border-color: #775540 !important;
  }

  .mobile-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .mobile-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-warm {
    border-color: #fa9441 !important;
  }

  .mobile-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .mobile-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .mobile-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-cool-darker {
    border-color: #07648d !important;
  }

  .mobile-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .mobile-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-cool {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .mobile-lg\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .mobile-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-error-lighter {
    border-color: #f4e3db !important;
  }

  .mobile-lg\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-error-light {
    border-color: #f39268 !important;
  }

  .mobile-lg\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-error {
    border-color: #d54309 !important;
  }

  .mobile-lg\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-error-dark {
    border-color: #b50909 !important;
  }

  .mobile-lg\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-error-darker {
    border-color: #6f3331 !important;
  }

  .mobile-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }

  .mobile-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-warning-light {
    border-color: #fee685 !important;
  }

  .mobile-lg\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-warning {
    border-color: #ffbe2e !important;
  }

  .mobile-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-warning-dark {
    border-color: #e5a000 !important;
  }

  .mobile-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-warning-darker {
    border-color: #936f38 !important;
  }

  .mobile-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-success-lighter {
    border-color: #ecf3ec !important;
  }

  .mobile-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-success-light {
    border-color: #70e17b !important;
  }

  .mobile-lg\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-success {
    border-color: #00a91c !important;
  }

  .mobile-lg\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-success-dark {
    border-color: #4d8055 !important;
  }

  .mobile-lg\:hover\:border-success-dark:hover {
    border-color: #4d8055 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-success-darker {
    border-color: #446443 !important;
  }

  .mobile-lg\:hover\:border-success-darker:hover {
    border-color: #446443 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }

  .mobile-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-info-light {
    border-color: #99deea !important;
  }

  .mobile-lg\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-info {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-info-dark {
    border-color: #009ec1 !important;
  }

  .mobile-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-info-darker {
    border-color: #2e6276 !important;
  }

  .mobile-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-disabled-light {
    border-color: #e6e6e6 !important;
  }

  .mobile-lg\:hover\:border-disabled-light:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-disabled {
    border-color: #c9c9c9 !important;
  }

  .mobile-lg\:hover\:border-disabled:hover {
    border-color: #c9c9c9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-disabled-dark {
    border-color: #adadad !important;
  }

  .mobile-lg\:hover\:border-disabled-dark:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-emergency {
    border-color: #9c3d10 !important;
  }

  .mobile-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-emergency-dark {
    border-color: #332d29 !important;
  }

  .mobile-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:hover\:border-default-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base-lighter {
    border-color: #dcdee0 !important;
  }

  .mobile-lg\:hover\:border-default-base-lighter:hover {
    border-color: #dcdee0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base-light {
    border-color: #a9aeb1 !important;
  }

  .mobile-lg\:hover\:border-default-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base {
    border-color: #71767a !important;
  }

  .mobile-lg\:hover\:border-default-base:hover {
    border-color: #71767a !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base-dark {
    border-color: #565c65 !important;
  }

  .mobile-lg\:hover\:border-default-base-dark:hover {
    border-color: #565c65 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base-darker {
    border-color: #3d4551 !important;
  }

  .mobile-lg\:hover\:border-default-base-darker:hover {
    border-color: #3d4551 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-base-darkest {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:hover\:border-default-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-ink {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:hover\:border-default-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .mobile-lg\:hover\:border-default-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-primary-light {
    border-color: #73b3e7 !important;
  }

  .mobile-lg\:hover\:border-default-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-primary {
    border-color: #005ea2 !important;
  }

  .mobile-lg\:hover\:border-default-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-primary-vivid {
    border-color: #0050d8 !important;
  }

  .mobile-lg\:hover\:border-default-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-primary-dark {
    border-color: #1a4480 !important;
  }

  .mobile-lg\:hover\:border-default-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-primary-darker {
    border-color: #162e51 !important;
  }

  .mobile-lg\:hover\:border-default-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-secondary-lighter {
    border-color: #f8dfe2 !important;
  }

  .mobile-lg\:hover\:border-default-secondary-lighter:hover {
    border-color: #f8dfe2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-secondary-light {
    border-color: #f2938c !important;
  }

  .mobile-lg\:hover\:border-default-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-secondary {
    border-color: #d83933 !important;
  }

  .mobile-lg\:hover\:border-default-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .mobile-lg\:hover\:border-default-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-secondary-dark {
    border-color: #b51d09 !important;
  }

  .mobile-lg\:hover\:border-default-secondary-dark:hover {
    border-color: #b51d09 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-secondary-darker {
    border-color: #8b1303 !important;
  }

  .mobile-lg\:hover\:border-default-secondary-darker:hover {
    border-color: #8b1303 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .mobile-lg\:hover\:border-default-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .mobile-lg\:hover\:border-default-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-warm {
    border-color: #fa9441 !important;
  }

  .mobile-lg\:hover\:border-default-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .mobile-lg\:hover\:border-default-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-warm-darker {
    border-color: #775540 !important;
  }

  .mobile-lg\:hover\:border-default-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .mobile-lg\:hover\:border-default-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .mobile-lg\:hover\:border-default-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-cool {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:hover\:border-default-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .mobile-lg\:hover\:border-default-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:border-default-accent-cool-darker {
    border-color: #07648d !important;
  }

  .mobile-lg\:hover\:border-default-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-0 {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-none {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-top-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-right-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-bottom-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-left-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-sm {
    border-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-md {
    border-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-lg {
    border-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-pill {
    border-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-block {
    display: block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-flex {
    display: flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-none {
    display: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-inline {
    display: inline !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-inline-block {
    display: inline-block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-inline-flex {
    display: inline-flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-table {
    display: table !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-table-cell {
    display: table-cell !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:display-table-row {
    display: table-row !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-3xs {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-2xs {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-xs {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-sm {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-md {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-lg {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-xl {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-2xl {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-3xl {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-heading-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-body-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-alt-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-ui-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-micro {
    font-size: 0.63rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-1 {
    font-size: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-2 {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-3 {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-4 {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-5 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-6 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-7 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-8 {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-9 {
    font-size: 1.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-10 {
    font-size: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-11 {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-12 {
    font-size: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-13 {
    font-size: 2.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-14 {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-15 {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-16 {
    font-size: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-17 {
    font-size: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-18 {
    font-size: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-19 {
    font-size: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-lang-20 {
    font-size: 8.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-micro {
    font-size: 0.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-1 {
    font-size: 0.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-2 {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-3 {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-4 {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-5 {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-6 {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-7 {
    font-size: 1.07rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-8 {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-9 {
    font-size: 1.31rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-10 {
    font-size: 1.43rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-11 {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-12 {
    font-size: 1.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-13 {
    font-size: 2.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-14 {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-15 {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-16 {
    font-size: 3.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-17 {
    font-size: 3.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-18 {
    font-size: 4.76rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-19 {
    font-size: 7.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-mono-20 {
    font-size: 8.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-micro {
    font-size: 0.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-1 {
    font-size: 0.8rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-2 {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-3 {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-4 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-5 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-6 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-7 {
    font-size: 1.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-8 {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-9 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-10 {
    font-size: 1.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-11 {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-12 {
    font-size: 2.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-13 {
    font-size: 2.4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-14 {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-15 {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-16 {
    font-size: 3.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-17 {
    font-size: 4.26rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-18 {
    font-size: 5.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-19 {
    font-size: 7.99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-sans-20 {
    font-size: 9.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-micro {
    font-size: 0.61rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-1 {
    font-size: 0.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-2 {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-3 {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-4 {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-5 {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-6 {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-7 {
    font-size: 1.1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-8 {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-9 {
    font-size: 1.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-10 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-11 {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-12 {
    font-size: 1.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-13 {
    font-size: 2.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-14 {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-15 {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-16 {
    font-size: 3.42rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-17 {
    font-size: 3.9rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-18 {
    font-size: 4.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-19 {
    font-size: 7.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:font-serif-20 {
    font-size: 8.54rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-thin {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-light {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-normal {
    font-weight: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-medium {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-semibold {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-bold {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-heavy {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-100 {
    font-weight: 100 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-200 {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-300 {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-400 {
    font-weight: 400 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-500 {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-600 {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-700 {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-800 {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-900 {
    font-weight: 900 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:flex-justify-center {
    justify-content: center !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:flex-justify-start {
    justify-content: flex-start !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:flex-justify-end {
    justify-content: flex-end !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:flex-justify {
    justify-content: space-between !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-sans-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-sans-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-sans-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-sans-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-sans-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-sans-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-serif-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-serif-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-mono-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-heading-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-heading-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-ui-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-ui-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-ui-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-ui-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-ui-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-ui-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-body-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-body-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-body-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-body-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-body-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-body-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-code-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-alt-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-alt-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-1px {
    margin: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-2px {
    margin: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-05 {
    margin: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-1 {
    margin: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-105 {
    margin: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-2 {
    margin: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-1px {
    margin: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-2px {
    margin: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-05 {
    margin: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-1 {
    margin: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-105 {
    margin: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-2 {
    margin: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-205 {
    margin: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-3 {
    margin: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-4 {
    margin: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-6 {
    margin: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-8 {
    margin: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-10 {
    margin: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-4 {
    margin: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-5 {
    margin: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-6 {
    margin: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-7 {
    margin: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-8 {
    margin: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-9 {
    margin: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-10 {
    margin: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-15 {
    margin: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-05em {
    margin: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-1em {
    margin: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-105em {
    margin: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-2em {
    margin: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-0 {
    margin: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-1px {
    margin-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-2px {
    margin-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-05 {
    margin-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-1 {
    margin-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-105 {
    margin-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-2 {
    margin-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-4 {
    margin-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-6 {
    margin-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-8 {
    margin-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-10 {
    margin-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-05em {
    margin-top: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-1em {
    margin-top: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-2em {
    margin-top: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-0 {
    margin-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-top-auto {
    margin-top: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-1px {
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-1px {
    margin-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-2px {
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-2px {
    margin-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-05 {
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-05 {
    margin-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-1 {
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-1 {
    margin-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-105 {
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-105 {
    margin-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-2 {
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-2 {
    margin-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-4 {
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-4 {
    margin-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-6 {
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-6 {
    margin-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-8 {
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-8 {
    margin-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-10 {
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-10 {
    margin-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-card {
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-card {
    margin-left: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-05em {
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-05em {
    margin-left: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-1em {
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-1em {
    margin-left: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-2em {
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-2em {
    margin-left: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-0 {
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-0 {
    margin-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-right-auto {
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:margin-left-auto {
    margin-left: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-05 {
    max-width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-1 {
    max-width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-105 {
    max-width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-2 {
    max-width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-205 {
    max-width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-3 {
    max-width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-4 {
    max-width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-5 {
    max-width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-6 {
    max-width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-7 {
    max-width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-8 {
    max-width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-9 {
    max-width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-10 {
    max-width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-15 {
    max-width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-card {
    max-width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-card-lg {
    max-width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-mobile {
    max-width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-mobile-lg {
    max-width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-tablet {
    max-width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-tablet-lg {
    max-width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-desktop {
    max-width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-desktop-lg {
    max-width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-widescreen {
    max-width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:maxw-full {
    max-width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-1 {
    max-width: 44ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-2 {
    max-width: 60ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-3 {
    max-width: 64ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-4 {
    max-width: 68ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-5 {
    max-width: 72ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-6 {
    max-width: 88ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:measure-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-first {
    order: -1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-last {
    order: 999 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-initial {
    order: initial !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-0 {
    order: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-1 {
    order: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-2 {
    order: 2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-3 {
    order: 3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-4 {
    order: 4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-5 {
    order: 5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-6 {
    order: 6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-7 {
    order: 7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-8 {
    order: 8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-9 {
    order: 9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-10 {
    order: 10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:order-11 {
    order: 11 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-1px {
    padding: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-1px {
    padding-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-1px {
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-1px {
    padding-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-2px {
    padding: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-2px {
    padding-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-2px {
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-2px {
    padding-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-05 {
    padding: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-05 {
    padding-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-05 {
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-05 {
    padding-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-1 {
    padding: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-1 {
    padding-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-1 {
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-1 {
    padding-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-105 {
    padding: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-105 {
    padding-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-105 {
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-105 {
    padding-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-2 {
    padding: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-2 {
    padding-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-2 {
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-2 {
    padding-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-205 {
    padding: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-3 {
    padding: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-4 {
    padding: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-4 {
    padding-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-4 {
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-4 {
    padding-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-5 {
    padding: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-6 {
    padding: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-6 {
    padding-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-6 {
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-6 {
    padding-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-7 {
    padding: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-8 {
    padding: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-8 {
    padding-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-8 {
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-8 {
    padding-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-9 {
    padding: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-10 {
    padding: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-10 {
    padding-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-10 {
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-10 {
    padding-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-15 {
    padding: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-0 {
    padding: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-top-0 {
    padding-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-right-0 {
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:padding-left-0 {
    padding-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-pre {
    white-space: pre !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-pre-line {
    white-space: pre-line !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-pre-wrap {
    white-space: pre-wrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-wrap {
    white-space: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:text-no-wrap {
    white-space: nowrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-1px {
    width: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-2px {
    width: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-05 {
    width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-1 {
    width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-105 {
    width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-2 {
    width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-205 {
    width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-3 {
    width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-4 {
    width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-5 {
    width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-6 {
    width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-7 {
    width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-8 {
    width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-9 {
    width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-10 {
    width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-15 {
    width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-card {
    width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-card-lg {
    width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-mobile {
    width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-mobile-lg {
    width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-tablet {
    width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-tablet-lg {
    width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-desktop {
    width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-desktop-lg {
    width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-widescreen {
    width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-0 {
    width: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-full {
    width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .mobile-lg\:width-auto {
    width: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
}
@media all and (min-width: 40em) {
  .tablet\:border-1px {
    border: 1px solid !important;
  }

  .tablet\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .tablet\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .tablet\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-1px {
    border-top: 1px solid !important;
  }

  .tablet\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-1px {
    border-right: 1px solid !important;
  }

  .tablet\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }

  .tablet\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-1px {
    border-left: 1px solid !important;
  }

  .tablet\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-2px {
    border: 2px solid !important;
  }

  .tablet\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .tablet\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .tablet\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-2px {
    border-top: 2px solid !important;
  }

  .tablet\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-2px {
    border-right: 2px solid !important;
  }

  .tablet\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }

  .tablet\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-2px {
    border-left: 2px solid !important;
  }

  .tablet\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-05 {
    border: 0.25rem solid !important;
  }

  .tablet\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  .tablet\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  .tablet\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-05 {
    border-top: 0.25rem solid !important;
  }

  .tablet\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-05 {
    border-right: 0.25rem solid !important;
  }

  .tablet\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }

  .tablet\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-05 {
    border-left: 0.25rem solid !important;
  }

  .tablet\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-1 {
    border: 0.5rem solid !important;
  }

  .tablet\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  .tablet\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  .tablet\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-1 {
    border-top: 0.5rem solid !important;
  }

  .tablet\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-1 {
    border-right: 0.5rem solid !important;
  }

  .tablet\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }

  .tablet\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-1 {
    border-left: 0.5rem solid !important;
  }

  .tablet\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-105 {
    border: 0.75rem solid !important;
  }

  .tablet\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  .tablet\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  .tablet\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-105 {
    border-top: 0.75rem solid !important;
  }

  .tablet\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-105 {
    border-right: 0.75rem solid !important;
  }

  .tablet\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }

  .tablet\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-105 {
    border-left: 0.75rem solid !important;
  }

  .tablet\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-2 {
    border: 1rem solid !important;
  }

  .tablet\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .tablet\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .tablet\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-2 {
    border-top: 1rem solid !important;
  }

  .tablet\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-2 {
    border-right: 1rem solid !important;
  }

  .tablet\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }

  .tablet\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-2 {
    border-left: 1rem solid !important;
  }

  .tablet\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-205 {
    border: 1.25rem solid !important;
  }

  .tablet\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .tablet\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .tablet\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-205 {
    border-top: 1.25rem solid !important;
  }

  .tablet\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-205 {
    border-right: 1.25rem solid !important;
  }

  .tablet\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }

  .tablet\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-205 {
    border-left: 1.25rem solid !important;
  }

  .tablet\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-3 {
    border: 1.5rem solid !important;
  }

  .tablet\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .tablet\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .tablet\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-3 {
    border-top: 1.5rem solid !important;
  }

  .tablet\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-3 {
    border-right: 1.5rem solid !important;
  }

  .tablet\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }

  .tablet\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-3 {
    border-left: 1.5rem solid !important;
  }

  .tablet\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-0 {
    border: 0 solid !important;
  }

  .tablet\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .tablet\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .tablet\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top-0 {
    border-top: 0 solid !important;
  }

  .tablet\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right-0 {
    border-right: 0 solid !important;
  }

  .tablet\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }

  .tablet\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left-0 {
    border-left: 0 solid !important;
  }

  .tablet\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border {
    border: 1px solid !important;
  }

  .tablet\:hover\:border:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .tablet\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .tablet\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-top {
    border-top: 1px solid !important;
  }

  .tablet\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-right {
    border-right: 1px solid !important;
  }

  .tablet\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-bottom {
    border-bottom: 1px solid !important;
  }

  .tablet\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-left {
    border-left: 1px solid !important;
  }

  .tablet\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-transparent {
    border-color: transparent !important;
  }

  .tablet\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-black {
    border-color: black !important;
  }

  .tablet\:hover\:border-black:hover {
    border-color: black !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-white {
    border-color: white !important;
  }

  .tablet\:hover\:border-white:hover {
    border-color: white !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-red {
    border-color: #e52207 !important;
  }

  .tablet\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-orange {
    border-color: #e66f0e !important;
  }

  .tablet\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gold {
    border-color: #ffbe2e !important;
  }

  .tablet\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-yellow {
    border-color: #fee685 !important;
  }

  .tablet\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-green {
    border-color: #538200 !important;
  }

  .tablet\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-mint {
    border-color: #04c585 !important;
  }

  .tablet\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-cyan {
    border-color: #009ec1 !important;
  }

  .tablet\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-blue {
    border-color: #0076d6 !important;
  }

  .tablet\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-indigo {
    border-color: #676cc8 !important;
  }

  .tablet\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-violet {
    border-color: #8168b3 !important;
  }

  .tablet\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-magenta {
    border-color: #d72d79 !important;
  }

  .tablet\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }

  .tablet\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }

  .tablet\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gray-30 {
    border-color: #adadad !important;
  }

  .tablet\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gray-50 {
    border-color: #757575 !important;
  }

  .tablet\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gray-70 {
    border-color: #454545 !important;
  }

  .tablet\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-gray-90 {
    border-color: #1b1b1b !important;
  }

  .tablet\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .tablet\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base-lighter {
    border-color: #e6e6e6 !important;
  }

  .tablet\:hover\:border-base-lighter:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base-light {
    border-color: #adadad !important;
  }

  .tablet\:hover\:border-base-light:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base {
    border-color: #757575 !important;
  }

  .tablet\:hover\:border-base:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base-dark {
    border-color: #5c5c5c !important;
  }

  .tablet\:hover\:border-base-dark:hover {
    border-color: #5c5c5c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base-darker {
    border-color: #2e2e2e !important;
  }

  .tablet\:hover\:border-base-darker:hover {
    border-color: #2e2e2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-base-darkest {
    border-color: #1b1b1b !important;
  }

  .tablet\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-ink {
    border-color: #1b1b1b !important;
  }

  .tablet\:hover\:border-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .tablet\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-primary-light {
    border-color: #73b3e7 !important;
  }

  .tablet\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-primary {
    border-color: #005ea2 !important;
  }

  .tablet\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-primary-vivid {
    border-color: #0050d8 !important;
  }

  .tablet\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-primary-dark {
    border-color: #1a4480 !important;
  }

  .tablet\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-primary-darker {
    border-color: #162e51 !important;
  }

  .tablet\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }

  .tablet\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-secondary-light {
    border-color: #f2938c !important;
  }

  .tablet\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-secondary {
    border-color: #d83933 !important;
  }

  .tablet\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .tablet\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-secondary-dark {
    border-color: #b50909 !important;
  }

  .tablet\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }

  .tablet\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-warm-darker {
    border-color: #775540 !important;
  }

  .tablet\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .tablet\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-warm {
    border-color: #fa9441 !important;
  }

  .tablet\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .tablet\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .tablet\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-cool-darker {
    border-color: #07648d !important;
  }

  .tablet\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .tablet\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-cool {
    border-color: #00bde3 !important;
  }

  .tablet\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .tablet\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .tablet\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-error-lighter {
    border-color: #f4e3db !important;
  }

  .tablet\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-error-light {
    border-color: #f39268 !important;
  }

  .tablet\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-error {
    border-color: #d54309 !important;
  }

  .tablet\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-error-dark {
    border-color: #b50909 !important;
  }

  .tablet\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-error-darker {
    border-color: #6f3331 !important;
  }

  .tablet\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }

  .tablet\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-warning-light {
    border-color: #fee685 !important;
  }

  .tablet\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-warning {
    border-color: #ffbe2e !important;
  }

  .tablet\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-warning-dark {
    border-color: #e5a000 !important;
  }

  .tablet\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-warning-darker {
    border-color: #936f38 !important;
  }

  .tablet\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-success-lighter {
    border-color: #ecf3ec !important;
  }

  .tablet\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-success-light {
    border-color: #70e17b !important;
  }

  .tablet\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-success {
    border-color: #00a91c !important;
  }

  .tablet\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-success-dark {
    border-color: #4d8055 !important;
  }

  .tablet\:hover\:border-success-dark:hover {
    border-color: #4d8055 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-success-darker {
    border-color: #446443 !important;
  }

  .tablet\:hover\:border-success-darker:hover {
    border-color: #446443 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }

  .tablet\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-info-light {
    border-color: #99deea !important;
  }

  .tablet\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-info {
    border-color: #00bde3 !important;
  }

  .tablet\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-info-dark {
    border-color: #009ec1 !important;
  }

  .tablet\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-info-darker {
    border-color: #2e6276 !important;
  }

  .tablet\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-disabled-light {
    border-color: #e6e6e6 !important;
  }

  .tablet\:hover\:border-disabled-light:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-disabled {
    border-color: #c9c9c9 !important;
  }

  .tablet\:hover\:border-disabled:hover {
    border-color: #c9c9c9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-disabled-dark {
    border-color: #adadad !important;
  }

  .tablet\:hover\:border-disabled-dark:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-emergency {
    border-color: #9c3d10 !important;
  }

  .tablet\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-emergency-dark {
    border-color: #332d29 !important;
  }

  .tablet\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .tablet\:hover\:border-default-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base-lighter {
    border-color: #dcdee0 !important;
  }

  .tablet\:hover\:border-default-base-lighter:hover {
    border-color: #dcdee0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base-light {
    border-color: #a9aeb1 !important;
  }

  .tablet\:hover\:border-default-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base {
    border-color: #71767a !important;
  }

  .tablet\:hover\:border-default-base:hover {
    border-color: #71767a !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base-dark {
    border-color: #565c65 !important;
  }

  .tablet\:hover\:border-default-base-dark:hover {
    border-color: #565c65 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base-darker {
    border-color: #3d4551 !important;
  }

  .tablet\:hover\:border-default-base-darker:hover {
    border-color: #3d4551 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-base-darkest {
    border-color: #1b1b1b !important;
  }

  .tablet\:hover\:border-default-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-ink {
    border-color: #1b1b1b !important;
  }

  .tablet\:hover\:border-default-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .tablet\:hover\:border-default-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-primary-light {
    border-color: #73b3e7 !important;
  }

  .tablet\:hover\:border-default-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-primary {
    border-color: #005ea2 !important;
  }

  .tablet\:hover\:border-default-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-primary-vivid {
    border-color: #0050d8 !important;
  }

  .tablet\:hover\:border-default-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-primary-dark {
    border-color: #1a4480 !important;
  }

  .tablet\:hover\:border-default-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-primary-darker {
    border-color: #162e51 !important;
  }

  .tablet\:hover\:border-default-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-secondary-lighter {
    border-color: #f8dfe2 !important;
  }

  .tablet\:hover\:border-default-secondary-lighter:hover {
    border-color: #f8dfe2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-secondary-light {
    border-color: #f2938c !important;
  }

  .tablet\:hover\:border-default-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-secondary {
    border-color: #d83933 !important;
  }

  .tablet\:hover\:border-default-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .tablet\:hover\:border-default-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-secondary-dark {
    border-color: #b51d09 !important;
  }

  .tablet\:hover\:border-default-secondary-dark:hover {
    border-color: #b51d09 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-secondary-darker {
    border-color: #8b1303 !important;
  }

  .tablet\:hover\:border-default-secondary-darker:hover {
    border-color: #8b1303 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .tablet\:hover\:border-default-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .tablet\:hover\:border-default-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-warm {
    border-color: #fa9441 !important;
  }

  .tablet\:hover\:border-default-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .tablet\:hover\:border-default-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-warm-darker {
    border-color: #775540 !important;
  }

  .tablet\:hover\:border-default-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .tablet\:hover\:border-default-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .tablet\:hover\:border-default-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-cool {
    border-color: #00bde3 !important;
  }

  .tablet\:hover\:border-default-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .tablet\:hover\:border-default-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:border-default-accent-cool-darker {
    border-color: #07648d !important;
  }

  .tablet\:hover\:border-default-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-0 {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-none {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-top-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-right-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-bottom-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-left-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-sm {
    border-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-md {
    border-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-lg {
    border-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-pill {
    border-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-block {
    display: block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-flex {
    display: flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-none {
    display: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-inline {
    display: inline !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-inline-block {
    display: inline-block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-inline-flex {
    display: inline-flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-table {
    display: table !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-table-cell {
    display: table-cell !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:display-table-row {
    display: table-row !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-3xs {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-2xs {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-xs {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-sm {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-md {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-lg {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-xl {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-2xl {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-3xl {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-heading-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-body-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-alt-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-ui-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-micro {
    font-size: 0.63rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-1 {
    font-size: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-2 {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-3 {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-4 {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-5 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-6 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-7 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-8 {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-9 {
    font-size: 1.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-10 {
    font-size: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-11 {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-12 {
    font-size: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-13 {
    font-size: 2.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-14 {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-15 {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-16 {
    font-size: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-17 {
    font-size: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-18 {
    font-size: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-19 {
    font-size: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-lang-20 {
    font-size: 8.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-micro {
    font-size: 0.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-1 {
    font-size: 0.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-2 {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-3 {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-4 {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-5 {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-6 {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-7 {
    font-size: 1.07rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-8 {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-9 {
    font-size: 1.31rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-10 {
    font-size: 1.43rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-11 {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-12 {
    font-size: 1.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-13 {
    font-size: 2.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-14 {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-15 {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-16 {
    font-size: 3.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-17 {
    font-size: 3.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-18 {
    font-size: 4.76rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-19 {
    font-size: 7.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-mono-20 {
    font-size: 8.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-micro {
    font-size: 0.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-1 {
    font-size: 0.8rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-2 {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-3 {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-4 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-5 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-6 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-7 {
    font-size: 1.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-8 {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-9 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-10 {
    font-size: 1.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-11 {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-12 {
    font-size: 2.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-13 {
    font-size: 2.4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-14 {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-15 {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-16 {
    font-size: 3.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-17 {
    font-size: 4.26rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-18 {
    font-size: 5.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-19 {
    font-size: 7.99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-sans-20 {
    font-size: 9.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-micro {
    font-size: 0.61rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-1 {
    font-size: 0.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-2 {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-3 {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-4 {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-5 {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-6 {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-7 {
    font-size: 1.1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-8 {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-9 {
    font-size: 1.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-10 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-11 {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-12 {
    font-size: 1.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-13 {
    font-size: 2.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-14 {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-15 {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-16 {
    font-size: 3.42rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-17 {
    font-size: 3.9rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-18 {
    font-size: 4.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-19 {
    font-size: 7.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:font-serif-20 {
    font-size: 8.54rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-thin {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-light {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-normal {
    font-weight: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-medium {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-semibold {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-bold {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-heavy {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-100 {
    font-weight: 100 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-200 {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-300 {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-400 {
    font-weight: 400 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-500 {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-600 {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-700 {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-800 {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-900 {
    font-weight: 900 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:flex-justify-center {
    justify-content: center !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:flex-justify-start {
    justify-content: flex-start !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:flex-justify-end {
    justify-content: flex-end !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:flex-justify {
    justify-content: space-between !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-sans-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-sans-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-sans-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-sans-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-sans-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-sans-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-serif-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-serif-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-mono-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-heading-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-heading-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-ui-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-ui-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-ui-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-ui-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-ui-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-ui-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-body-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-body-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-body-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-body-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-body-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-body-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-code-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-alt-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-alt-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-1px {
    margin: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-2px {
    margin: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-05 {
    margin: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-1 {
    margin: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-105 {
    margin: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-2 {
    margin: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-1px {
    margin: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-2px {
    margin: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-05 {
    margin: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-1 {
    margin: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-105 {
    margin: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-2 {
    margin: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-205 {
    margin: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-3 {
    margin: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-4 {
    margin: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-6 {
    margin: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-8 {
    margin: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-10 {
    margin: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-4 {
    margin: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-5 {
    margin: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-6 {
    margin: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-7 {
    margin: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-8 {
    margin: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-9 {
    margin: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-10 {
    margin: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-15 {
    margin: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-05em {
    margin: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-1em {
    margin: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-105em {
    margin: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-2em {
    margin: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-0 {
    margin: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-1px {
    margin-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-2px {
    margin-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-05 {
    margin-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-1 {
    margin-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-105 {
    margin-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-2 {
    margin-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-4 {
    margin-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-6 {
    margin-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-8 {
    margin-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-10 {
    margin-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-05em {
    margin-top: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-1em {
    margin-top: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-2em {
    margin-top: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-0 {
    margin-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-top-auto {
    margin-top: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-1px {
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-1px {
    margin-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-2px {
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-2px {
    margin-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-05 {
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-05 {
    margin-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-1 {
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-1 {
    margin-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-105 {
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-105 {
    margin-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-2 {
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-2 {
    margin-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-4 {
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-4 {
    margin-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-6 {
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-6 {
    margin-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-8 {
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-8 {
    margin-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-10 {
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-10 {
    margin-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-card {
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-card {
    margin-left: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-05em {
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-05em {
    margin-left: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-1em {
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-1em {
    margin-left: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-2em {
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-2em {
    margin-left: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-0 {
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-0 {
    margin-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-right-auto {
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:margin-left-auto {
    margin-left: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-05 {
    max-width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-1 {
    max-width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-105 {
    max-width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-2 {
    max-width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-205 {
    max-width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-3 {
    max-width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-4 {
    max-width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-5 {
    max-width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-6 {
    max-width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-7 {
    max-width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-8 {
    max-width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-9 {
    max-width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-10 {
    max-width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-15 {
    max-width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-card {
    max-width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-card-lg {
    max-width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-mobile {
    max-width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-mobile-lg {
    max-width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-tablet {
    max-width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-tablet-lg {
    max-width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-desktop {
    max-width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-desktop-lg {
    max-width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-widescreen {
    max-width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:maxw-full {
    max-width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-1 {
    max-width: 44ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-2 {
    max-width: 60ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-3 {
    max-width: 64ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-4 {
    max-width: 68ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-5 {
    max-width: 72ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-6 {
    max-width: 88ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:measure-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-first {
    order: -1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-last {
    order: 999 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-initial {
    order: initial !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-0 {
    order: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-1 {
    order: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-2 {
    order: 2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-3 {
    order: 3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-4 {
    order: 4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-5 {
    order: 5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-6 {
    order: 6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-7 {
    order: 7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-8 {
    order: 8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-9 {
    order: 9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-10 {
    order: 10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:order-11 {
    order: 11 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-1px {
    padding: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-1px {
    padding-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-1px {
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-1px {
    padding-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-2px {
    padding: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-2px {
    padding-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-2px {
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-2px {
    padding-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-05 {
    padding: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-05 {
    padding-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-05 {
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-05 {
    padding-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-1 {
    padding: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-1 {
    padding-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-1 {
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-1 {
    padding-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-105 {
    padding: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-105 {
    padding-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-105 {
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-105 {
    padding-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-2 {
    padding: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-2 {
    padding-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-2 {
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-2 {
    padding-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-205 {
    padding: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-3 {
    padding: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-4 {
    padding: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-4 {
    padding-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-4 {
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-4 {
    padding-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-5 {
    padding: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-6 {
    padding: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-6 {
    padding-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-6 {
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-6 {
    padding-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-7 {
    padding: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-8 {
    padding: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-8 {
    padding-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-8 {
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-8 {
    padding-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-9 {
    padding: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-10 {
    padding: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-10 {
    padding-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-10 {
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-10 {
    padding-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-15 {
    padding: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-0 {
    padding: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-top-0 {
    padding-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-right-0 {
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:padding-left-0 {
    padding-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-pre {
    white-space: pre !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-pre-line {
    white-space: pre-line !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-pre-wrap {
    white-space: pre-wrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-wrap {
    white-space: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:text-no-wrap {
    white-space: nowrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-1px {
    width: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-2px {
    width: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-05 {
    width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-1 {
    width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-105 {
    width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-2 {
    width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-205 {
    width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-3 {
    width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-4 {
    width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-5 {
    width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-6 {
    width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-7 {
    width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-8 {
    width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-9 {
    width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-10 {
    width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-15 {
    width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-card {
    width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-card-lg {
    width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-mobile {
    width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-mobile-lg {
    width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-tablet {
    width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-tablet-lg {
    width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-desktop {
    width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-desktop-lg {
    width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-widescreen {
    width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-0 {
    width: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-full {
    width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .tablet\:width-auto {
    width: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
}
@media all and (min-width: 64em) {
  .desktop\:border-1px {
    border: 1px solid !important;
  }

  .desktop\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-1px {
    border-top: 1px solid !important;
  }

  .desktop\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-1px {
    border-right: 1px solid !important;
  }

  .desktop\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }

  .desktop\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-1px {
    border-left: 1px solid !important;
  }

  .desktop\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-2px {
    border: 2px solid !important;
  }

  .desktop\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .desktop\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .desktop\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-2px {
    border-top: 2px solid !important;
  }

  .desktop\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-2px {
    border-right: 2px solid !important;
  }

  .desktop\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }

  .desktop\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-2px {
    border-left: 2px solid !important;
  }

  .desktop\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-05 {
    border: 0.25rem solid !important;
  }

  .desktop\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  .desktop\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  .desktop\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-05 {
    border-top: 0.25rem solid !important;
  }

  .desktop\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-05 {
    border-right: 0.25rem solid !important;
  }

  .desktop\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }

  .desktop\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-05 {
    border-left: 0.25rem solid !important;
  }

  .desktop\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-1 {
    border: 0.5rem solid !important;
  }

  .desktop\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  .desktop\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  .desktop\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-1 {
    border-top: 0.5rem solid !important;
  }

  .desktop\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-1 {
    border-right: 0.5rem solid !important;
  }

  .desktop\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }

  .desktop\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-1 {
    border-left: 0.5rem solid !important;
  }

  .desktop\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-105 {
    border: 0.75rem solid !important;
  }

  .desktop\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  .desktop\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  .desktop\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-105 {
    border-top: 0.75rem solid !important;
  }

  .desktop\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-105 {
    border-right: 0.75rem solid !important;
  }

  .desktop\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }

  .desktop\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-105 {
    border-left: 0.75rem solid !important;
  }

  .desktop\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-2 {
    border: 1rem solid !important;
  }

  .desktop\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .desktop\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .desktop\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-2 {
    border-top: 1rem solid !important;
  }

  .desktop\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-2 {
    border-right: 1rem solid !important;
  }

  .desktop\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }

  .desktop\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-2 {
    border-left: 1rem solid !important;
  }

  .desktop\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-205 {
    border: 1.25rem solid !important;
  }

  .desktop\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .desktop\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .desktop\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-205 {
    border-top: 1.25rem solid !important;
  }

  .desktop\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-205 {
    border-right: 1.25rem solid !important;
  }

  .desktop\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }

  .desktop\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-205 {
    border-left: 1.25rem solid !important;
  }

  .desktop\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-3 {
    border: 1.5rem solid !important;
  }

  .desktop\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .desktop\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .desktop\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-3 {
    border-top: 1.5rem solid !important;
  }

  .desktop\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-3 {
    border-right: 1.5rem solid !important;
  }

  .desktop\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }

  .desktop\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-3 {
    border-left: 1.5rem solid !important;
  }

  .desktop\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-0 {
    border: 0 solid !important;
  }

  .desktop\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .desktop\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .desktop\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top-0 {
    border-top: 0 solid !important;
  }

  .desktop\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right-0 {
    border-right: 0 solid !important;
  }

  .desktop\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }

  .desktop\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left-0 {
    border-left: 0 solid !important;
  }

  .desktop\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border {
    border: 1px solid !important;
  }

  .desktop\:hover\:border:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-top {
    border-top: 1px solid !important;
  }

  .desktop\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-right {
    border-right: 1px solid !important;
  }

  .desktop\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-bottom {
    border-bottom: 1px solid !important;
  }

  .desktop\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-left {
    border-left: 1px solid !important;
  }

  .desktop\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-transparent {
    border-color: transparent !important;
  }

  .desktop\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-black {
    border-color: black !important;
  }

  .desktop\:hover\:border-black:hover {
    border-color: black !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-white {
    border-color: white !important;
  }

  .desktop\:hover\:border-white:hover {
    border-color: white !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-red {
    border-color: #e52207 !important;
  }

  .desktop\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-orange {
    border-color: #e66f0e !important;
  }

  .desktop\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gold {
    border-color: #ffbe2e !important;
  }

  .desktop\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-yellow {
    border-color: #fee685 !important;
  }

  .desktop\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-green {
    border-color: #538200 !important;
  }

  .desktop\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-mint {
    border-color: #04c585 !important;
  }

  .desktop\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-cyan {
    border-color: #009ec1 !important;
  }

  .desktop\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-blue {
    border-color: #0076d6 !important;
  }

  .desktop\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-indigo {
    border-color: #676cc8 !important;
  }

  .desktop\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-violet {
    border-color: #8168b3 !important;
  }

  .desktop\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-magenta {
    border-color: #d72d79 !important;
  }

  .desktop\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }

  .desktop\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }

  .desktop\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gray-30 {
    border-color: #adadad !important;
  }

  .desktop\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gray-50 {
    border-color: #757575 !important;
  }

  .desktop\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gray-70 {
    border-color: #454545 !important;
  }

  .desktop\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-gray-90 {
    border-color: #1b1b1b !important;
  }

  .desktop\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .desktop\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base-lighter {
    border-color: #e6e6e6 !important;
  }

  .desktop\:hover\:border-base-lighter:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base-light {
    border-color: #adadad !important;
  }

  .desktop\:hover\:border-base-light:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base {
    border-color: #757575 !important;
  }

  .desktop\:hover\:border-base:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base-dark {
    border-color: #5c5c5c !important;
  }

  .desktop\:hover\:border-base-dark:hover {
    border-color: #5c5c5c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base-darker {
    border-color: #2e2e2e !important;
  }

  .desktop\:hover\:border-base-darker:hover {
    border-color: #2e2e2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-base-darkest {
    border-color: #1b1b1b !important;
  }

  .desktop\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-ink {
    border-color: #1b1b1b !important;
  }

  .desktop\:hover\:border-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .desktop\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-primary-light {
    border-color: #73b3e7 !important;
  }

  .desktop\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-primary {
    border-color: #005ea2 !important;
  }

  .desktop\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-primary-vivid {
    border-color: #0050d8 !important;
  }

  .desktop\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-primary-dark {
    border-color: #1a4480 !important;
  }

  .desktop\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-primary-darker {
    border-color: #162e51 !important;
  }

  .desktop\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }

  .desktop\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-secondary-light {
    border-color: #f2938c !important;
  }

  .desktop\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-secondary {
    border-color: #d83933 !important;
  }

  .desktop\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .desktop\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-secondary-dark {
    border-color: #b50909 !important;
  }

  .desktop\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }

  .desktop\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-warm-darker {
    border-color: #775540 !important;
  }

  .desktop\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .desktop\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-warm {
    border-color: #fa9441 !important;
  }

  .desktop\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .desktop\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .desktop\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-cool-darker {
    border-color: #07648d !important;
  }

  .desktop\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .desktop\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-cool {
    border-color: #00bde3 !important;
  }

  .desktop\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .desktop\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .desktop\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-error-lighter {
    border-color: #f4e3db !important;
  }

  .desktop\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-error-light {
    border-color: #f39268 !important;
  }

  .desktop\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-error {
    border-color: #d54309 !important;
  }

  .desktop\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-error-dark {
    border-color: #b50909 !important;
  }

  .desktop\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-error-darker {
    border-color: #6f3331 !important;
  }

  .desktop\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }

  .desktop\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-warning-light {
    border-color: #fee685 !important;
  }

  .desktop\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-warning {
    border-color: #ffbe2e !important;
  }

  .desktop\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-warning-dark {
    border-color: #e5a000 !important;
  }

  .desktop\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-warning-darker {
    border-color: #936f38 !important;
  }

  .desktop\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-success-lighter {
    border-color: #ecf3ec !important;
  }

  .desktop\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-success-light {
    border-color: #70e17b !important;
  }

  .desktop\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-success {
    border-color: #00a91c !important;
  }

  .desktop\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-success-dark {
    border-color: #4d8055 !important;
  }

  .desktop\:hover\:border-success-dark:hover {
    border-color: #4d8055 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-success-darker {
    border-color: #446443 !important;
  }

  .desktop\:hover\:border-success-darker:hover {
    border-color: #446443 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }

  .desktop\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-info-light {
    border-color: #99deea !important;
  }

  .desktop\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-info {
    border-color: #00bde3 !important;
  }

  .desktop\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-info-dark {
    border-color: #009ec1 !important;
  }

  .desktop\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-info-darker {
    border-color: #2e6276 !important;
  }

  .desktop\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-disabled-light {
    border-color: #e6e6e6 !important;
  }

  .desktop\:hover\:border-disabled-light:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-disabled {
    border-color: #c9c9c9 !important;
  }

  .desktop\:hover\:border-disabled:hover {
    border-color: #c9c9c9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-disabled-dark {
    border-color: #adadad !important;
  }

  .desktop\:hover\:border-disabled-dark:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-emergency {
    border-color: #9c3d10 !important;
  }

  .desktop\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-emergency-dark {
    border-color: #332d29 !important;
  }

  .desktop\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .desktop\:hover\:border-default-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base-lighter {
    border-color: #dcdee0 !important;
  }

  .desktop\:hover\:border-default-base-lighter:hover {
    border-color: #dcdee0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base-light {
    border-color: #a9aeb1 !important;
  }

  .desktop\:hover\:border-default-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base {
    border-color: #71767a !important;
  }

  .desktop\:hover\:border-default-base:hover {
    border-color: #71767a !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base-dark {
    border-color: #565c65 !important;
  }

  .desktop\:hover\:border-default-base-dark:hover {
    border-color: #565c65 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base-darker {
    border-color: #3d4551 !important;
  }

  .desktop\:hover\:border-default-base-darker:hover {
    border-color: #3d4551 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-base-darkest {
    border-color: #1b1b1b !important;
  }

  .desktop\:hover\:border-default-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-ink {
    border-color: #1b1b1b !important;
  }

  .desktop\:hover\:border-default-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .desktop\:hover\:border-default-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-primary-light {
    border-color: #73b3e7 !important;
  }

  .desktop\:hover\:border-default-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-primary {
    border-color: #005ea2 !important;
  }

  .desktop\:hover\:border-default-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-primary-vivid {
    border-color: #0050d8 !important;
  }

  .desktop\:hover\:border-default-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-primary-dark {
    border-color: #1a4480 !important;
  }

  .desktop\:hover\:border-default-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-primary-darker {
    border-color: #162e51 !important;
  }

  .desktop\:hover\:border-default-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-secondary-lighter {
    border-color: #f8dfe2 !important;
  }

  .desktop\:hover\:border-default-secondary-lighter:hover {
    border-color: #f8dfe2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-secondary-light {
    border-color: #f2938c !important;
  }

  .desktop\:hover\:border-default-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-secondary {
    border-color: #d83933 !important;
  }

  .desktop\:hover\:border-default-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .desktop\:hover\:border-default-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-secondary-dark {
    border-color: #b51d09 !important;
  }

  .desktop\:hover\:border-default-secondary-dark:hover {
    border-color: #b51d09 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-secondary-darker {
    border-color: #8b1303 !important;
  }

  .desktop\:hover\:border-default-secondary-darker:hover {
    border-color: #8b1303 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .desktop\:hover\:border-default-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .desktop\:hover\:border-default-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-warm {
    border-color: #fa9441 !important;
  }

  .desktop\:hover\:border-default-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .desktop\:hover\:border-default-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-warm-darker {
    border-color: #775540 !important;
  }

  .desktop\:hover\:border-default-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .desktop\:hover\:border-default-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .desktop\:hover\:border-default-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-cool {
    border-color: #00bde3 !important;
  }

  .desktop\:hover\:border-default-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .desktop\:hover\:border-default-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:border-default-accent-cool-darker {
    border-color: #07648d !important;
  }

  .desktop\:hover\:border-default-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-0 {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-none {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-top-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-right-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-bottom-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-left-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-sm {
    border-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-md {
    border-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-lg {
    border-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-pill {
    border-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-block {
    display: block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-flex {
    display: flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-none {
    display: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-inline {
    display: inline !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-inline-block {
    display: inline-block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-inline-flex {
    display: inline-flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-table {
    display: table !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-table-cell {
    display: table-cell !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:display-table-row {
    display: table-row !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-3xs {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-2xs {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-xs {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-sm {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-md {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-lg {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-xl {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-2xl {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-3xl {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-heading-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-body-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-alt-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-ui-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-micro {
    font-size: 0.63rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-1 {
    font-size: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-2 {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-3 {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-4 {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-5 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-6 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-7 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-8 {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-9 {
    font-size: 1.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-10 {
    font-size: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-11 {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-12 {
    font-size: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-13 {
    font-size: 2.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-14 {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-15 {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-16 {
    font-size: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-17 {
    font-size: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-18 {
    font-size: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-19 {
    font-size: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-lang-20 {
    font-size: 8.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-micro {
    font-size: 0.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-1 {
    font-size: 0.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-2 {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-3 {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-4 {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-5 {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-6 {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-7 {
    font-size: 1.07rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-8 {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-9 {
    font-size: 1.31rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-10 {
    font-size: 1.43rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-11 {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-12 {
    font-size: 1.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-13 {
    font-size: 2.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-14 {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-15 {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-16 {
    font-size: 3.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-17 {
    font-size: 3.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-18 {
    font-size: 4.76rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-19 {
    font-size: 7.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-mono-20 {
    font-size: 8.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-micro {
    font-size: 0.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-1 {
    font-size: 0.8rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-2 {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-3 {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-4 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-5 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-6 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-7 {
    font-size: 1.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-8 {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-9 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-10 {
    font-size: 1.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-11 {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-12 {
    font-size: 2.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-13 {
    font-size: 2.4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-14 {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-15 {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-16 {
    font-size: 3.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-17 {
    font-size: 4.26rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-18 {
    font-size: 5.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-19 {
    font-size: 7.99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-sans-20 {
    font-size: 9.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-micro {
    font-size: 0.61rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-1 {
    font-size: 0.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-2 {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-3 {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-4 {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-5 {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-6 {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-7 {
    font-size: 1.1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-8 {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-9 {
    font-size: 1.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-10 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-11 {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-12 {
    font-size: 1.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-13 {
    font-size: 2.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-14 {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-15 {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-16 {
    font-size: 3.42rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-17 {
    font-size: 3.9rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-18 {
    font-size: 4.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-19 {
    font-size: 7.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:font-serif-20 {
    font-size: 8.54rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-thin {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-light {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-normal {
    font-weight: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-medium {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-semibold {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-bold {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-heavy {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-100 {
    font-weight: 100 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-200 {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-300 {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-400 {
    font-weight: 400 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-500 {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-600 {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-700 {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-800 {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-900 {
    font-weight: 900 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:flex-justify-center {
    justify-content: center !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:flex-justify-start {
    justify-content: flex-start !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:flex-justify-end {
    justify-content: flex-end !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:flex-justify {
    justify-content: space-between !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-sans-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-sans-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-sans-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-sans-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-sans-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-sans-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-serif-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-serif-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-mono-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-heading-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-heading-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-ui-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-ui-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-ui-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-ui-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-ui-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-ui-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-body-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-body-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-body-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-body-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-body-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-body-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-code-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-alt-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-alt-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-1px {
    margin: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-2px {
    margin: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-05 {
    margin: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-1 {
    margin: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-105 {
    margin: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-2 {
    margin: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-1px {
    margin: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-2px {
    margin: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-05 {
    margin: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-1 {
    margin: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-105 {
    margin: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-2 {
    margin: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-205 {
    margin: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-3 {
    margin: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-4 {
    margin: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-6 {
    margin: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-8 {
    margin: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-10 {
    margin: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-4 {
    margin: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-5 {
    margin: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-6 {
    margin: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-7 {
    margin: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-8 {
    margin: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-9 {
    margin: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-10 {
    margin: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-15 {
    margin: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-05em {
    margin: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-1em {
    margin: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-105em {
    margin: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-2em {
    margin: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-0 {
    margin: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-1px {
    margin-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-2px {
    margin-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-05 {
    margin-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-1 {
    margin-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-105 {
    margin-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-2 {
    margin-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-4 {
    margin-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-6 {
    margin-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-8 {
    margin-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-10 {
    margin-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-05em {
    margin-top: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-1em {
    margin-top: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-2em {
    margin-top: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-0 {
    margin-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-top-auto {
    margin-top: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-1px {
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-1px {
    margin-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-2px {
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-2px {
    margin-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-05 {
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-05 {
    margin-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-1 {
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-1 {
    margin-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-105 {
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-105 {
    margin-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-2 {
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-2 {
    margin-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-4 {
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-4 {
    margin-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-6 {
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-6 {
    margin-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-8 {
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-8 {
    margin-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-10 {
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-10 {
    margin-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-card {
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-card {
    margin-left: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-05em {
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-05em {
    margin-left: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-1em {
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-1em {
    margin-left: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-2em {
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-2em {
    margin-left: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-0 {
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-0 {
    margin-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-right-auto {
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:margin-left-auto {
    margin-left: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-05 {
    max-width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-1 {
    max-width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-105 {
    max-width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-2 {
    max-width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-205 {
    max-width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-3 {
    max-width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-4 {
    max-width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-5 {
    max-width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-6 {
    max-width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-7 {
    max-width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-8 {
    max-width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-9 {
    max-width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-10 {
    max-width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-15 {
    max-width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-card {
    max-width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-card-lg {
    max-width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-mobile {
    max-width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-mobile-lg {
    max-width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-tablet {
    max-width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-tablet-lg {
    max-width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-desktop {
    max-width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-desktop-lg {
    max-width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-widescreen {
    max-width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:maxw-full {
    max-width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-1 {
    max-width: 44ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-2 {
    max-width: 60ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-3 {
    max-width: 64ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-4 {
    max-width: 68ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-5 {
    max-width: 72ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-6 {
    max-width: 88ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:measure-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-first {
    order: -1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-last {
    order: 999 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-initial {
    order: initial !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-0 {
    order: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-1 {
    order: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-2 {
    order: 2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-3 {
    order: 3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-4 {
    order: 4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-5 {
    order: 5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-6 {
    order: 6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-7 {
    order: 7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-8 {
    order: 8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-9 {
    order: 9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-10 {
    order: 10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:order-11 {
    order: 11 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-1px {
    padding: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-1px {
    padding-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-1px {
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-1px {
    padding-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-2px {
    padding: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-2px {
    padding-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-2px {
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-2px {
    padding-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-05 {
    padding: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-05 {
    padding-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-05 {
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-05 {
    padding-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-1 {
    padding: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-1 {
    padding-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-1 {
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-1 {
    padding-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-105 {
    padding: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-105 {
    padding-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-105 {
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-105 {
    padding-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-2 {
    padding: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-2 {
    padding-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-2 {
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-2 {
    padding-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-205 {
    padding: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-3 {
    padding: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-4 {
    padding: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-4 {
    padding-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-4 {
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-4 {
    padding-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-5 {
    padding: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-6 {
    padding: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-6 {
    padding-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-6 {
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-6 {
    padding-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-7 {
    padding: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-8 {
    padding: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-8 {
    padding-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-8 {
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-8 {
    padding-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-9 {
    padding: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-10 {
    padding: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-10 {
    padding-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-10 {
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-10 {
    padding-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-15 {
    padding: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-0 {
    padding: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-top-0 {
    padding-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-right-0 {
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:padding-left-0 {
    padding-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-pre {
    white-space: pre !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-pre-line {
    white-space: pre-line !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-pre-wrap {
    white-space: pre-wrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-wrap {
    white-space: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:text-no-wrap {
    white-space: nowrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-1px {
    width: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-2px {
    width: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-05 {
    width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-1 {
    width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-105 {
    width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-2 {
    width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-205 {
    width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-3 {
    width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-4 {
    width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-5 {
    width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-6 {
    width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-7 {
    width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-8 {
    width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-9 {
    width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-10 {
    width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-15 {
    width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-card {
    width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-card-lg {
    width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-mobile {
    width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-mobile-lg {
    width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-tablet {
    width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-tablet-lg {
    width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-desktop {
    width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-desktop-lg {
    width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-widescreen {
    width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-0 {
    width: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-full {
    width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop\:width-auto {
    width: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
}
@media all and (min-width: 75em) {
  .desktop-lg\:border-1px {
    border: 1px solid !important;
  }

  .desktop-lg\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-1px {
    border-top: 1px solid !important;
  }

  .desktop-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-1px {
    border-right: 1px solid !important;
  }

  .desktop-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }

  .desktop-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-1px {
    border-left: 1px solid !important;
  }

  .desktop-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-2px {
    border: 2px solid !important;
  }

  .desktop-lg\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .desktop-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .desktop-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-2px {
    border-top: 2px solid !important;
  }

  .desktop-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-2px {
    border-right: 2px solid !important;
  }

  .desktop-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }

  .desktop-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-2px {
    border-left: 2px solid !important;
  }

  .desktop-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-05 {
    border: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-05 {
    border-top: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-05 {
    border-right: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-05 {
    border-left: 0.25rem solid !important;
  }

  .desktop-lg\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-1 {
    border: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-1 {
    border-top: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-1 {
    border-right: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-1 {
    border-left: 0.5rem solid !important;
  }

  .desktop-lg\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-105 {
    border: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-105 {
    border-top: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-105 {
    border-right: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-105 {
    border-left: 0.75rem solid !important;
  }

  .desktop-lg\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-2 {
    border: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-2 {
    border-top: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-2 {
    border-right: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-2 {
    border-left: 1rem solid !important;
  }

  .desktop-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-205 {
    border: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-205 {
    border-top: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-205 {
    border-right: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-205 {
    border-left: 1.25rem solid !important;
  }

  .desktop-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-3 {
    border: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-3 {
    border-top: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-3 {
    border-right: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-3 {
    border-left: 1.5rem solid !important;
  }

  .desktop-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-0 {
    border: 0 solid !important;
  }

  .desktop-lg\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .desktop-lg\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .desktop-lg\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top-0 {
    border-top: 0 solid !important;
  }

  .desktop-lg\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right-0 {
    border-right: 0 solid !important;
  }

  .desktop-lg\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }

  .desktop-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left-0 {
    border-left: 0 solid !important;
  }

  .desktop-lg\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border {
    border: 1px solid !important;
  }

  .desktop-lg\:hover\:border:hover {
    border: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop-lg\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop-lg\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-top {
    border-top: 1px solid !important;
  }

  .desktop-lg\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-right {
    border-right: 1px solid !important;
  }

  .desktop-lg\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-bottom {
    border-bottom: 1px solid !important;
  }

  .desktop-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-left {
    border-left: 1px solid !important;
  }

  .desktop-lg\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-transparent {
    border-color: transparent !important;
  }

  .desktop-lg\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-black {
    border-color: black !important;
  }

  .desktop-lg\:hover\:border-black:hover {
    border-color: black !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-white {
    border-color: white !important;
  }

  .desktop-lg\:hover\:border-white:hover {
    border-color: white !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-red {
    border-color: #e52207 !important;
  }

  .desktop-lg\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-orange {
    border-color: #e66f0e !important;
  }

  .desktop-lg\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gold {
    border-color: #ffbe2e !important;
  }

  .desktop-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-yellow {
    border-color: #fee685 !important;
  }

  .desktop-lg\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-green {
    border-color: #538200 !important;
  }

  .desktop-lg\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-mint {
    border-color: #04c585 !important;
  }

  .desktop-lg\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-cyan {
    border-color: #009ec1 !important;
  }

  .desktop-lg\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-blue {
    border-color: #0076d6 !important;
  }

  .desktop-lg\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-indigo {
    border-color: #676cc8 !important;
  }

  .desktop-lg\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-violet {
    border-color: #8168b3 !important;
  }

  .desktop-lg\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-magenta {
    border-color: #d72d79 !important;
  }

  .desktop-lg\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }

  .desktop-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }

  .desktop-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gray-30 {
    border-color: #adadad !important;
  }

  .desktop-lg\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gray-50 {
    border-color: #757575 !important;
  }

  .desktop-lg\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gray-70 {
    border-color: #454545 !important;
  }

  .desktop-lg\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-gray-90 {
    border-color: #1b1b1b !important;
  }

  .desktop-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .desktop-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base-lighter {
    border-color: #e6e6e6 !important;
  }

  .desktop-lg\:hover\:border-base-lighter:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base-light {
    border-color: #adadad !important;
  }

  .desktop-lg\:hover\:border-base-light:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base {
    border-color: #757575 !important;
  }

  .desktop-lg\:hover\:border-base:hover {
    border-color: #757575 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base-dark {
    border-color: #5c5c5c !important;
  }

  .desktop-lg\:hover\:border-base-dark:hover {
    border-color: #5c5c5c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base-darker {
    border-color: #2e2e2e !important;
  }

  .desktop-lg\:hover\:border-base-darker:hover {
    border-color: #2e2e2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-base-darkest {
    border-color: #1b1b1b !important;
  }

  .desktop-lg\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-ink {
    border-color: #1b1b1b !important;
  }

  .desktop-lg\:hover\:border-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .desktop-lg\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-primary-light {
    border-color: #73b3e7 !important;
  }

  .desktop-lg\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-primary {
    border-color: #005ea2 !important;
  }

  .desktop-lg\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-primary-vivid {
    border-color: #0050d8 !important;
  }

  .desktop-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-primary-dark {
    border-color: #1a4480 !important;
  }

  .desktop-lg\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-primary-darker {
    border-color: #162e51 !important;
  }

  .desktop-lg\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }

  .desktop-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-secondary-light {
    border-color: #f2938c !important;
  }

  .desktop-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-secondary {
    border-color: #d83933 !important;
  }

  .desktop-lg\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .desktop-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-secondary-dark {
    border-color: #b50909 !important;
  }

  .desktop-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }

  .desktop-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-warm-darker {
    border-color: #775540 !important;
  }

  .desktop-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .desktop-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-warm {
    border-color: #fa9441 !important;
  }

  .desktop-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .desktop-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .desktop-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-cool-darker {
    border-color: #07648d !important;
  }

  .desktop-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .desktop-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-cool {
    border-color: #00bde3 !important;
  }

  .desktop-lg\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .desktop-lg\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .desktop-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-error-lighter {
    border-color: #f4e3db !important;
  }

  .desktop-lg\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-error-light {
    border-color: #f39268 !important;
  }

  .desktop-lg\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-error {
    border-color: #d54309 !important;
  }

  .desktop-lg\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-error-dark {
    border-color: #b50909 !important;
  }

  .desktop-lg\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-error-darker {
    border-color: #6f3331 !important;
  }

  .desktop-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }

  .desktop-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-warning-light {
    border-color: #fee685 !important;
  }

  .desktop-lg\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-warning {
    border-color: #ffbe2e !important;
  }

  .desktop-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-warning-dark {
    border-color: #e5a000 !important;
  }

  .desktop-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-warning-darker {
    border-color: #936f38 !important;
  }

  .desktop-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-success-lighter {
    border-color: #ecf3ec !important;
  }

  .desktop-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-success-light {
    border-color: #70e17b !important;
  }

  .desktop-lg\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-success {
    border-color: #00a91c !important;
  }

  .desktop-lg\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-success-dark {
    border-color: #4d8055 !important;
  }

  .desktop-lg\:hover\:border-success-dark:hover {
    border-color: #4d8055 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-success-darker {
    border-color: #446443 !important;
  }

  .desktop-lg\:hover\:border-success-darker:hover {
    border-color: #446443 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }

  .desktop-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-info-light {
    border-color: #99deea !important;
  }

  .desktop-lg\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-info {
    border-color: #00bde3 !important;
  }

  .desktop-lg\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-info-dark {
    border-color: #009ec1 !important;
  }

  .desktop-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-info-darker {
    border-color: #2e6276 !important;
  }

  .desktop-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-disabled-light {
    border-color: #e6e6e6 !important;
  }

  .desktop-lg\:hover\:border-disabled-light:hover {
    border-color: #e6e6e6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-disabled {
    border-color: #c9c9c9 !important;
  }

  .desktop-lg\:hover\:border-disabled:hover {
    border-color: #c9c9c9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-disabled-dark {
    border-color: #adadad !important;
  }

  .desktop-lg\:hover\:border-disabled-dark:hover {
    border-color: #adadad !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-emergency {
    border-color: #9c3d10 !important;
  }

  .desktop-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-emergency-dark {
    border-color: #332d29 !important;
  }

  .desktop-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base-lightest {
    border-color: #f0f0f0 !important;
  }

  .desktop-lg\:hover\:border-default-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base-lighter {
    border-color: #dcdee0 !important;
  }

  .desktop-lg\:hover\:border-default-base-lighter:hover {
    border-color: #dcdee0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base-light {
    border-color: #a9aeb1 !important;
  }

  .desktop-lg\:hover\:border-default-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base {
    border-color: #71767a !important;
  }

  .desktop-lg\:hover\:border-default-base:hover {
    border-color: #71767a !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base-dark {
    border-color: #565c65 !important;
  }

  .desktop-lg\:hover\:border-default-base-dark:hover {
    border-color: #565c65 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base-darker {
    border-color: #3d4551 !important;
  }

  .desktop-lg\:hover\:border-default-base-darker:hover {
    border-color: #3d4551 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-base-darkest {
    border-color: #1b1b1b !important;
  }

  .desktop-lg\:hover\:border-default-base-darkest:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-ink {
    border-color: #1b1b1b !important;
  }

  .desktop-lg\:hover\:border-default-ink:hover {
    border-color: #1b1b1b !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-primary-lighter {
    border-color: #d9e8f6 !important;
  }

  .desktop-lg\:hover\:border-default-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-primary-light {
    border-color: #73b3e7 !important;
  }

  .desktop-lg\:hover\:border-default-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-primary {
    border-color: #005ea2 !important;
  }

  .desktop-lg\:hover\:border-default-primary:hover {
    border-color: #005ea2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-primary-vivid {
    border-color: #0050d8 !important;
  }

  .desktop-lg\:hover\:border-default-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-primary-dark {
    border-color: #1a4480 !important;
  }

  .desktop-lg\:hover\:border-default-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-primary-darker {
    border-color: #162e51 !important;
  }

  .desktop-lg\:hover\:border-default-primary-darker:hover {
    border-color: #162e51 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-secondary-lighter {
    border-color: #f8dfe2 !important;
  }

  .desktop-lg\:hover\:border-default-secondary-lighter:hover {
    border-color: #f8dfe2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-secondary-light {
    border-color: #f2938c !important;
  }

  .desktop-lg\:hover\:border-default-secondary-light:hover {
    border-color: #f2938c !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-secondary {
    border-color: #d83933 !important;
  }

  .desktop-lg\:hover\:border-default-secondary:hover {
    border-color: #d83933 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-secondary-vivid {
    border-color: #e41d3d !important;
  }

  .desktop-lg\:hover\:border-default-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-secondary-dark {
    border-color: #b51d09 !important;
  }

  .desktop-lg\:hover\:border-default-secondary-dark:hover {
    border-color: #b51d09 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-secondary-darker {
    border-color: #8b1303 !important;
  }

  .desktop-lg\:hover\:border-default-secondary-darker:hover {
    border-color: #8b1303 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }

  .desktop-lg\:hover\:border-default-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-warm-light {
    border-color: #ffbc78 !important;
  }

  .desktop-lg\:hover\:border-default-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-warm {
    border-color: #fa9441 !important;
  }

  .desktop-lg\:hover\:border-default-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-warm-dark {
    border-color: #c05600 !important;
  }

  .desktop-lg\:hover\:border-default-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-warm-darker {
    border-color: #775540 !important;
  }

  .desktop-lg\:hover\:border-default-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }

  .desktop-lg\:hover\:border-default-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-cool-light {
    border-color: #97d4ea !important;
  }

  .desktop-lg\:hover\:border-default-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-cool {
    border-color: #00bde3 !important;
  }

  .desktop-lg\:hover\:border-default-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-cool-dark {
    border-color: #28a0cb !important;
  }

  .desktop-lg\:hover\:border-default-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:border-default-accent-cool-darker {
    border-color: #07648d !important;
  }

  .desktop-lg\:hover\:border-default-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-0 {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-none {
    border-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-top-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-right-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-bottom-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-left-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-sm {
    border-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-md {
    border-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-lg {
    border-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-pill {
    border-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-block {
    display: block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-flex {
    display: flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-none {
    display: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-inline {
    display: inline !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-inline-block {
    display: inline-block !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-inline-flex {
    display: inline-flex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-table {
    display: table !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-table-cell {
    display: table-cell !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:display-table-row {
    display: table-row !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-3xs {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-2xs {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-xs {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-sm {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-md {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-lg {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-xl {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-2xl {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-3xl {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-heading-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-body-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-3xs {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-2xs {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-xs {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-sm {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-md {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-lg {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-xl {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-3xs {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-2xs {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-xs {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-sm {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-md {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-lg {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-xl {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-2xl {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-alt-3xl {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-3xs {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-2xs {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-xs {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-sm {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-md {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-lg {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-xl {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-2xl {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-ui-3xl {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-micro {
    font-size: 0.63rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-1 {
    font-size: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-2 {
    font-size: 0.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-3 {
    font-size: 0.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-4 {
    font-size: 0.94rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-5 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-6 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-7 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-8 {
    font-size: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-9 {
    font-size: 1.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-10 {
    font-size: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-11 {
    font-size: 1.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-12 {
    font-size: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-13 {
    font-size: 2.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-14 {
    font-size: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-15 {
    font-size: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-16 {
    font-size: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-17 {
    font-size: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-18 {
    font-size: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-19 {
    font-size: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-lang-20 {
    font-size: 8.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-micro {
    font-size: 0.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-1 {
    font-size: 0.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-2 {
    font-size: 0.77rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-3 {
    font-size: 0.83rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-4 {
    font-size: 0.89rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-5 {
    font-size: 0.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-6 {
    font-size: 1.01rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-7 {
    font-size: 1.07rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-8 {
    font-size: 1.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-9 {
    font-size: 1.31rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-10 {
    font-size: 1.43rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-11 {
    font-size: 1.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-12 {
    font-size: 1.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-13 {
    font-size: 2.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-14 {
    font-size: 2.38rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-15 {
    font-size: 2.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-16 {
    font-size: 3.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-17 {
    font-size: 3.81rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-18 {
    font-size: 4.76rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-19 {
    font-size: 7.14rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-mono-20 {
    font-size: 8.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-micro {
    font-size: 0.67rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-1 {
    font-size: 0.8rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-2 {
    font-size: 0.87rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-3 {
    font-size: 0.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-4 {
    font-size: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-5 {
    font-size: 1.06rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-6 {
    font-size: 1.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-7 {
    font-size: 1.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-8 {
    font-size: 1.33rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-9 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-10 {
    font-size: 1.6rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-11 {
    font-size: 1.86rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-12 {
    font-size: 2.13rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-13 {
    font-size: 2.4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-14 {
    font-size: 2.66rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-15 {
    font-size: 3.19rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-16 {
    font-size: 3.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-17 {
    font-size: 4.26rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-18 {
    font-size: 5.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-19 {
    font-size: 7.99rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-sans-20 {
    font-size: 9.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-micro {
    font-size: 0.61rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-1 {
    font-size: 0.73rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-2 {
    font-size: 0.79rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-3 {
    font-size: 0.85rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-4 {
    font-size: 0.91rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-5 {
    font-size: 0.98rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-6 {
    font-size: 1.04rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-7 {
    font-size: 1.1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-8 {
    font-size: 1.22rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-9 {
    font-size: 1.34rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-10 {
    font-size: 1.46rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-11 {
    font-size: 1.71rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-12 {
    font-size: 1.95rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-13 {
    font-size: 2.2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-14 {
    font-size: 2.44rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-15 {
    font-size: 2.93rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-16 {
    font-size: 3.42rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-17 {
    font-size: 3.9rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-18 {
    font-size: 4.88rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-19 {
    font-size: 7.32rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:font-serif-20 {
    font-size: 8.54rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-thin {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-light {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-normal {
    font-weight: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-medium {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-semibold {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-bold {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-heavy {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-100 {
    font-weight: 100 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-200 {
    font-weight: 200 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-300 {
    font-weight: 300 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-400 {
    font-weight: 400 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-500 {
    font-weight: 500 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-600 {
    font-weight: 600 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-700 {
    font-weight: 700 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-800 {
    font-weight: 800 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-900 {
    font-weight: 900 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:flex-justify-center {
    justify-content: center !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:flex-justify-start {
    justify-content: flex-start !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:flex-justify-end {
    justify-content: flex-end !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:flex-justify {
    justify-content: space-between !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-sans-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-sans-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-sans-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-sans-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-sans-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-sans-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-serif-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-serif-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-mono-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-heading-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-heading-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-ui-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-ui-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-ui-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-ui-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-ui-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-ui-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-body-1 {
    line-height: 0.9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-body-2 {
    line-height: 1.1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-body-3 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-body-4 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-body-5 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-body-6 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-code-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-alt-1 {
    line-height: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-alt-5 {
    line-height: 1.7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-1px {
    margin: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-2px {
    margin: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-05 {
    margin: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-1 {
    margin: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-105 {
    margin: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-2 {
    margin: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-1px {
    margin: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-2px {
    margin: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-05 {
    margin: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-1 {
    margin: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-105 {
    margin: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-2 {
    margin: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-205 {
    margin: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-3 {
    margin: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-4 {
    margin: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-6 {
    margin: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-8 {
    margin: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-10 {
    margin: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-4 {
    margin: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-5 {
    margin: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-6 {
    margin: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-7 {
    margin: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-8 {
    margin: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-9 {
    margin: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-10 {
    margin: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-15 {
    margin: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-05em {
    margin: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-1em {
    margin: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-105em {
    margin: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-2em {
    margin: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-0 {
    margin: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-1px {
    margin-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-2px {
    margin-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-05 {
    margin-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-1 {
    margin-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-105 {
    margin-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-2 {
    margin-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-4 {
    margin-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-6 {
    margin-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-8 {
    margin-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-10 {
    margin-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-05em {
    margin-top: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-1em {
    margin-top: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-2em {
    margin-top: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-0 {
    margin-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-top-auto {
    margin-top: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-1px {
    margin-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-1px {
    margin-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-2px {
    margin-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-2px {
    margin-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-05 {
    margin-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-05 {
    margin-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-1 {
    margin-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-1 {
    margin-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-105 {
    margin-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-105 {
    margin-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-2 {
    margin-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-2 {
    margin-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-4 {
    margin-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-4 {
    margin-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-6 {
    margin-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-6 {
    margin-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-8 {
    margin-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-8 {
    margin-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-10 {
    margin-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-10 {
    margin-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-card {
    margin-right: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-card {
    margin-left: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-05em {
    margin-right: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-05em {
    margin-left: 0.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-1em {
    margin-right: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-1em {
    margin-left: 1em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-2em {
    margin-right: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-2em {
    margin-left: 2em !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-0 {
    margin-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-0 {
    margin-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-right-auto {
    margin-right: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:margin-left-auto {
    margin-left: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-05 {
    max-width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-1 {
    max-width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-105 {
    max-width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-2 {
    max-width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-205 {
    max-width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-3 {
    max-width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-4 {
    max-width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-5 {
    max-width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-6 {
    max-width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-7 {
    max-width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-8 {
    max-width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-9 {
    max-width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-10 {
    max-width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-15 {
    max-width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-card {
    max-width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-card-lg {
    max-width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-mobile {
    max-width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-mobile-lg {
    max-width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-tablet {
    max-width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-tablet-lg {
    max-width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-desktop {
    max-width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-desktop-lg {
    max-width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-widescreen {
    max-width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:maxw-full {
    max-width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-1 {
    max-width: 44ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-2 {
    max-width: 60ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-3 {
    max-width: 64ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-4 {
    max-width: 68ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-5 {
    max-width: 72ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-6 {
    max-width: 88ex !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:measure-none {
    max-width: none !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-first {
    order: -1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-last {
    order: 999 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-initial {
    order: initial !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-0 {
    order: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-1 {
    order: 1 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-2 {
    order: 2 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-3 {
    order: 3 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-4 {
    order: 4 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-5 {
    order: 5 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-6 {
    order: 6 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-7 {
    order: 7 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-8 {
    order: 8 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-9 {
    order: 9 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-10 {
    order: 10 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:order-11 {
    order: 11 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-1px {
    padding: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-1px {
    padding-top: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-1px {
    padding-right: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-1px {
    padding-left: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-2px {
    padding: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-2px {
    padding-top: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-2px {
    padding-right: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-2px {
    padding-left: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-05 {
    padding: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-05 {
    padding-top: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-05 {
    padding-right: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-05 {
    padding-left: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-1 {
    padding: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-1 {
    padding-top: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-1 {
    padding-right: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-1 {
    padding-left: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-105 {
    padding: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-105 {
    padding-top: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-105 {
    padding-right: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-105 {
    padding-left: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-2 {
    padding: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-2 {
    padding-top: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-2 {
    padding-right: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-2 {
    padding-left: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-205 {
    padding: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-3 {
    padding: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-4 {
    padding: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-4 {
    padding-top: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-4 {
    padding-right: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-4 {
    padding-left: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-5 {
    padding: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-6 {
    padding: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-6 {
    padding-top: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-6 {
    padding-right: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-6 {
    padding-left: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-7 {
    padding: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-8 {
    padding: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-8 {
    padding-top: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-8 {
    padding-right: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-8 {
    padding-left: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-9 {
    padding: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-10 {
    padding: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-10 {
    padding-top: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-10 {
    padding-right: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-10 {
    padding-left: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-15 {
    padding: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-0 {
    padding: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-top-0 {
    padding-top: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-right-0 {
    padding-right: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:padding-left-0 {
    padding-left: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-pre {
    white-space: pre !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-pre-line {
    white-space: pre-line !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-pre-wrap {
    white-space: pre-wrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-wrap {
    white-space: normal !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:text-no-wrap {
    white-space: nowrap !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-1px {
    width: 1px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-2px {
    width: 2px !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-05 {
    width: 0.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-1 {
    width: 0.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-105 {
    width: 0.75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-2 {
    width: 1rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-205 {
    width: 1.25rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-3 {
    width: 1.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-4 {
    width: 2rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-5 {
    width: 2.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-6 {
    width: 3rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-7 {
    width: 3.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-8 {
    width: 4rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-9 {
    width: 4.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-10 {
    width: 5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-15 {
    width: 7.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-card {
    width: 10rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-card-lg {
    width: 15rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-mobile {
    width: 20rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-mobile-lg {
    width: 30rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-tablet {
    width: 40rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-tablet-lg {
    width: 55rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-desktop {
    width: 64rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-desktop-lg {
    width: 75rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-widescreen {
    width: 87.5rem !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-0 {
    width: 0 !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-full {
    width: 100% !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
  .desktop-lg\:width-auto {
    width: auto !important;
  }

  /*
  @if map-deep-get($utility, settings, responsive) {
    @include render-media-queries(
      $utility,
      $selector,
      $property,
      $value,
      $val-props
    );
  }
  */
}

/*# sourceMappingURL=file:///opt/build/repo/css/uswds-utilities.scss */